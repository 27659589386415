import { Stack, Typography } from "@mui/material";
import React, { useCallback } from "react";
import { Button } from "../../../core/v2/Button/Button";
import { Dialog } from "../../../core/v2/Dialog/Dialog";
import { useSnackbar } from "notistack";
import { httpClient } from "../../../../utils/httpClient";
import { tracker } from "../../../../utils/tracker";
import { config } from "../../../../utils/config";
import { AxiosError } from "axios";
import { datadogRum } from "@datadog/browser-rum";
import { EditNameComponent } from "./EditNameComponent";
import { Box } from "../../../core/v2/Box/Box";
import { ChangePasswordComponent } from "./ChangePasswordComponent";

type LogoutDialogProps = {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
};

const LogoutDialog: React.FC<LogoutDialogProps> = ({
  open,
  onClose,
  onSubmit,
}) => {
  return (
    <Dialog
      title={`Confirm`}
      open={open}
      onClose={onClose}
      onCancel={onClose}
      onSubmit={onSubmit}
      cancelButtonText="Cancel"
      submitButtonNegative={true}
      submitButtonText={"Logout"}
      minWidth={320}
    >
      <Typography>Do you really want to log out?</Typography>
    </Dialog>
  );
};

const LogoutButton = () => {
  const [logoutDialogOpen, setLogoutDialogOpen] = React.useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const handleLogout = useCallback(() => {
    httpClient
      .post(
        "/auth/logout/web",
        {},
        { headers: { "x-heritage-auth": "logout" } },
      )
      .then(({ status }) =>
        status === 200
          ? Promise.resolve()
          : Promise.reject(new Error("Server logout failed")),
      )
      .then(() => {
        tracker.trackEvent({ name: "Logout" });
        tracker.deIdentifyUser();
      })
      .then(() => {
        window.location.href = config.loginPageUrl;
      })
      .catch((e) => {
        enqueueSnackbar("Something went wrong while logging out", {
          variant: "error",
        });
        const axiosError: AxiosError | undefined = e as AxiosError;
        tracker.trackEvent({
          name: "LogoutFailure",
          payload: {
            status: axiosError?.response?.status,
            error: axiosError?.message,
          },
        });
        datadogRum.addError(e);
      });
  }, [enqueueSnackbar]);

  return (
    <>
      {/*TODO: should be secondary negative*/}
      <Button
        variant="secondary"
        negative
        onClick={() => setLogoutDialogOpen(true)}
      >
        Logout
      </Button>
      <LogoutDialog
        open={logoutDialogOpen}
        onClose={() => setLogoutDialogOpen(false)}
        onSubmit={handleLogout}
      />
    </>
  );
};

/**
 * TODO: Implement me, just as placeholder atm
 * @constructor
 */
export const AccountComponent: React.FC = () => {
  return (
    <Box>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Typography variant="h1">Account</Typography>
        <Box flex={"0 1 auto"}>
          <LogoutButton />
        </Box>
      </Stack>
      <Stack
        direction="row"
        alignItems="flex-start"
        flexWrap={{ sm: "wrap", md: "nowrap" }}
        gap={0}
      >
        <Box flexBasis={{ sm: "100%", md: "50%" }}>
          <EditNameComponent />
        </Box>

        <Box flexBasis={{ sm: "100%", md: "50%" }}>
          <ChangePasswordComponent />
        </Box>
      </Stack>
    </Box>
  );
};
