import React from "react";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";

import { SystemStatus } from "../../../generated/urql";
import { useLayoutContext } from "../../../routes/LayoutRoute/LayoutRoute";
import { MaintenanceBanner } from "../../commons/MaintenanceBanner";
import { BasePageContainer } from "../BasePageContainer/BasePageContainer";
import { ContentWrapper } from "../ContentWrapper/ContentWrapper";

type Props = Readonly<{
  hideContentOnMaintenanceMode?: boolean;
  title?: string;
  // if true the content is wrapped into a Paper component
  wrapContentInContainer?: boolean;
  showBetaBadge?: boolean;
}>;

/**
 * Wraps the content of a route page with a simple layout that includes a page
 * title, an optional disclaimer and a footer.
 */
export const PageContainer: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  title,
  wrapContentInContainer,
  hideContentOnMaintenanceMode = false,
  showBetaBadge = false,
}) => {
  const { systemStatus } = useLayoutContext();
  const content =
    hideContentOnMaintenanceMode &&
    systemStatus === SystemStatus.Maintenance ? (
      <MaintenanceBanner />
    ) : (
      children
    );

  const TitleWrapper: React.FC<React.PropsWithChildren> = showBetaBadge
    ? ({ children }) => (
        <Badge badgeContent="BETA" color="warning">
          {children}
        </Badge>
      )
    : React.Fragment;

  return (
    <ContentWrapper>
      <Stack
        direction={"row"}
        justifyContent="flex-end"
        alignItems="center"
        mt={0}
      >
        {title && (
          <Box sx={{ flex: 1 }}>
            <TitleWrapper>
              <Typography variant="h4" component="h1">
                {title}
              </Typography>
            </TitleWrapper>
          </Box>
        )}
      </Stack>

      <BasePageContainer wrapContentInContainer={wrapContentInContainer}>
        {content}
      </BasePageContainer>
    </ContentWrapper>
  );
};
