import React, { PropsWithChildren } from "react";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { Typography, styled } from "@mui/material";
import { useHeritageV2Palette } from "../../../../utils/hooks/useHeritageV2Palette";
import { Box } from "../Box/Box";

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.heritageV2.text900,
  },

  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.heritageV2.text900,
  },
}));

const TooltipInner = React.forwardRef<
  HTMLDivElement,
  PropsWithChildren<unknown>
>((props, ref) => {
  return <Box display="flex" {...props} ref={ref} />;
});

type Props = {
  children: React.ReactElement;
  text: React.ReactNode;
};

/**
 * Wrap a component with a tooltip.
 */
export const TooltipWrapper: React.FC<Props> = ({ text, children }) => {
  const palette = useHeritageV2Palette();

  return (
    <CustomTooltip
      placement="top"
      title={
        <Typography variant="formLabel" color={palette.invariantWhite}>
          {text}
        </Typography>
      }
      arrow
      disableInteractive
    >
      <TooltipInner>{children}</TooltipInner>
    </CustomTooltip>
  );
};
