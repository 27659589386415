import React, { useMemo } from "react";
import {
  ActiveTableColumnFilter,
  BaseTableRow,
  DefaultSortingModel,
  DocumentLinkStructure,
  Table,
  TableColumn,
  TableGroupingColumn,
} from "../Table/Table";
import { useMediaQuery, useTheme } from "@mui/material";

export type BaseDocumentsTableRow = BaseTableRow & {
  link?: DocumentLinkStructure;
};

export type DocumentsTableProps<R extends BaseDocumentsTableRow> = {
  rows: Array<R>;
  columns: Array<TableColumn<R>>;
  filters?: Array<ActiveTableColumnFilter<R>>;
  treeData?: boolean;
  groupingColumn?: TableGroupingColumn<R>;
  defaultGroupsExpanded?: boolean;
  defaultSortingModel?: DefaultSortingModel<R>;
};

/**
 * Display a table of documents with a custom column at the end
 * used to download or visualize the document.
 */
export const DocumentsTable = <R extends BaseDocumentsTableRow>(
  props: DocumentsTableProps<R>,
) => {
  const theme = useTheme();
  const mediaMaxMd = useMediaQuery(theme.breakpoints.down("md"));

  const columns = useMemo<Array<TableColumn<R>>>(() => {
    return [
      ...props.columns,
      {
        headerName: "",
        field: "link",
        sortable: false,
        width: mediaMaxMd ? 55 : 100,
        customColumnKind: {
          kind: "documentLink",
          generateLink: ({ row }) => row?.link,
        },
      },
    ];
  }, [props, mediaMaxMd]);

  return (
    <Table
      columns={columns}
      rows={props.rows}
      filters={props.filters}
      treeData={props.treeData}
      groupingColumn={props.groupingColumn}
      defaultGroupsExpanded={props.defaultGroupsExpanded}
      defaultSortingModel={props.defaultSortingModel}
      pinnedRightColumns={mediaMaxMd ? ["link"] : undefined}
    />
  );
};
