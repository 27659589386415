import React, { useMemo } from "react";
import { Typography } from "@mui/material";
import { Card } from "../../../core/v2/Card/Card";
import { getPublicMediaPath } from "../../../../lib/helpers/media";
import { DetailedAsset } from "../data/DetailedAsset";
import { Box } from "../../../core/v2/Box/Box";

type Props = {
  asset: DetailedAsset;
};

/**
 * The "Overview" tab in the asset details.
 */
export const AssetOverview: React.FC<Props> = ({ asset }) => {
  const imageUrl = useMemo(() => {
    const firstMedia = asset.media[0];

    const coverImageUrl =
      firstMedia !== undefined
        ? getPublicMediaPath(firstMedia.publicMediaId, firstMedia.fileName)
        : undefined;

    return coverImageUrl;
  }, [asset]);

  return (
    <Card backgroundColor="invariantWhite">
      <Box maxWidth={1000}>
        <Box>
          <img
            src={imageUrl}
            alt={asset.name}
            style={{ maxHeight: "5em", width: "auto" }}
          />
        </Box>

        <Box mt={2}>
          <Typography>{asset.description}</Typography>
        </Box>
      </Box>
    </Card>
  );
};
