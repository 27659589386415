import {
  GridAggregationFunction,
  GridComparatorFn,
} from "@mui/x-data-grid-premium";
import { SimpleMoney } from "../../../../utils/data/SimpleMoney";

export const simpleMoneySumAggregationLabel =
  "simpleMoneySumAggregation" as const;

/**
 * Aggregation function for `SimpleMoney` values.
 */
export const simpleMoneySumAggregation: GridAggregationFunction<
  SimpleMoney,
  SimpleMoney | null
> = {
  apply: (params) => {
    // FIXME: Here we should trigger a log-error
    // for currencies different than the first one.
    // We should aggregate only values with the same currency.

    if (params.values.length === 0) return null;

    const currency = params.values[0]?.currency;

    if (!currency) return null;

    const sum = params.values.reduce<SimpleMoney>(
      (acc, next) => {
        return {
          amount: acc.amount + (next?.amount ?? 0),
          currency,
        };
      },
      { amount: 0, currency },
    );

    return sum;
  },
  label: simpleMoneySumAggregationLabel,
  columnTypes: ["SimpleMoney"],
};

/**
 * Comparator function for `SimpleMoney` values.
 */
export const simpleMoneySorter: GridComparatorFn<SimpleMoney> = (v1, v2) => {
  // FIXME: Here we should trigger a log-error
  // for different currencies.

  return v1.amount - v2.amount;
};
