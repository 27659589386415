import { Stack, ThemeProvider, Toolbar, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  VdrLogin,
  VdrLoginForm,
} from "../../components/documents/v2/VdrLoginForm/VdrLoginForm";
import { useMutation, useQuery } from "@tanstack/react-query";
import { httpClient } from "../../utils/httpClient";
import { useSharedDocumentsRouteQuery } from "../../generated/urql";
import { useSnackbar } from "notistack";
import { tracker } from "../../utils/tracker";
import { NotificationsProvider } from "../../utils/notifications";
import { Box } from "../../components/core/v2/Box/Box";
import { getTheme } from "../../mui/theme";
import { PublicNavbar } from "../../components/layout/v2/PublicNavbar/PublicNavbar";
import { PublicSidebarDrawer } from "../../components/layout/v2/PublicSidebarDrawer/PublicSidebarDrawer";
import { SkeletonLoader } from "../../components/core/v2/Loader/SkeletonLoader";
import { DataRoomView } from "../../components/documents/v2/DataRoomView/DataRoomView";
import { Dialog } from "../../components/core/v2/Dialog/Dialog";

type VdrCheckResponse = {
  accepted: boolean;
};

type VdrLoginResponse = {
  result: "ok";
};

/**
 * Route that handles the shared documents page (Guest VDR).
 */
const SharedDocumentsRouteContent: React.FC = () => {
  const params = useParams();
  const investorId = params.investorId;
  const shareToken = params.token;
  const snackbar = useSnackbar();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [expiredLinkModal, setExpiredLinkModal] = useState(false);

  const vdrCheckQuery = useQuery({
    queryKey: ["vdr-check"],
    queryFn: ({ signal }) =>
      httpClient.post<VdrCheckResponse>(
        "/auth/login/vdr/check",
        {
          investorId,
          shareToken,
        },
        { headers: { "x-heritage-action": "vdr" }, signal },
      ),
    enabled: !!investorId && !!shareToken,
    retry: 1,
    useErrorBoundary: false,
    refetchOnWindowFocus: false,
  });

  const vdrLoginMutation = useMutation({
    mutationFn: (params: { email: string; investorName?: string }) =>
      httpClient.post<VdrLoginResponse>(
        "/auth/login/vdr",
        {
          investorId,
          shareToken,
          ...params,
        },
        {
          headers: { "x-heritage-action": "vdr" },
          withCredentials: true,
        },
      ),
    useErrorBoundary: false,
  });

  const vdrCheckAccepted = vdrCheckQuery.data?.data?.accepted;
  const vdrLoginResult = vdrLoginMutation.data?.data?.result;
  const shouldFetchDocuments = investorId && vdrLoginResult === "ok";

  const [{ data: investorVdrData }] = useSharedDocumentsRouteQuery({
    variables: { investorId: investorId ?? "" },
    pause: !shouldFetchDocuments,
    requestPolicy: "network-only",
  });

  const handleLogin = useCallback(
    (data: VdrLogin) => {
      const { email } = data;
      const investorName =
        "investorName" in data ? data.investorName : undefined;

      vdrLoginMutation.mutate(
        {
          email,
          investorName,
        },
        {
          onSuccess: () => {
            tracker.trackEvent({
              name: "GuestVDRLoginComplete",
              payload: { email, name: investorName },
            });
          },

          onError: () => {
            snackbar.enqueueSnackbar("Wrong email address", {
              variant: "error",
            });
          },
        },
      );
    },
    [vdrLoginMutation, snackbar],
  );

  const handleDrawerOpen = useCallback(() => {
    setDrawerOpen(true);
  }, [setDrawerOpen]);

  const handleDrawerClose = useCallback(() => {
    setDrawerOpen(false);
  }, [setDrawerOpen]);

  useEffect(() => {
    if (vdrCheckQuery.error) setExpiredLinkModal(true);
  }, [vdrCheckQuery.error]);

  useEffect(() => {
    tracker.trackEvent({ name: "GuestVDRLoginStart" });
  }, []);

  useEffect(() => {
    if (investorVdrData)
      tracker.trackEvent({ name: "GuestVDRDocumentsShowed" });
  }, [investorVdrData]);

  const isLoggedIn = investorVdrData !== undefined && investorId !== undefined;

  const showLoginModal =
    !shouldFetchDocuments &&
    !vdrLoginMutation.isLoading &&
    isLoggedIn === false &&
    vdrCheckAccepted !== undefined;

  return (
    <>
      <Stack minHeight="100vh" flexDirection="column">
        <Box flex="0 0 auto">
          <PublicNavbar
            drawerOpen={drawerOpen}
            onMenuPress={drawerOpen ? handleDrawerClose : handleDrawerOpen}
          />
        </Box>

        <Box
          flex="1 1 100%"
          sx={(theme) => ({
            backgroundColor: theme.palette.heritageV2.backgroundPage,
          })}
        >
          <Stack height="100%" flexDirection="row">
            <PublicSidebarDrawer
              drawerOpen={drawerOpen}
              onDrawerOpen={handleDrawerOpen}
              onDrawerClose={handleDrawerClose}
            />

            <Box flex="1 1 100%">
              <Stack height="100%" flexDirection="column">
                <Toolbar />

                <Box flex="1 1 100%" p={3}>
                  {isLoggedIn ? (
                    <DataRoomView
                      documents={investorVdrData.investorVdr.documents}
                      hideQuarterlyReports
                    />
                  ) : (
                    <SkeletonLoader size={{ height: "table" }} />
                  )}
                </Box>
              </Stack>
            </Box>
          </Stack>
        </Box>
      </Stack>

      <VdrLoginForm
        open={showLoginModal}
        variant={vdrCheckAccepted ? "compact" : "complete"}
        onSubmit={handleLogin}
      />

      <Dialog
        errorTitle="Link expired"
        open={expiredLinkModal}
        onClose={() => null}
        maxWidth={450}
      >
        <Typography>
          The link to this <strong>Data Room</strong> has expired. Please
          contact us to obtain a new one.
        </Typography>
      </Dialog>
    </>
  );
};

export const SharedDocumentsRoute: React.FC = () => {
  return (
    <ThemeProvider theme={getTheme()}>
      <NotificationsProvider isThemeV2Enabled={true}>
        <SharedDocumentsRouteContent />
      </NotificationsProvider>
    </ThemeProvider>
  );
};
