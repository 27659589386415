import React from "react";
import { QuarterSelect, useQuarterSelectManager } from "./QuarterSelect";

/**
 * A wrapper component for the `QuarterSelect` that manages
 * the state of the selected quarter.
 */
export const ControlledQuarterSelect = () => {
  const { selectedQuarter, setSelectedQuarter } = useQuarterSelectManager();

  return (
    <QuarterSelect value={selectedQuarter} onChange={setSelectedQuarter} />
  );
};
