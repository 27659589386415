import React from "react";
import { DatePicker } from "@mui/x-date-pickers-pro";
import { CalendarIcon, ChevronLeft, ChevronRight } from "../Icon/Icon";

type Props = {
  value?: Date;
  onChange?: (newDate: Date | undefined) => void;
  label?: string;
};

/**
 * A input component used to select a specific date.
 */
export const DateInput: React.FC<Props> = ({ value, onChange, label }) => {
  return (
    <DatePicker
      sx={{
        width: "100%",
      }}
      label={label}
      value={value}
      onChange={(newDate) => {
        onChange?.(newDate ?? undefined);
      }}
      slots={{
        openPickerIcon: () => <CalendarIcon variant="medium" />,
        leftArrowIcon: () => <ChevronLeft variant="medium" />,
        rightArrowIcon: () => <ChevronRight variant="medium" />,
      }}
    />
  );
};
