import React from "react";
import { Navigate } from "react-router-dom";
import { useLayoutContext } from "../LayoutRoute/LayoutRoute";
import { useDefaultRoutePath } from "../../utils/navigation";

/**
 * The default route component redirects the user to the portfolio of the
 * first investor in the list.
 */
export const IndexRoute: React.FC = () => {
  const {
    authUser: { investors },
  } = useLayoutContext();

  const defaultRoutePath = useDefaultRoutePath(investors);

  return <Navigate to={defaultRoutePath} replace />;
};
