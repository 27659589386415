import { useParams } from "react-router-dom";
import { LayoutRouteQuery } from "../generated/urql";

/**
 * Looks up the investor ID from the URL parameters.
 */
export const usePublicInvestorId = ():
  | LayoutRouteQuery["authUser"]["investors"][number]["investor"]["id"]
  | undefined => {
  const { investorId } = useParams();
  return investorId;
};
