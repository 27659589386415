// FIXME: This is a temporary fix to show assets from the legacy
// application to the new one hosted on `app.heritageholdings.co`.
// In the future there will be a structural solution to handle assets

import { config } from "../../utils/config";

// in a better way.
const assetsPrefix = config.assetsPrefix;

/**
 * Returns the URL for a public media file (e.g. a fund logo).
 * NOTE: make sure to use the {@param publicMediaId}
 */
export const getPublicMediaPath = (
  publicMediaId: string,
  mediaFilename: string,
) => `${assetsPrefix}/storage/${publicMediaId}/${mediaFilename}`;

/**
 * Retrieve the download link to a document visible from
 * a specified investor.
 */
export const getDocumentPath = (
  investorId: string,
  documentId: string,
  action: "download" | "view",
) =>
  `${config.serverBaseUrl}/investor/${investorId}/document/${action}/${documentId}`;
