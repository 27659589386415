import React, { useCallback, useMemo, useState } from "react";
import { Money } from "@heritageholdings/lib-commons-finance/lib/units/money";
import { CashflowComputedAsset } from "../../data/CashflowComputedAsset";
import { CashflowAsset } from "../../data/CashflowAsset";
import { CloseIcon, DeleteIcon } from "../../../core/v2/Icon/Icon";
import { Stack, Typography } from "@mui/material";
import { IconButton } from "../../../core/v2/IconButton/IconButton";
import { Checkbox } from "../../../core/v2/Checkbox/Checkbox";
import { Button } from "../../../core/v2/Button/Button";
import {
  AddSimulatedAsset,
  AddSimulatedAssetDialog,
} from "./AddSimulatedAssetDialog";
import { PositiveChip } from "../../../core/v2/Chip/Chip";
import { Box } from "../../../core/v2/Box/Box";
import { LocalDateFromISOString } from "@heritageholdings/lib-commons-finance/lib/time/LocalDateFromISOString";
import * as E from "fp-ts/Either";

/**
 * Transform the amount returned by the `AddSimulatedAssetForm`
 * into a `Money` object.
 */
function formAmountToMoney(amount: number): Money {
  return new Money({ USD: amount * 1000 });
}

type Props = {
  jcurveAssets: Array<CashflowComputedAsset>;
  assetsToSimulate: Array<CashflowAsset>;
  onChange: (newAssets: Array<CashflowComputedAsset>) => void;
  onClose: () => void;
};

/**
 * This component is responsible for displaying the list of assets that are
 * plotted in the JCurve. It also allows the user to add, edit, and delete
 * assets that are not normally in the investor's portfolio.
 */
export const CashflowSimulatorPortfolio: React.FC<Props> = ({
  jcurveAssets,
  assetsToSimulate,
  onChange,
  onClose,
}) => {
  const [openSimulationDialog, setOpenSimulationDialog] = useState(false);

  // Create a new simulated asset.
  const handleAddSimulatedAsset = useCallback(
    (newAsset: AddSimulatedAsset) => {
      const selectedAsset = assetsToSimulate.find(
        (asset) => asset.id === newAsset.fundId,
      );

      if (!selectedAsset) return;

      const decodedAllocationDate = LocalDateFromISOString.decode(
        newAsset.allocationDate,
      );

      if (E.isLeft(decodedAllocationDate)) return;

      const computedSimulatedAsset: CashflowComputedAsset = {
        ...selectedAsset,
        allocationAmount: formAmountToMoney(newAsset.allocationAmount),
        allocationDate: decodedAllocationDate.right,
        showInJCurve: true,
        paidIn: new Money({ USD: 0 }),
        kind: "simulated",
      };

      const newAssets = [...jcurveAssets, computedSimulatedAsset];

      onChange?.(newAssets);
      setOpenSimulationDialog(false);
    },
    [jcurveAssets, assetsToSimulate, onChange, setOpenSimulationDialog],
  );

  const activeAssetsNum = useMemo(
    () =>
      jcurveAssets.reduce(
        (acc, next) => (next.showInJCurve ? acc + 1 : acc),
        0,
      ),
    [jcurveAssets],
  );

  const handleAddAsset = useCallback(() => {
    setOpenSimulationDialog(true);
  }, [setOpenSimulationDialog]);

  const handleCloseAssetModal = useCallback(() => {
    setOpenSimulationDialog(false);
  }, [setOpenSimulationDialog]);

  const handleToggleAll = useCallback(() => {
    if (!onChange) return;

    const newAssets: Array<CashflowComputedAsset> = [];

    for (const asset of jcurveAssets) {
      newAssets.push({
        ...asset,
        showInJCurve: jcurveAssets.length !== activeAssetsNum,
      });
    }

    onChange(newAssets);
  }, [onChange, activeAssetsNum, jcurveAssets]);

  return (
    <>
      <Box display="flex" justifyContent="flex-end">
        <IconButton Icon={CloseIcon} onClick={onClose} />
      </Box>

      <Typography variant="h3">
        Choose the assets to include in this simulation
      </Typography>

      <Box mt={2}>
        <Button variant="mini-text" onClick={handleToggleAll}>
          {jcurveAssets.length === activeAssetsNum
            ? "Delesect all"
            : "Select all"}
        </Button>
      </Box>

      <Box mt={1}>
        {jcurveAssets.map((asset, index) => {
          const handleToggle = () => {
            const newAssets = [...jcurveAssets];

            // Update the `showInSimulation` property of the asset.
            newAssets[index].showInJCurve = !newAssets[index].showInJCurve;

            onChange?.(newAssets);
          };

          const handleDelete = () => {
            const newAssets = [...jcurveAssets];

            // Remove the asset from the list.
            newAssets.splice(index, 1);

            onChange?.(newAssets);
          };

          return (
            <Box key={asset.id} mt={index > 0 ? 0 : undefined}>
              <Stack direction="row" alignItems="center">
                <Box>
                  <Checkbox
                    label={asset.name}
                    checked={asset.showInJCurve}
                    onChange={handleToggle}
                  />
                </Box>

                {asset.kind === "simulated" ? (
                  <>
                    <Box flex="0 0 auto">
                      <PositiveChip>Added</PositiveChip>
                    </Box>

                    <Box marginLeft="auto" pl={1}>
                      <IconButton
                        Icon={DeleteIcon}
                        onClick={handleDelete}
                        danger
                        noPadding
                      />
                    </Box>
                  </>
                ) : undefined}
              </Stack>
            </Box>
          );
        })}
      </Box>

      <Box mt={2}>
        <Button variant="secondary" onClick={handleAddAsset}>
          Add asset
        </Button>
      </Box>

      <AddSimulatedAssetDialog
        open={openSimulationDialog}
        onClose={handleCloseAssetModal}
        assetsToSimulate={assetsToSimulate}
        onSubmit={handleAddSimulatedAsset}
      />
    </>
  );
};
