import React from "react";
import { PropsWithChildren } from "react";
import { Card as MuiCard } from "@mui/material";
import { useHeritageV2Palette } from "../../../../utils/hooks/useHeritageV2Palette";
import { heritageV2Palette } from "../../../../mui/heritageV2Palette";

type Props = PropsWithChildren<{
  backgroundColor?: keyof typeof heritageV2Palette;
}>;

/**
 * A box with a background and a specific spacing.
 */
export const Card: React.FC<Props> = ({ children, backgroundColor }) => {
  const palette = useHeritageV2Palette();

  return (
    <MuiCard
      sx={{
        height: "100%",
        boxSizing: "border-box",
        padding: 2,
        backgroundColor: backgroundColor
          ? palette[backgroundColor]
          : palette.invariantWhite,
      }}
    >
      {children}
    </MuiCard>
  );
};
