import { useMediaQuery, useTheme } from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";
import {
  DomainTuple,
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryLine,
  VictoryTheme,
  VictoryZoomContainer,
} from "victory";
import { useHeritageV2Palette } from "../../../../utils/hooks/useHeritageV2Palette";
import { P, match } from "ts-pattern";
import { formatCutoffDateQuarter } from "../../../../utils/cutoff";
import * as U_Show from "@heritageholdings/lib-commons-finance/lib/show";
import { isSameQuarter } from "date-fns";
import { GenericTooltipNode } from "../../../core/v2/GenericTooltip/GenericTooltipNode";
import { useChartRatio } from "../../../../utils/hooks/useChartRatio";
import { useTimeout } from "../../../../lib/hooks/useTimeout";
import { Box } from "../../../core/v2/Box/Box";
import { useRetrieveCutoffs } from "../../../../hooks/useRetrieveCutoffs";

type PortfolioAmountsChartPoint = {
  x: Date;
  y: number;
};

/**
 * A point on the chart representing the NAV.
 */
export type PortfolioAmountsChartNavPoint = PortfolioAmountsChartPoint & {
  label?: "";
  paidIn?: number;
};

/**
 * A point on the chart representing the Paid-in.
 */
export type PortfolioAmountsChartPaidInPoint = PortfolioAmountsChartPoint;

type Props = {
  navData: Array<PortfolioAmountsChartNavPoint>;
  paidInData: Array<PortfolioAmountsChartPaidInPoint>;
  activeCutoff?: Date;
};

/**
 * The main chart used in the portfolio section showing
 * both the NAV and the Paid-in.
 */
export const PortfolioAmountsChart: React.FC<Props> = ({
  navData,
  paidInData,
  activeCutoff,
}) => {
  const [chartLoaded, setChartLoaded] = useState(false);
  const theme = useTheme();
  const palette = useHeritageV2Palette();
  const mqMinLg = useMediaQuery(theme.breakpoints.up("xxl"));
  const mqMinMd = useMediaQuery(theme.breakpoints.up("md"));
  const chartRatio = useChartRatio();
  const isSmallDevice = !mqMinMd;
  const { isDraftCutoff } = useRetrieveCutoffs();

  const chartHeight = match([mqMinMd, mqMinLg])
    .with([P._, true], () => 110)
    .with([true, false], () => 150)
    .otherwise(() => 280);

  const axisLabelOffset = match([mqMinMd, mqMinLg])
    .with([P._, true], () => 15)
    .with([true, false], () => 20)
    .otherwise(() => 30);

  const labelsFontSize = 3 * chartRatio;
  const tooltipWidth = 38 * chartRatio;
  const tooltipHeight = 22.5 * chartRatio;

  const tooltipNode = useMemo(
    () => (
      <GenericTooltipNode<PortfolioAmountsChartNavPoint>
        width={tooltipWidth}
        height={tooltipHeight}
        labelsFontSize={labelsFontSize}
        renderData={({ x, y, paidIn }) => {
          const safeY = y ?? 0;
          const safePaidIn = paidIn ?? 0;
          const formattedCutoffQuarter = formatCutoffDateQuarter(x);

          return {
            title: {
              value: formattedCutoffQuarter,
              suffix: isDraftCutoff(x) ? "(Draft)" : undefined,
            },
            nav: {
              value: U_Show.currencyThousandsNoDecimal("USD").show(safeY),
              color: palette.chart100,
            },
            "paid-in": {
              value: U_Show.currencyThousandsNoDecimal("USD").show(safePaidIn),
              color: palette.chart700,
            },
          };
        }}
      />
    ),
    [labelsFontSize, tooltipWidth, tooltipHeight, palette, isDraftCutoff],
  );

  const transformDatumForX = useCallback(
    (point: PortfolioAmountsChartPoint) => formatCutoffDateQuarter(point.x),
    [],
  );

  const zoomDomain = useMemo<{ x?: DomainTuple }>(
    () => ({
      // Set the zoom domain to the last 3 quarters
      // only if the device is small.
      x:
        navData.length > 2 && isSmallDevice
          ? [navData.length - 1, navData.length + 1]
          : undefined,
    }),
    [navData, isSmallDevice],
  );

  // Since we can't actually disable the initial animation
  // in Victory, we'll have to delay the rendering of the chart.
  useTimeout(
    useCallback(() => {
      setChartLoaded(true);
    }, [setChartLoaded]),
    500,
  );

  return (
    <Box
      sx={{
        opacity: chartLoaded ? 1 : 0,
        transition: "opacity 0.5s ease",
      }}
    >
      <VictoryChart
        domainPadding={10 * chartRatio}
        theme={VictoryTheme.material}
        padding={{
          top: 10,
          bottom: 10 * chartRatio,
          right: 20 * chartRatio * (isSmallDevice ? 0.6 : 1),
          left: 0,
        }}
        height={chartHeight}
        containerComponent={
          isSmallDevice ? (
            <VictoryZoomContainer
              allowZoom={false}
              allowPan={true}
              zoomDomain={zoomDomain}
              zoomDimension="x"
            />
          ) : undefined
        }
      >
        <VictoryAxis
          style={{
            axis: { stroke: "transparent" },
            ticks: { stroke: "transparent" },
            tickLabels: { fontSize: labelsFontSize, padding: 5 },
            grid: { stroke: "transparent" },
          }}
          offsetY={axisLabelOffset}
          fixLabelOverlap
        />

        <VictoryAxis
          dependentAxis
          style={{
            tickLabels: { fontSize: labelsFontSize, padding: 0 },
            ticks: { stroke: "transparent" },
            axis: { stroke: "transparent" },
            grid: {
              stroke: theme.palette.heritageV2.neutral300,
              strokeDasharray: "none",
            },
          }}
          orientation="right"
          tickFormat={U_Show.currencyThousandsNoDecimal("USD").show}
          fixLabelOverlap
        />

        <VictoryAxis
          style={{
            axis: {
              stroke: theme.palette.heritageV2.neutral500,
              strokeWidth: 2,
            },
            ticks: { stroke: "transparent" },
            tickLabels: { fill: "transparent" },
            grid: { stroke: "transparent" },
          }}
          fixLabelOverlap
        />

        <VictoryBar
          x={transformDatumForX}
          data={navData}
          labelComponent={!isSmallDevice ? tooltipNode : undefined}
          barWidth={isSmallDevice ? 40 : undefined}
          style={{
            data: {
              fill: ({ datum }) => {
                return isDraftCutoff(datum.x)
                  ? palette.neutral500
                  : palette.chart100;
              },
              transition: "opacity 0.5s ease",

              opacity: ({ datum }) =>
                !activeCutoff || isSameQuarter(activeCutoff, datum.x) ? 1 : 0.3,
            },
          }}
        />

        <VictoryLine
          x={transformDatumForX}
          data={paidInData}
          style={{
            data: {
              stroke: palette.chart700,
              strokeWidth: isSmallDevice ? 2 : 0.7,
            },
            parent: {
              pointerEvents: "none",
            },
          }}
          interpolation="step"
        />
      </VictoryChart>
    </Box>
  );
};
