import React from "react";
import { AppBar, IconButton, Toolbar } from "@mui/material";
import { Logo } from "../../../core/Logo/Logo";
import { CloseIcon, MenuIcon } from "../../../core/v2/Icon/Icon";
import { useHeritageV2Palette } from "../../../../utils/hooks/useHeritageV2Palette";
import { Link } from "react-router-dom";
import { Box } from "../../../core/v2/Box/Box";

type Props = {
  onMenuPress?: () => void;
  drawerOpen?: boolean;
  logoLink?: string;
};

/**
 * The top navbar component for non-authenticated routes.
 */
export const PublicNavbar: React.FC<Props> = ({
  onMenuPress,
  drawerOpen,
  logoLink,
}) => {
  const palette = useHeritageV2Palette();
  const DrawerIcon = drawerOpen ? CloseIcon : MenuIcon;

  return (
    <AppBar
      variant="outlined"
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        overflow: "hidden",
      }}
    >
      <Toolbar sx={{ justifyContent: "space-between", alignItems: "center" }}>
        <Box display="flex" alignItems="center" flex="0 0 auto">
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={onMenuPress}
            sx={(theme) => ({
              display: "none",
              marginLeft: "0px",
              paddingLeft: 0,
              [theme.breakpoints.down("lg")]: { display: "flex" },
            })}
          >
            <DrawerIcon stroke={palette.invariantWhite} variant="medium" />
          </IconButton>

          <Box
            sx={(theme) => ({
              [theme.breakpoints.down("lg")]: { display: "none" },
            })}
          >
            <Link to={logoLink ?? ""}>
              <Logo fill="white" height={25} />
            </Link>
          </Box>
        </Box>

        <Box></Box>
      </Toolbar>
    </AppBar>
  );
};
