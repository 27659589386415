import React, { useMemo, useState } from "react";
import { PageTable } from "../../../core/v2/PageTable/PageTable";
import {
  DocumentLinkStructure,
  TableColumn,
} from "../../../core/v2/Table/Table";
import { useInvestor } from "../../../../hooks/useInvestor";
import { DocumentsCapitalCall } from "../data/DocumentsCapitalCall";
import { formatDateDisplay1 } from "../../../../utils/date";
import { array as A, string as S, function as F, option as O } from "fp-ts";
import { tracker } from "../../../../utils/tracker";
import { config } from "../../../../utils/config";
import { Box } from "../../../core/v2/Box/Box";
import { useHeritageV2Palette } from "../../../../utils/hooks/useHeritageV2Palette";

type CapitalCallsDocumentsRow = {
  id: string;
  title: string;
  link: DocumentLinkStructure;
  deadline?: Date;
  vehicle?: string;
  isNew?: boolean;
};

type Props = {
  capitalCalls: ReadonlyArray<DocumentsCapitalCall>;
};

export const CapitalCallsDocuments: React.FC<Props> = ({ capitalCalls }) => {
  const investor = useInvestor();

  // Handle the local state for read documents. This is used to
  // update the `New` label after clicking on the download button.
  const [readDocuments, setReadDocuments] = useState<Record<string, true>>({});

  const palette = useHeritageV2Palette();

  const columns = useMemo<Array<TableColumn<CapitalCallsDocumentsRow>>>(
    () => [
      {
        headerName: "Title",
        field: "title",
        customColumnKind: {
          kind: "withInlineComponent",
          generateCell: ({ row, value }) => {
            if (!row) return {};

            // A document is new if it's not present in
            // `readDocuments` and `isNew` is true.
            const isNew = row.isNew && !readDocuments[row.id];

            return {
              prefixComponent: (
                <Box
                  width={7}
                  height={7}
                  mx={0}
                  borderRadius="100%"
                  bgcolor={isNew ? palette.accentLight : "transparent"}
                />
              ),
              value: `${value}`,
              boldText: isNew,
            };
          },
        },
        flex: 2,
        minWidth: 400,
      },
      {
        headerName: "Deadline",
        field: "deadline",
        type: "date",
        minWidth: 150,
      },
      {
        headerName: "Partnership",
        field: "vehicle",
        minWidth: 150,
      },
    ],
    [readDocuments, palette],
  );

  const rows = useMemo(() => {
    const computedRows = Array<CapitalCallsDocumentsRow>();
    const investorId = investor?.id;

    if (!investorId) return computedRows;

    for (const capitalCall of capitalCalls) {
      const deadlineDate = capitalCall.deadlineDate
        ? new Date(capitalCall.deadlineDate)
        : null;

      const formattedDeadlineDate = deadlineDate
        ? formatDateDisplay1(deadlineDate)
        : "-";

      const vehicles = capitalCall.transactions.map(
        (transaction) => transaction.allocation.vehicle.name,
      );

      const vehicle = F.pipe(vehicles, A.uniq(S.Eq), A.head, O.toUndefined);

      computedRows.push({
        id: capitalCall.uuid,
        title: `Capital Call Notice due ${formattedDeadlineDate}`,
        link: {
          kind: "download",
          link: config.serverBaseUrl + capitalCall.document.link,
          onClick() {
            tracker.trackEvent({
              name: "ShowCapitalCallDocument",
              payload: {
                capitalCallId: capitalCall.uuid,
                investorId: investorId,
              },
            });

            // Set the document as read in the local state.
            setReadDocuments((curr) => ({
              ...curr,
              [capitalCall.uuid]: true,
            }));
          },
        },
        deadline: deadlineDate || undefined,
        vehicle,
        isNew: !capitalCall.document.read,
      });
    }

    return computedRows;
  }, [capitalCalls, investor, setReadDocuments]);

  return (
    <PageTable
      kind="documents-table"
      title="Capital Calls"
      columns={columns}
      rows={rows}
      defaultSortingModel={{ field: "deadline", sort: "desc" }}
    />
  );
};
