import React, { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { CashflowAsset } from "../../data/CashflowAsset";
import { Input } from "../../../core/v2/Input/Input";
import { Select } from "../../../core/v2/Select/Select";
import { Dialog } from "../../../core/v2/Dialog/Dialog";
import { DateInput } from "../../../core/v2/DateInput/DateInput";
import { Box } from "../../../core/v2/Box/Box";
import { LocalDate } from "@heritageholdings/lib-commons-finance/lib/time/LocalDate";

const addSimulatedAssetSchema = z.object({
  fundId: z.string(),
  allocationAmount: z.coerce.number(),
  allocationDate: z.string(),
});

export type AddSimulatedAsset = z.infer<typeof addSimulatedAssetSchema>;

type Props = {
  open: boolean;
  onClose: () => void;
  assetsToSimulate: Array<CashflowAsset>;
  onSubmit: (data: AddSimulatedAsset) => void;
};

const currencySymbol = (currency: string): string =>
  ({
    EUR: "€",
    USD: "$",
  })[currency] ?? "$";

/**
 * Form to add a new simulated asset.
 */
export const AddSimulatedAssetDialog: React.FC<Props> = ({
  open,
  onClose,
  assetsToSimulate,
  onSubmit,
}) => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitSuccessful },
  } = useForm<AddSimulatedAsset>({
    mode: "onBlur",
    resolver: zodResolver(addSimulatedAssetSchema),
    defaultValues: {
      allocationDate: LocalDate.fromDateLocal(new Date()).toString(),
    },
  });

  const [fundCurrency, setFundCurrency] = useState<string>("$");

  const resetForm = useCallback(() => {
    reset({}, { keepDefaultValues: true });
  }, [reset]);

  const handleClose = useCallback(() => {
    resetForm();
    onClose();
  }, [resetForm, onClose]);

  useEffect(() => {
    resetForm();
  }, [isSubmitSuccessful, resetForm]);

  return (
    <Dialog
      title="Add asset"
      open={open}
      onClose={handleClose}
      cancelButtonText="Cancel"
      submitButtonText="Add"
      onCancel={handleClose}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Box width={{ md: 450 }}>
        <Box>
          <Controller
            name="fundId"
            control={control}
            render={({ field, formState: { errors } }) => (
              <Select
                label="Fund"
                options={assetsToSimulate.map((asset) => ({
                  kind: "value",
                  key: asset.id,
                  label: asset.name,
                  value: asset.id,
                }))}
                onChange={(v) => {
                  setFundCurrency(
                    currencySymbol(
                      assetsToSimulate.find((a) => a.id === v)?.currency ??
                        "USD",
                    ),
                  );
                  field.onChange(v);
                }}
                value={field.value}
                error={!!errors.fundId}
              />
            )}
          />
        </Box>

        <Box marginTop={3}>
          <Controller
            name="allocationAmount"
            control={control}
            render={({ field, formState: { errors } }) => (
              <Input
                label={`Amount in '000 ${fundCurrency}`}
                type="number"
                value={field.value?.toString()}
                onChange={field.onChange}
                error={!!errors.allocationAmount}
              />
            )}
          />
        </Box>

        <Box marginTop={3}>
          <Controller
            name="allocationDate"
            control={control}
            render={({ field }) => (
              <DateInput
                label="Starting date"
                value={new Date(field.value)}
                onChange={(newDate) => {
                  if (newDate)
                    field.onChange(
                      new LocalDate(
                        newDate.getFullYear(),
                        newDate.getMonth() + 1,
                        newDate.getDate(),
                      ).toString(),
                    );
                }}
              />
            )}
          />
        </Box>
      </Box>
    </Dialog>
  );
};
