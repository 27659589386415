import React, { useMemo } from "react";
import { List, Toolbar, Stack, useMediaQuery, useTheme } from "@mui/material";
import { DocumentsIcon } from "../../../core/v2/Icon/Icon";
import { Box } from "../../../core/v2/Box/Box";
import {
  Drawer,
  DrawerMenuItem,
  SwipeableDrawer,
} from "../SidebarDrawer/SidebarDrawer";
import { SidebarDrawerItem } from "../SidebarDrawer/SidebarDrawerItem";

type Props = {
  drawerOpen?: boolean;
  onDrawerOpen?: () => void;
  onDrawerClose?: () => void;
};

/**
 * The sidebar menu and drawer when the screen matches
 * a mobile device. This is used for the public routes.
 */
export const PublicSidebarDrawer: React.FC<Props> = ({
  drawerOpen,
  onDrawerOpen,
  onDrawerClose,
}) => {
  const theme = useTheme();
  const showLeftDrawer = useMediaQuery(theme.breakpoints.up("md"));

  const menuItems: Array<DrawerMenuItem> = useMemo(
    () => [
      {
        slug: "data-room",
        Icon: DocumentsIcon,
        name: "Data Room",
        selected: true,
        route: undefined,
        onClick: onDrawerClose,
      },
    ],
    [onDrawerClose],
  );

  const renderMenuItems = useMemo(
    () =>
      menuItems.map((item) => (
        <SidebarDrawerItem
          key={item.slug}
          slug={item.slug}
          Icon={item.Icon}
          name={item.name}
          route={item.route}
          selected={item.selected}
          onClick={item.onClick}
        />
      )),
    [menuItems],
  );

  return (
    <>
      {showLeftDrawer ? (
        <Drawer variant="permanent">
          <Toolbar />

          <Box
            sx={{
              overflow: "hidden",
              mt: 3,
              ml: 3,
              height: "100%",
            }}
          >
            <Stack
              direction="column"
              justifyContent="space-between"
              height="100%"
            >
              <List
                sx={{
                  // This will fix an inssue in which the borders
                  // of the items are cutted off. This should be
                  // the same size of the said borders.
                  py: "2px",
                }}
              >
                {renderMenuItems}
              </List>
            </Stack>
          </Box>
        </Drawer>
      ) : undefined}

      <SwipeableDrawer
        anchor="left"
        open={drawerOpen}
        onOpen={onDrawerOpen ?? (() => undefined)}
        onClose={onDrawerClose ?? (() => undefined)}
      >
        <Toolbar />

        <Stack
          direction="column"
          justifyContent="space-between"
          height="100%"
          sx={{
            p: 1,
          }}
        >
          <List>{renderMenuItems}</List>
        </Stack>
      </SwipeableDrawer>
    </>
  );
};
