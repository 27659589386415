import { GridAggregationFunction } from "@mui/x-data-grid-premium";

export const customFirstValAggregationLabel =
  "customFirstValAggregation" as const;

/**
 * Aggregate a "custom" datagrid value using the very first value
 * of the rows aggregated as the result.
 */
export const customFirstValAggregation: GridAggregationFunction<
  string,
  string | null
> = {
  apply: (params) => {
    if (params.values.length === 0) return null;

    const firstValue = params.values[0];

    return firstValue;
  },
  label: customFirstValAggregationLabel,
  columnTypes: ["custom"],
};
