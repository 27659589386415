import { useCallback, useMemo } from "react";
import { useInvestor } from "./useInvestor";
import { useLayoutContext } from "../routes/LayoutRoute/LayoutRoute";
import {
  generateConfirmedQuarters,
  generateDraftQuarters,
} from "../lib/utils/date";

type UseRetrieveCutoffsReturnValue = {
  confirmedCutoffs: Array<Date>;
  draftCutoffs: Array<Date>;
  fetching: boolean;
  isDraftCutoff: (date: Date) => boolean;
};

/**
 * Hook used to retrieve all the cutoffs (quarters)
 * in a portfolio.
 */
export function useRetrieveCutoffs(): UseRetrieveCutoffsReturnValue {
  const {
    configuration: { defaultQuarter },
  } = useLayoutContext();

  const investor = useInvestor();

  const defaultQuarterDate = useMemo(
    () => new Date(defaultQuarter),
    [defaultQuarter],
  );

  const oldestAllocationDate = useMemo(() => {
    const maybeAllocationDate = investor?.firstAllocation?.allocationDate;
    return maybeAllocationDate ? new Date(maybeAllocationDate) : undefined;
  }, [investor]);

  const confirmedCutoffs = useMemo(
    () => generateConfirmedQuarters(defaultQuarterDate, oldestAllocationDate),
    [oldestAllocationDate, defaultQuarterDate],
  );

  const draftCutoffs = useMemo(
    () => generateDraftQuarters(defaultQuarterDate, oldestAllocationDate),
    [defaultQuarterDate, oldestAllocationDate],
  );

  const isDraftCutoff = useCallback(
    (date: Date) => date.getTime() > defaultQuarterDate.getTime(),
    [defaultQuarterDate],
  );

  const stableResult = useMemo<UseRetrieveCutoffsReturnValue>(
    () => ({
      confirmedCutoffs,
      draftCutoffs,
      fetching: false,
      isDraftCutoff,
    }),
    [confirmedCutoffs, draftCutoffs, isDraftCutoff],
  );

  return stableResult;
}
