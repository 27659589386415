import React, { useMemo } from "react";
import { Card } from "../../../core/v2/Card/Card";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useHeritageV2Palette } from "../../../../utils/hooks/useHeritageV2Palette";
import { DetailedAsset } from "../data/DetailedAsset";
import * as U_Show from "@heritageholdings/lib-commons-finance/lib/show";
import { AssetStatus } from "../../../assets/v2/data/AssetStatus";

type Props = {
  asset: DetailedAsset;
  status: AssetStatus;
};

/**
 * The "Terms" tab in the asset details.
 */
export const AssetTerms: React.FC<Props> = ({ asset, status }) => {
  const palette = useHeritageV2Palette();
  const isClosed = useMemo(() => status === "closed", [status]);

  const informations = useMemo(
    () => [
      {
        label: "Fundraising",
        value: (
          <Typography
            color={isClosed ? palette.statusNegative : palette.statusPositive}
          >
            <strong>{isClosed ? "Closed" : "Open"}</strong>
          </Typography>
        ),
      },
      {
        label: "Closing date",
        value: <strong>{asset.closingDate}</strong>,
      },
      ...(asset.lifetime
        ? [
            {
              label: "Fund lifetime",
              value: (
                <>
                  <strong>{asset.lifetime}</strong> years
                </>
              ),
            },
          ]
        : []),
      ...(asset.investmentPeriod
        ? [
            {
              label: "Investment period",
              value: (
                <>
                  <strong>{asset.investmentPeriod}</strong> years
                </>
              ),
            },
          ]
        : []),
      {
        label: "Min. participations",
        value: (
          <strong>
            {U_Show.currencyDecimal("USD").show(asset.minimumParticipationUsd)}
          </strong>
        ),
      },
      ...(asset.currency
        ? [
            {
              label: "Fund currency",
              value: asset.currency,
            },
          ]
        : []),
      ...(asset.gpCommitment
        ? [
            {
              label: "GP Commitment",
              value: (
                <>
                  A minimum of{" "}
                  <strong>
                    {U_Show.percentNoDecimal.show(asset.gpCommitment / 100)}
                  </strong>{" "}
                  of aggregate commitments
                </>
              ),
            },
          ]
        : []),
    ],
    [isClosed, palette, asset],
  );

  return (
    <Card backgroundColor="invariantWhite">
      <Grid columns={2} rowSpacing={2} maxWidth={800} container>
        {informations.map(({ label, value }) => (
          <>
            <Grid
              xs={1}
              justifyContent="center"
              display="flex"
              flexDirection="column"
            >
              <Typography variant="h6" color={palette.neutral700}>
                {label}
              </Typography>
            </Grid>

            <Grid xs={1}>
              <Typography>{value}</Typography>
            </Grid>
          </>
        ))}
      </Grid>
    </Card>
  );
};
