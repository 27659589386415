/**
 * return a promise that resolves to true if the copy was successful
 * false otherwise (see https://developer.mozilla.org/en-US/docs/Web/API/Clipboard)
 * @param text
 */
export const copyToClipboard = async (text: string): Promise<boolean> => {
  try {
    await navigator.clipboard.writeText(text);
    return true;
  } catch {
    return false;
  }
};
