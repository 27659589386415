import { OverrideProperties } from "type-fest";
import { function as F, either as E } from "fp-ts";

import { Percent } from "@heritageholdings/lib-commons-finance/lib/units/percent";
import { Numeric } from "@heritageholdings/lib-commons-finance/lib/units/numeric";
import { LocalDate } from "@heritageholdings/lib-commons-finance/lib/time/LocalDate";
import { LocalDateFromISOString } from "@heritageholdings/lib-commons-finance/lib/time/LocalDateFromISOString";

import { Asset, JCurveYear } from "../../../generated/urql";

type CashflowV3RawAsset = Pick<
  Asset,
  | "closingDate"
  | "jcurveYears"
  | "moicBase"
  | "moicLow"
  | "moicHigh"
  | "name"
  | "id"
  | "currency"
>;

/**
 * The only needed fields for the `Asset` type transformed
 * in order to be used by the `Money` library.
 */
export type CashflowAsset = OverrideProperties<
  CashflowV3RawAsset,
  {
    closingDate?: LocalDate;
    jcurveYears: Array<
      OverrideProperties<
        JCurveYear,
        {
          distributionPercent: Percent;
          drawdownPercent: Percent;
          year: number;
        }
      >
    >;
    moicBase: Numeric;
    moicLow: Numeric;
    moicHigh: Numeric;
  }
>;

/**
 * Transform the `Asset` type to the `CashflowV3Asset` type.
 */
export function assetToCashflowAsset(asset: CashflowV3RawAsset): CashflowAsset {
  const closingDate = F.pipe(
    LocalDateFromISOString.decode(asset.closingDate),
    E.getOrElseW(() => undefined),
  );
  return {
    ...asset,
    closingDate,
    jcurveYears: asset.jcurveYears.map((year) => ({
      ...year,
      distributionPercent: new Percent(year.distributionPercent),
      drawdownPercent: new Percent(year.drawdownPercent),
      year: year.year,
    })),
    moicBase: new Numeric(asset.moicBase),
    moicLow: new Numeric(asset.moicLow),
    moicHigh: new Numeric(asset.moicHigh),
  };
}
