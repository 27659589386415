import { useQuery } from "urql";
import {
  CashflowSimulatorDocument,
  CashflowSimulatorQuery,
  CashflowSimulatorQueryVariables,
} from "../generated/urql";
import { formatCutoffDate, getTodayCutoff } from "../utils/cutoff";
import { disableSuspenseContext } from "../utils/queries";

/**
 * Hook used to query the cashflow simulator.
 */
export function useCashflowSimulatorQueryProxy(investorId: string) {
  const query = useQuery<
    CashflowSimulatorQuery,
    CashflowSimulatorQueryVariables
  >({
    query: CashflowSimulatorDocument,
    variables: {
      investorId,
      cutoffs: [formatCutoffDate(getTodayCutoff())],
    },
    context: disableSuspenseContext,
  });

  return query;
}
