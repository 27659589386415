import { IterableElement } from "type-fest";
import { PortfolioRouteQuery } from "../generated/urql";

/**
 * Get the first capital call date from the allocations.
 */
export const getFirstCapitalCallDate = (
  allocations: Pick<
    IterableElement<PortfolioRouteQuery["investor"]["portfolioSituation"]>,
    "byAllocation" | "totals" | "annotatedByAllocation"
  >["byAllocation"],
): string | undefined =>
  allocations.reduce(
    (acc, allocation) => {
      const { firstCapitalCallDate } = allocation.allocation.asset;

      if (acc === undefined) return firstCapitalCallDate ?? undefined;
      if (!firstCapitalCallDate) return acc;

      if (new Date(acc) > new Date(firstCapitalCallDate))
        return firstCapitalCallDate;

      return acc;
    },
    undefined as string | undefined,
  );

/**
 *
 */
export const getCommittedCapitalCalledPercent = (
  paidIn: number,
  allocationAmount: number,
) => (paidIn / allocationAmount) * 100;
