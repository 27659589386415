import React, { CSSProperties } from "react";
import { Skeleton } from "@mui/material";
import { useMemo } from "react";
import { P, match } from "ts-pattern";

type SkeletonLoaderSize =
  | {
      height: number | "table";
    }
  | {
      aspectRatio: string;
    };

type Props = {
  size: SkeletonLoaderSize;
};

/**
 * A skeleton loader component.
 */
export const SkeletonLoader: React.FC<Props> = ({ size }) => {
  const computedSx = useMemo<CSSProperties>(
    () =>
      match(size)
        .with({ height: "table" }, () => ({ height: 400 }))
        .with({ height: P.number }, ({ height }) => ({ height }))
        .with({ aspectRatio: P.string }, ({ aspectRatio }) => ({
          display: "block",
          width: "100%",
          height: "auto",
          aspectRatio,
        }))
        .exhaustive(),
    [size],
  );

  return <Skeleton variant="rounded" style={computedSx} />;
};
