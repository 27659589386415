import React from "react";
import { NavigationTabs } from "../../../core/v2/NavigationTabs/NavigationTabs";
import { PortfolioCompanies } from "../PortfolioCompanies/PortfolioCompanies";
import { PortfolioAssets } from "../PortfolioAssets/PortfolioAssets";

export const InvestmentsView: React.FC = () => {
  return (
    <NavigationTabs
      defaultTab="assets"
      tabs={[
        {
          route: "assets",
          displayName: "Assets",
          component: <PortfolioAssets />,
        },

        {
          route: "companies",
          displayName: "Companies",
          component: <PortfolioCompanies />,
        },
      ]}
    />
  );
};
