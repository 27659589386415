export const generateFundAggregationId = (
  fundName: string,
  currency: string,
): string => `${fundName}#${currency}`;

/**
 * Given an array of funds names, format them into a single string.
 */
export const formatAggregatedFundName = (funds: Set<string>): string => {
  const fundsArray = Array.from(funds);

  return fundsArray.length <= 2
    ? fundsArray.join(", ")
    : `${fundsArray[0]}, ${fundsArray[1]} and ${fundsArray.length - 2} more`;
};
