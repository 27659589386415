import React from "react";
import { VictoryTooltip, VictoryTooltipProps } from "victory";
import {
  GenericTooltip,
  TooltipBasePoint,
  TooltipRenderDataFn,
} from "./GenericTooltip";
import { useHeritageV2Palette } from "../../../../utils/hooks/useHeritageV2Palette";
import { useChartRatio } from "../../../../utils/hooks/useChartRatio";

type Props<T extends TooltipBasePoint> = VictoryTooltipProps & {
  width: number;
  height: number;
  labelsFontSize: number;
  renderData: TooltipRenderDataFn<T>;
};

/**
 * The generic tooltip container node.
 */
export const GenericTooltipNode = <T extends TooltipBasePoint>({
  width,
  height,
  labelsFontSize,
  renderData,
  ...props
}: Props<T>) => {
  const palette = useHeritageV2Palette();
  const chartRatio = useChartRatio();

  // These calculations are used to fix the tooltip vertical
  // position probably due to a bug in Victory.
  // Using a custom and generic component for a tooltip will render
  // it a bit higher than the point it is supposed to be attached to.
  const verticalOffset = 6;
  const safeX = props.x ?? 0;
  const safeY = props.y ?? 0;
  const pointY = (props.datum as TooltipBasePoint | undefined)?.y ?? 0;
  const isInverted = pointY < 0;
  const computedY = isInverted
    ? safeY - verticalOffset - 1
    : safeY + verticalOffset;

  return (
    <VictoryTooltip
      {...props}
      labelComponent={
        <GenericTooltip<T>
          baseFontSize={labelsFontSize}
          tooltipWidth={width}
          tooltipHeight={height}
          renderData={renderData}
          chartRatio={chartRatio}
        />
      }
      x={safeX}
      y={computedY}
      flyoutPadding={10 * chartRatio}
      flyoutWidth={width}
      flyoutHeight={height}
      pointerLength={3 * chartRatio}
      pointerWidth={5 * chartRatio}
      flyoutStyle={{
        ...props.flyoutStyle,
        stroke: "transparent",
        fill: palette.text900,
      }}
      cornerRadius={1}
    />
  );
};

// This is required by Victory Chart in order
// to make the tooltip up and running.
GenericTooltipNode.defaultEvents = VictoryTooltip.defaultEvents;
