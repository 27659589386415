import React, { useMemo } from "react";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { SingleAsset } from "../data/SingleAsset";
import { AssetCard } from "../AssetCard/AssetCard";
import { useAssetUrl } from "../../../../hooks/useAssetUrl";
import { useNavigate } from "react-router-dom";
import { PendingAllocation } from "../data/PendingAllocation";
import { NoDataPlaceholder } from "../../../core/v2/NoDataPlaceholder/NoDataPlaceholder";
import { Box } from "../../../core/v2/Box/Box";

type Props = {
  openAssets: ReadonlyArray<SingleAsset>;
  pendingAllocations: ReadonlyArray<PendingAllocation>;
};

/**
 * This component shows a list of assets using the `AssetCard` component.
 */
export const AssetsView: React.FC<Props> = ({
  openAssets,
  pendingAllocations,
}) => {
  const navigate = useNavigate();
  const generateAssetUrl = useAssetUrl();

  const assetsRenderer = useMemo(
    () =>
      openAssets.map((asset) => {
        const assetUrl = generateAssetUrl(asset.id);

        // FIXME: This could be simplified in complexity using
        // a data structure like a `Map` or a `Record` instead of
        // an array directly from the parent component.
        const maybePendingAllocation = pendingAllocations.find(
          (allocation) => allocation.asset.id === asset.id,
        );

        return (
          <Grid key={asset.id} sm={12} md={6} lg={6} xl={4} xxl={3}>
            <AssetCard
              asset={asset}
              onDetailsClick={() => navigate(assetUrl)}
              pendingAllocation={maybePendingAllocation}
            />
          </Grid>
        );
      }),
    [openAssets, generateAssetUrl, navigate, pendingAllocations],
  );

  if (openAssets.length <= 0)
    return (
      <Box height="100%">
        <NoDataPlaceholder message="No opportunities available" />
      </Box>
    );

  return (
    <>
      <Box>
        <Typography variant="h1">Invest</Typography>
      </Box>

      <Box mt={2}>
        <Grid columns={12} spacing={1} container>
          {assetsRenderer}
        </Grid>
      </Box>
    </>
  );
};
