import { Typography } from "@mui/material";
import React from "react";

import { Link } from "react-router-dom";

import {
  ManageInvestorRouteComponent,
  useSubmitInvestorAccountFormMutation,
} from "../../generated/urql";

import { useRoutesResolver } from "../../lib/hooks/useRoutesResolver";
import { useInvestor } from "../../hooks/useInvestor";

import { ContainerPaper } from "../../components/core/ContainerPaper/ContainerPaper";
import { PageContainer } from "../../components/layout/PageContainer/PageContainer";
import { ManageInvestorView } from "../../components/manageInvestor/ManageInvestorView/ManageInvestorView";

import { RouteClasses } from "../RootRoute/RootRoute";
import { handleQueryData } from "../../utils/queries";

export const ManageInvestorRoute = () => {
  const investor = useInvestor();
  const { generatePathForRoute } = useRoutesResolver<RouteClasses>();
  const [_accountFormMutationResult, executeAccountFormMutation] =
    useSubmitInvestorAccountFormMutation();

  if (investor === undefined) {
    // FIXME: handle error
    return null;
  }

  return (
    <PageContainer
      title={`Manage investor account of ${investor.businessName}`}
    >
      <Typography variant="subtitle1">
        This is the settings page for the investor account, if you are looking
        for your user settings you can find them{" "}
        <Link to={generatePathForRoute("UserProfileRoute")}>here</Link>.
      </Typography>
      <ContainerPaper>
        <ManageInvestorRouteComponent
          variables={{ investorId: investor.id }}
          pause={investor === undefined}
          requestPolicy="network-only"
          children={handleQueryData((data) => (
            <ManageInvestorView
              investor={data.investor}
              executeAccountFormMutation={executeAccountFormMutation}
            />
          ))}
        />
      </ContainerPaper>
    </PageContainer>
  );
};
