import { Box, Typography } from "@mui/material";
import { array as A, function as F, option as O } from "fp-ts";
import React, { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { match } from "ts-pattern";
import { IterableElement } from "type-fest";
import { AssetRouteQuery, useAssetRouteQuery } from "../../generated/urql";
import { useRoutesResolver } from "../../lib/hooks/useRoutesResolver";
import { AssetView as AssetViewV2 } from "../../components/asset/v2/AssetView/AssetView";
import { AssetStatus } from "../../components/assets/v2/data/AssetStatus";
import { GenericErrorBox } from "../../components/commons/GenericErrorBox";
import { Button } from "../../components/core/v2/Button/Button";
import { ArrowLeft } from "../../components/core/v2/Icon/Icon";
import { useInvestor } from "../../hooks/useInvestor";
import { RouteClasses } from "../RootRoute/RootRoute";

type Asset =
  | IterableElement<AssetRouteQuery["investor"]["openAssets"]>
  | IterableElement<AssetRouteQuery["investor"]["closedAssets"]>;

type Props = {
  fromSection: "investments" | "assets";
};

export const AssetRoute: React.FC<Props> = ({ fromSection }) => {
  const investor = useInvestor();
  const { assetId } = useParams();
  const navigate = useNavigate();
  const { generatePathForRoute } = useRoutesResolver<RouteClasses>();

  const handleNavigateBack = useCallback(() => {
    if (!investor) return;

    const goBackPath = match(fromSection)
      .with("investments", () =>
        generatePathForRoute("AssetsRoute", {
          investorId: investor.id,
        }),
      )
      .with("assets", () =>
        generatePathForRoute("InvestmentsRoute", {
          investorId: investor.id,
        }),
      )
      .exhaustive();

    navigate(goBackPath);
  }, [fromSection, navigate, generatePathForRoute, investor]);

  const [{ data, error }] = useAssetRouteQuery({
    variables: {
      investorId: investor?.id ?? "",
      assetId: assetId ?? "",
    },
    pause: investor === undefined, // do not query until investor is defined
  });
  // Check that route params are defined
  if (investor === undefined || assetId === undefined) {
    return <div>some route params are not defined</div>; // TODO: handle error
  }

  if (!data || error) return <GenericErrorBox />;

  // Lookup asset from loaded data

  const [openAssets, closedAssets, enabledAssets, documents, contracts] = [
    data.investor.openAssets,
    data.investor.closedAssets,
    data.investor.enabledAssets,
    data.investor.documents,
    data.investor.contracts,
  ];
  // search the asset and return the found asset indicating if it has
  // been found in open or closed asset
  const assetWithStatus: { asset: Asset; status: AssetStatus } | undefined =
    F.pipe(
      [
        ...openAssets.map((asset) => ({
          asset,
          status: "open" as const,
        })),
        ...closedAssets.map((asset) => ({
          asset,
          status: "closed" as const,
        })),
        ...enabledAssets.map((asset) => ({
          asset,
          status: "enabled" as const,
        })),
      ],
      A.findFirst(({ asset }) => asset.id === assetId),
      O.toUndefined,
    );
  // Asset not found
  if (assetWithStatus === undefined) {
    // TODO: handle error, see https://github.com/heritageholdings/iconic/issues/1013
    return <Typography>asset not found</Typography>;
  }

  const { asset, status } = assetWithStatus;

  return (
    <>
      <Button
        variant="mini-text"
        LeftIcon={ArrowLeft}
        onClick={handleNavigateBack}
      >
        {match(fromSection)
          .with("investments", () => "All opportunities")
          .with("assets", () => "Assets")
          .exhaustive()}
      </Button>

      <Box mt={0}>
        <AssetViewV2
          asset={asset}
          status={status}
          documents={documents}
          contracts={contracts}
        />
      </Box>
    </>
  );
};
