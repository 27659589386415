import { function as F, array as A, record as R } from "fp-ts";

import { getYear, formatISO } from "date-fns";
import { formatDateQuarterExtended } from "../../utils/date";

/**
 * Converts a Date to an option entry to be used in the QuarterSelect component.
 */
const quarterOptionFromDate = (
  i: number,
  cutoff: Date,
): [string, [[string, string]]] => [
  String(getYear(cutoff)),
  [
    [
      formatISO(cutoff, { representation: "date" }),
      i === 0
        ? "Today" // assume that the first cutoff date is for the "Today" view
        : formatDateQuarterExtended(cutoff),
    ],
  ],
];

/**
 * Returns the options to be used for the QuarterSelect component from a list
 * of cutoff dates representing quarters.
 *
 * The output is a Record of years to Record of cutoff dates to Quarter names.
 */
export const getQuartersFromCutoffs = (
  cutoffs: Date[],
): Record<string, Record<string, string>> =>
  F.pipe(
    cutoffs,
    A.mapWithIndex(quarterOptionFromDate),
    R.fromFoldable(A.getSemigroup<[string, string]>(), A.Foldable),
    R.map(R.fromEntries),
  );
