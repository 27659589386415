import React from "react";
import { Paper, Typography } from "@mui/material";
import { useHeritageV2Palette } from "../../../../utils/hooks/useHeritageV2Palette";
import { Box } from "../Box/Box";

type Props = {
  value: string;
  symbol?: string;
  title: string;
  subTitle?: string;
  highlightValue?: boolean;
};

/**
 * A single box used across the application in order
 * to display a specifc statistic.
 */
export const StatBox: React.FC<Props> = ({
  value,
  symbol,
  title,
  subTitle,
  highlightValue,
}) => {
  const palette = useHeritageV2Palette();

  return (
    <Paper>
      <Box p={2}>
        <Box>
          <Typography
            variant="figuresNumber"
            color={highlightValue ? palette.chart100 : undefined}
          >
            {value}
          </Typography>

          {symbol ? (
            <Typography
              variant="figuresSymbol"
              color={highlightValue ? palette.chart100 : undefined}
            >
              {symbol}
            </Typography>
          ) : undefined}
        </Box>

        <Box mt={0}>
          <Typography variant="h6">{title}</Typography>

          {subTitle ? (
            <Typography variant="h6" color={palette.neutral500}>
              {subTitle}
            </Typography>
          ) : undefined}
        </Box>
      </Box>
    </Paper>
  );
};
