import { Card } from "../../../core/v2/Card/Card";
import React from "react";
import { Box, Typography } from "@mui/material";
import { Input } from "../../../core/v2/Input/Input";
import { Button } from "../../../core/v2/Button/Button";
import { useLayoutContext } from "../../../../routes/LayoutRoute/LayoutRoute";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod/dist/zod";
import { z } from "zod";
import {
  useLayoutRouteQuery,
  useSubmitUserAccountFormMutation,
} from "../../../../generated/urql";
import { useNotifications } from "../../../../utils/notifications";

const updateNameSurnameSchema = z.object({
  firstName: z.string().min(2, "First name must be at least 2 characters"),
  lastName: z.string().min(2, "Last name must be at least 2 characters"),
});

type NameSurnameSchema = z.infer<typeof updateNameSurnameSchema>;

export const EditNameComponent: React.FC = () => {
  const { authUser } = useLayoutContext();
  const [_, refresh] = useLayoutRouteQuery();
  const [, submit] = useSubmitUserAccountFormMutation();
  const { successNotification, errorNotification } = useNotifications();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isValid, isDirty },
    reset,
  } = useForm<NameSurnameSchema>({
    mode: "onChange",
    resolver: zodResolver(updateNameSurnameSchema),
    defaultValues: {
      firstName: authUser.firstName,
      lastName: authUser.lastName,
    },
  });

  const submitButtonEnabled = isValid && isDirty && !isSubmitting;

  return (
    <Card>
      <form
        onSubmit={handleSubmit(async (formValues) => {
          const mutationResult = await submit({
            input: formValues,
          });

          if (
            mutationResult.error ||
            !mutationResult.data?.submitUserAccountForm.success
          ) {
            errorNotification("Failed to update name and surname");
          } else {
            successNotification("Successfully updated name and surname");
            refresh();
            reset(formValues);
          }
        })}
      >
        <Box
          display={"flex"}
          justifyContent={"flex-start"}
          flexDirection={"column"}
        >
          <Typography variant={"h3"}>Edit name</Typography>

          <Box mt={1}>
            <Controller
              name="firstName"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <Input
                  error={error !== undefined}
                  label={"First name"}
                  defaultValue={authUser.firstName}
                  onChange={(e) => {
                    field.onChange(e);
                  }}
                  helperText={error?.message}
                  disabled={isSubmitting}
                />
              )}
            />
          </Box>

          <Box mt={1}>
            <Controller
              name="lastName"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <Input
                  error={error !== undefined}
                  label={"Last name"}
                  defaultValue={authUser.lastName}
                  onChange={(e) => {
                    field.onChange(e);
                  }}
                  helperText={error?.message}
                  disabled={isSubmitting}
                />
              )}
            />
          </Box>

          <Box mt={2} flex={"0 1 auto"} alignSelf={"flex-end"}>
            <Button
              variant={"primary"}
              type="submit"
              disabled={!submitButtonEnabled}
            >
              Save changes
            </Button>
          </Box>
        </Box>
      </form>
    </Card>
  );
};
