import { it, enGB, enUS } from "date-fns/locale";
import { format, getQuarter, getYear, isValid } from "date-fns";
import { function as F, option as O } from "fp-ts";

const mapLanguageLocale: Record<string, Locale> = {
  it: it,
  "it-IT": it,
  en: enGB,
  "en-GB": enGB,
  "en-US": enUS,
};
const defaultLocale: Locale = enGB;
const currentLocale: Locale =
  mapLanguageLocale[navigator?.language ?? "en"] ?? defaultLocale;
export const getCurrentLocale = (): Locale => currentLocale;

/**
 * format a Date with the given format.
 */
const formatDate =
  (dateFormat: string) =>
  (date: Date): string =>
    format(date, dateFormat, { locale: getCurrentLocale() });

/**
 * Format a date using the `yyyy-MM-dd` format.
 */
export const formatDateDisplay1 = formatDate("yyyy-MM-dd");

/**
 * Format a date using the `yyyy-MM-dd HH:mm:ss` format.
 */
export const formatDateTimeDisplay1 = formatDate("yyyy-MM-dd HH:mm:ss");

/**
 * Format a date in a quarter extended format.
 */
export const formatDateQuarterExtended = (date: Date) =>
  `Quarter ${getQuarter(date)} - ${getYear(date)}`;

/**
 * format a Date with day,month,year
 * 28th April 1990
 * - it/enGB -> 28/04/1990
 * - enUS -> 04/28/1990
 */
const formatSimple = formatDate("P");

/**
 * return some with the formatted date when the given string represents a valid date
 * @param date
 */
export const convertAndFormatSimple = (date: string): O.Option<string> =>
  F.pipe(
    date,
    (d) => new Date(d),
    O.fromPredicate(isValid),
    O.map(formatSimple),
  );
