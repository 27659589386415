import { Stack, TablePaginationProps, Typography } from "@mui/material";
import React, { useCallback, useMemo } from "react";
import { Select } from "../Select/Select";
import { SelectOption } from "../Select/Select";
import { IconButton } from "../IconButton/IconButton";
import { ChevronLeft, ChevronRight } from "../Icon/Icon";
import { Box } from "../Box/Box";

type Props = Omit<
  TablePaginationProps,
  | "component"
  | "SelectProps"
  | "rowsPerPageOptions"
  | "onRowsPerPageChange"
  | "onPageChange"
> & {
  rowsPerPageOptions: Array<number>;
  onRowsPerPageChange: (page: number) => void;
  onPageChange: (page: number) => void;
};

/**
 * Custom pagination component used in the main `Table`.
 */
export const TablePagination: React.FC<Props> = ({
  count,
  page,
  onPageChange,
  onRowsPerPageChange,
  rowsPerPageOptions,
  rowsPerPage,
}) => {
  const rowsOptions = useMemo<Array<SelectOption>>(
    () =>
      rowsPerPageOptions?.map((v) => ({
        kind: "value",
        label: v.toString(),
        value: v.toString(),
        key: v.toString(),
      })),
    [rowsPerPageOptions],
  );

  const handleRowsPerPageChange = useCallback(
    (value: string) => {
      onRowsPerPageChange(Number(value));
    },
    [onRowsPerPageChange],
  );

  const totalPages = useMemo(
    () => Math.ceil(count / rowsPerPage),
    [count, rowsPerPage],
  );

  const currentRowsRange = useMemo(() => {
    const start = page * rowsPerPage + 1;
    const end = Math.min((page + 1) * rowsPerPage, count);

    return `${start}-${end}`;
  }, [count, page, rowsPerPage]);

  const isNextDisabled = useMemo(
    () => page + 1 >= totalPages,
    [page, totalPages],
  );

  const isPrevDisabled = useMemo(() => page === 0, [page]);

  const handlePrevClick = useCallback(() => {
    onPageChange?.(page - 1);
  }, [onPageChange, page]);

  const handleNextClick = useCallback(() => {
    onPageChange?.(page + 1);
  }, [onPageChange, page]);

  return (
    <Stack
      direction="row"
      justifyContent="flex-end"
      alignItems="center"
      flexWrap="wrap"
      gap={1}
    >
      <Box flexBasis={{ sm: "100%", md: "auto" }}>
        <Stack direction="row" alignItems="center" gap={1}>
          <Box flexBasis="100%" flexGrow={1} flexShrink={1}>
            <Select
              value={rowsPerPage.toString()}
              options={rowsOptions}
              onChange={handleRowsPerPageChange}
            />
          </Box>

          <Box flexBasis="auto" flexGrow={0} flexShrink={0}>
            <Typography variant="formLabel">Rows per page</Typography>
          </Box>
        </Stack>
      </Box>

      <Box flexBasis={{ sm: "100%", md: "auto" }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          gap={0}
        >
          <Box>
            <IconButton
              Icon={ChevronLeft}
              disabled={isPrevDisabled}
              onClick={handlePrevClick}
            />
          </Box>

          <Box>
            <Typography variant="formLabel">
              {currentRowsRange} of {totalPages}
            </Typography>
          </Box>

          <Box>
            <IconButton
              Icon={ChevronRight}
              disabled={isNextDisabled}
              onClick={handleNextClick}
            />
          </Box>
        </Stack>
      </Box>
    </Stack>
  );
};
