import React, { useCallback, useMemo, useState } from "react";
import * as U_Show from "@heritageholdings/lib-commons-finance/lib/show";
import {
  AnimatePropTypeInterface,
  DomainTuple,
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryLine,
  VictoryTheme,
  VictoryZoomContainer,
} from "victory";
import { useMediaQuery, useTheme } from "@mui/material";
import { useTimeout } from "../../../../lib/hooks/useTimeout";
import { Money } from "@heritageholdings/lib-commons-finance/lib/units/money";
import { CashflowJCurve } from "../../data/CashflowJCurve";
import { P, match } from "ts-pattern";
import { useHeritageV2Palette } from "../../../../utils/hooks/useHeritageV2Palette";
import { GenericTooltipNode } from "../../../core/v2/GenericTooltip/GenericTooltipNode";
import { useChartRatio } from "../../../../utils/hooks/useChartRatio";
import { Box } from "../../../core/v2/Box/Box";

export const drawdownBarsTestId = "drawdown-bars";
export const distributionBarsTestId = "distribution-bars";
const cumulativeLineTestId = "cumulative-line";

/**
 * A point on the chart.
 */
type CashflowPoint = {
  x: string;
  y: number;
  cumulative?: number;
  label?: string;
};

/**
 * Generate an array of `Point`, a `min` and a `max` from a
 * given `Record<string, Money | undefined>`.
 */
function generatePointsAndMeta(
  data: Record<string, Money | undefined>,
  cumulativeData?: Record<string, Money | undefined>,
): [Array<CashflowPoint>, number, number] {
  const points: Array<CashflowPoint> = [];
  let min = 0;
  let max = 0;

  for (const [year, distribution] of Object.entries(data)) {
    if (!distribution) continue;

    points.push({
      x: year,
      y: distribution.toNormalizedAmount(),
      cumulative: cumulativeData?.[year]?.toNormalizedAmount(),
      label: "",
    });

    min = Math.min(min, distribution.toNormalizedAmount());
    max = Math.max(max, distribution.toNormalizedAmount());
  }

  return [points, min, max];
}

type Props = {
  forecastedJCurve: CashflowJCurve;
};

/**
 * The Chashflow Simulator chart component.
 */
export const CashflowSimulatorChart: React.FC<Props> = ({
  forecastedJCurve,
}) => {
  const [chartLoaded, setChartLoaded] = useState(false);
  const theme = useTheme();
  const heritagePalette = useHeritageV2Palette();
  const chartRatio = useChartRatio();

  const [drawdownPoints, minDrawdown, _maxDrawdown] = useMemo(
    () =>
      generatePointsAndMeta(
        forecastedJCurve.drawdown,
        forecastedJCurve.cumulative,
      ),
    [forecastedJCurve],
  );

  const [distributionPoints, _minDistribution, maxDistribution] = useMemo(
    () =>
      generatePointsAndMeta(
        forecastedJCurve.distribution,
        forecastedJCurve.cumulative,
      ),
    [forecastedJCurve],
  );

  const [cumulativePoints, minCumulative, maxCumulative] = useMemo(
    () => generatePointsAndMeta(forecastedJCurve.cumulative),
    [forecastedJCurve],
  );

  // This domain will center the chart around its origin.
  // N.B. It's possible that a bar of the drawdown or distribution
  // could be higher than the cumulative line, so we need to
  // take that into account.
  const domain = useMemo<DomainTuple>(() => {
    const min = Math.min(minCumulative, minDrawdown);
    const max = Math.max(maxCumulative, maxDistribution);
    const higher = Math.max(Math.abs(min), Math.abs(max));

    return [-higher, higher];
  }, [minCumulative, maxCumulative, minDrawdown, maxDistribution]);

  const mqMinLg = useMediaQuery(theme.breakpoints.up("xxl"));
  const mqMinMd = useMediaQuery(theme.breakpoints.up("md"));
  const isSmallDevice = !mqMinMd;

  const chartHeight = match([mqMinMd, mqMinLg])
    .with([P._, true], () => 110)
    .with([true, false], () => 150)
    .otherwise(() => 280);

  const axisLabelOffset = match([mqMinMd, mqMinLg])
    .with([P._, true], () => 15)
    .with([true, false], () => 20)
    .otherwise(() => 30);

  const labelsFontSize = 3 * chartRatio;
  const tooltipWidth = 50 * chartRatio;
  const tooltipHeight = 22 * chartRatio;

  // We are going to disable the animation while on mobile becase
  // of a Victory chart bug that causes the bars to be rendered
  // everytime the chart is panned causing frame drops.
  const defaultAnimation: AnimatePropTypeInterface | undefined = useMemo(
    () =>
      !isSmallDevice
        ? {
            duration: 200,
            easing: "sinInOut",
            animationWhitelist: ["data", "scaleY", "scaleX", "domain"],
            onLoad: {
              duration: 0,
            },
            onEnter: {
              duration: 0,
            },
          }
        : undefined,
    [isSmallDevice],
  );

  const tooltipNode = useMemo(
    () => (
      <GenericTooltipNode<CashflowPoint>
        width={tooltipWidth}
        height={tooltipHeight}
        labelsFontSize={labelsFontSize}
        renderData={({ x, y, cumulative }) => {
          const safeY = y ?? 0;
          const safeCumulative = cumulative ?? 0;
          const computedColor =
            safeY < 0
              ? heritagePalette.statusNegative
              : heritagePalette.statusPositive;

          return {
            title: { value: x?.toString() },
            total: {
              value:
                U_Show.currencyThousandsNoDecimalZeroDash("USD").show(safeY),
              color: computedColor,
            },
            cumulative: {
              value:
                U_Show.currencyThousandsNoDecimalZeroDash("USD").show(
                  safeCumulative,
                ),
              color: computedColor,
            },
          };
        }}
      />
    ),
    [labelsFontSize, tooltipWidth, tooltipHeight, heritagePalette],
  );

  const zoomDomain = useMemo<{ x?: DomainTuple } | undefined>(
    () =>
      // Set the zoom domain to the last 3 quarters
      // only if the device is small.
      cumulativePoints.length > 2 && isSmallDevice
        ? {
            x: [cumulativePoints.length - 2, cumulativePoints.length],
          }
        : undefined,
    [cumulativePoints, isSmallDevice],
  );

  // Since we can't actually disable the initial animation
  // in Victory, we'll have to delay the rendering of the chart.
  useTimeout(
    useCallback(() => {
      setChartLoaded(true);
    }, [setChartLoaded]),
    500,
  );

  return (
    <Box
      sx={{
        opacity: chartLoaded ? 1 : 0,
        transition: "opacity 0.5s ease",
      }}
    >
      <VictoryChart
        domainPadding={10 * chartRatio}
        theme={VictoryTheme.material}
        padding={{
          top: 0,
          bottom: 10 * chartRatio,
          right: 20 * chartRatio * (isSmallDevice ? 0.6 : 1),
          left: 0,
        }}
        height={chartHeight}
        domain={{ y: domain }}
        containerComponent={
          isSmallDevice ? (
            <VictoryZoomContainer
              allowZoom={false}
              allowPan={true}
              zoomDomain={zoomDomain}
              zoomDimension="x"
              downsample={false}
            />
          ) : undefined
        }
      >
        <VictoryAxis
          style={{
            axis: { stroke: "transparent" },
            ticks: { stroke: "transparent" },
            tickLabels: { fontSize: labelsFontSize, padding: 5 },
            grid: { stroke: "transparent" },
          }}
          offsetY={axisLabelOffset}
          animate={defaultAnimation}
        />

        <VictoryAxis
          dependentAxis
          style={{
            tickLabels: { fontSize: labelsFontSize, padding: 0 },
            ticks: { stroke: "transparent" },
            axis: { stroke: "transparent" },
            grid: {
              stroke: theme.palette.heritageV2.neutral300,
              strokeDasharray: "none",
            },
          }}
          orientation="right"
          tickFormat={U_Show.usdThousandsNoDecimalZeroDash.show}
          animate={defaultAnimation}
        />

        <VictoryAxis
          style={{
            axis: {
              stroke: theme.palette.heritageV2.neutral500,
              strokeWidth: 2,
            },
            ticks: { stroke: "transparent" },
            tickLabels: { fill: "transparent" },
            grid: { stroke: "transparent" },
          }}
          animate={defaultAnimation}
        />

        <VictoryBar
          data={drawdownPoints}
          style={{
            data: {
              fill: heritagePalette.statusNegative,
            },
          }}
          barWidth={isSmallDevice ? 40 : undefined}
          animate={defaultAnimation}
          labelComponent={!isSmallDevice ? tooltipNode : undefined}
          groupComponent={<g data-testid={drawdownBarsTestId} />}
        />

        <VictoryBar
          data={distributionPoints}
          style={{
            data: {
              fill: heritagePalette.statusPositive,
            },
          }}
          barWidth={isSmallDevice ? 40 : undefined}
          animate={defaultAnimation}
          labelComponent={!isSmallDevice ? tooltipNode : undefined}
          groupComponent={<g data-testid={distributionBarsTestId} />}
        />

        <VictoryLine
          data={cumulativePoints}
          interpolation="natural"
          style={{
            data: {
              stroke: heritagePalette.chart800,
              strokeWidth: isSmallDevice ? 2 : 0.7,
              pointerEvents: "none",
            },
            parent: {
              pointerEvents: "none",
            },
          }}
          animate={defaultAnimation}
          groupComponent={<g data-testid={cumulativeLineTestId} />}
        />
      </VictoryChart>
    </Box>
  );
};
