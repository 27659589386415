import { useCallback } from "react";
import { getDocumentPath } from "../lib/helpers/media";
import { DocumentLinkStructure } from "../components/core/v2/Table/Table";
import { usePublicInvestorId } from "./usePublicInvestorId";

/**
 * Hook returning a function that generates a link for a document
 * using the underlying investor id.
 */
export const useGenerateDocumentsTableLink = () => {
  const investorId = usePublicInvestorId();

  const generateLinkKind = useCallback<
    (params: {
      canBeDownloaded: boolean;
      id: string;
    }) => DocumentLinkStructure | undefined
  >(
    ({ canBeDownloaded, id }) =>
      investorId
        ? {
            kind: canBeDownloaded ? "download" : "view",
            link: getDocumentPath(
              investorId,
              id,
              canBeDownloaded ? "download" : "view",
            ),
          }
        : undefined,
    [investorId],
  );

  return generateLinkKind;
};
