import React, { ComponentPropsWithoutRef, useCallback } from "react";

import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

import { useSnackbar } from "notistack";

import { datadogRum } from "@datadog/browser-rum";

import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import Grid from "@mui/material/Unstable_Grid2/Grid2";

import { InvestorAccountFormInputSchema } from "../../../generated/validation-schema";

import { ManageInvestorView } from "../ManageInvestorView/ManageInvestorView";

import { CountrySelect } from "../../core/CountrySelect/CountrySelect";

type Props = Pick<
  ComponentPropsWithoutRef<typeof ManageInvestorView>,
  "executeAccountFormMutation" | "investor"
>;

export const InvestorAccountForm: React.FC<Props> = ({
  investor,
  executeAccountFormMutation: executeMutation,
}) => {
  const schema = InvestorAccountFormInputSchema();
  type FormData = z.infer<typeof schema>;

  const {
    control,
    handleSubmit,
    formState: { isDirty, isSubmitting, isValid },
    reset,
  } = useForm<FormData>({
    mode: "all",
    resolver: zodResolver(schema),
    defaultValues: investor,
  });

  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = useCallback<SubmitHandler<FormData>>(
    (input) =>
      new Promise<void>((resolve) => {
        // TODO: handle validation errors
        executeMutation(
          {
            investorId: investor.id,
            input,
          },
          // Invalidate all queries that return a User
          // See https://formidable.com/open-source/urql/docs/basics/document-caching/#adding-typenames
          { additionalTypenames: ["Investor"] },
        )
          .then(() => {
            enqueueSnackbar("Account updated successfully", {
              variant: "success",
            });
            // Reset form with new values and defaults
            reset(input);
            resolve();
          })
          .catch((e) => {
            enqueueSnackbar("Account update failed", {
              variant: "error",
            });
            datadogRum.addError(e);
            resolve();
          });
      }),
    [executeMutation, enqueueSnackbar, reset, investor.id],
  );

  const isBusinessRole = investor.role === "business";

  return (
    <>
      <Typography variant="h4" sx={{ m: 1 }}>
        {isBusinessRole ? "Entity" : "Individual"} Details
      </Typography>
      <Grid
        container
        spacing={2}
        component="form"
        sx={{
          m: 1,
          p: 2,
          boxShadow: "0px 0px 20px rgb(31 30 30 / 10%)",
          borderRadius: "4px",
          "& .MuiTextField-root": { m: 1 },
        }}
        noValidate
      >
        <Grid xs={6}>
          <Controller
            name={"businessName"}
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                error={error !== undefined}
                label={isBusinessRole ? "Business Name" : "Full Name"}
                helperText={error?.message}
                disabled={isSubmitting}
                fullWidth
              />
            )}
          />
        </Grid>

        <Grid xs={6}>
          {isBusinessRole ? (
            <Controller
              name={"vat"}
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  error={error !== undefined}
                  label={"VAT"}
                  helperText={error?.message}
                  disabled={isSubmitting}
                  fullWidth
                />
              )}
            />
          ) : (
            <Controller
              name={"nationality"}
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  error={error !== undefined}
                  label={"Nationality"}
                  helperText={error?.message}
                  disabled={isSubmitting}
                  fullWidth
                />
              )}
            />
          )}
        </Grid>

        <Grid xs={6}>
          <Controller
            name={"phone"}
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                error={error !== undefined}
                label={"Phone Number"}
                helperText={error?.message}
                disabled={isSubmitting}
                fullWidth
              />
            )}
          />
        </Grid>

        <Grid xs={6}>
          <Controller
            name={"address"}
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                error={error !== undefined}
                label={"Address"}
                helperText={error?.message}
                disabled={isSubmitting}
                fullWidth
              />
            )}
          />
        </Grid>

        <Grid xs={6}>
          <Controller
            name={"city"}
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                error={error !== undefined}
                label={"City"}
                helperText={error?.message}
                disabled={isSubmitting}
                fullWidth
              />
            )}
          />
        </Grid>

        <Grid xs={6}>
          <Controller
            name={"country"}
            control={control}
            render={({ field, fieldState: { error } }) => (
              <CountrySelect
                {...field}
                error={error !== undefined}
                label={"Country"}
                helperText={error?.message}
                disabled={isSubmitting}
                fullWidth
              />
            )}
          />
        </Grid>

        <Grid xs={12}>
          <LoadingButton
            variant="outlined"
            onClick={handleSubmit(onSubmit)}
            disabled={!isDirty || !isValid || isSubmitting}
            loading={isSubmitting}
            sx={{ m: 1 }}
          >
            Update
          </LoadingButton>
        </Grid>
      </Grid>
    </>
  );
};
