import React, { useCallback, useMemo } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { Select, SelectOption } from "../../../core/v2/Select/Select";
import { ActiveTableColumnFilter } from "../../../core/v2/Table/Table";
import { PortfolioCompaniesRow } from "./PortfolioCompanies";
import { array as A, function as F, set as SE, string as S } from "fp-ts";

const allFilterValue = "_ALL_";

function generateSelectValues(values: Set<string>): Array<SelectOption> {
  return F.pipe(
    SE.toArray(S.Ord)(values),
    A.map(
      (value) =>
        ({
          kind: "value",
          key: value,
          value,
          label: value,
        }) as SelectOption,
    ),
    A.prepend({
      kind: "value",
      key: "all",
      value: allFilterValue,
      label: "All",
    } as SelectOption),
  );
}

function getFilterValue(
  currentFilters: Array<ActiveTableColumnFilter<PortfolioCompaniesRow>>,
  column: keyof PortfolioCompaniesRow,
): string | undefined {
  const value = currentFilters.find(
    (filter) => filter.column === column,
  )?.value;

  return typeof value !== "undefined" ? value.toString() : undefined;
}

function normalizeSelectValue(value: string): string | undefined {
  return value === allFilterValue ? undefined : value;
}

type Props = {
  currentFilters: Array<ActiveTableColumnFilter<PortfolioCompaniesRow>>;
  industryValues: Set<string>;
  regionValues: Set<string>;
  fundValues: Set<string>;
  onChange: (
    column: keyof PortfolioCompaniesRow,
    value: string | undefined,
  ) => void;
};

/**
 * Filters for the `PortfolioCompanies` component.
 */
export const PortfolioCompaniesFilters: React.FC<Props> = ({
  currentFilters,
  industryValues,
  regionValues,
  fundValues,
  onChange,
}) => {
  const computedIndustryValues = useMemo<Array<SelectOption>>(
    () => generateSelectValues(industryValues),
    [industryValues],
  );

  const computedRegionValues = useMemo<Array<SelectOption>>(
    () => generateSelectValues(regionValues),
    [regionValues],
  );

  const computedFundValues = useMemo<Array<SelectOption>>(
    () => generateSelectValues(fundValues),
    [fundValues],
  );

  const industryValue = useMemo(
    () => getFilterValue(currentFilters, "industry") ?? allFilterValue,
    [currentFilters],
  );

  const regionValue = useMemo(
    () => getFilterValue(currentFilters, "region") ?? allFilterValue,
    [currentFilters],
  );

  const fundValue = useMemo(
    () => getFilterValue(currentFilters, "fund") ?? allFilterValue,
    [currentFilters],
  );

  const handleIndustryChange = useCallback(
    (value: string) => {
      onChange("industry", normalizeSelectValue(value));
    },
    [onChange],
  );

  const handleRegionChange = useCallback(
    (value: string) => {
      onChange("region", normalizeSelectValue(value));
    },
    [onChange],
  );

  const handleFundChange = useCallback(
    (value: string) => {
      onChange("fund", normalizeSelectValue(value));
    },
    [onChange],
  );

  return (
    <Grid container spacing={2}>
      <Grid xs={12} md={4}>
        <Select
          label="Industry"
          options={computedIndustryValues}
          value={industryValue}
          onChange={handleIndustryChange}
        />
      </Grid>

      <Grid xs={12} md={4}>
        <Select
          label="Region"
          options={computedRegionValues}
          value={regionValue}
          onChange={handleRegionChange}
        />
      </Grid>

      <Grid xs={12} md={4}>
        <Select
          label="Fund"
          options={computedFundValues}
          value={fundValue}
          onChange={handleFundChange}
        />
      </Grid>
    </Grid>
  );
};
