import { useMemo } from "react";
import { useDebounce, useWindowSize } from "usehooks-ts";

// Base screen width for chart ratio.
const baseScreenWidth = 1920;

/**
 * Hook to get chart ratio.
 */
export function useChartRatio() {
  const { width } = useWindowSize();

  const ratio = useMemo(() => {
    const computedRatio = baseScreenWidth / width;
    const sanitizedRation = computedRatio === Infinity ? 1 : computedRatio;

    return sanitizedRation;
  }, [width]);

  const debouncedRatio = useDebounce(ratio, 100);

  return debouncedRatio;
}
