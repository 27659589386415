import React from "react";

import { Stack, Typography } from "@mui/material";

import { Box } from "../../../core/v2/Box/Box";

import { NoDataPlaceholder } from "../../../core/v2/NoDataPlaceholder/NoDataPlaceholder";

export const EmptyDistributions: React.FC = () => (
  <Stack flexDirection="column" height="100%">
    <Box flex="0 0 auto">
      <Typography variant="h1">Distributions</Typography>
    </Box>

    <Box mt={2} flex="1 1 100%">
      <NoDataPlaceholder message="No distributions yet" />
    </Box>
  </Stack>
);
