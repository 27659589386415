import React from "react";
import {
  ManageInvestorRouteQuery,
  useSubmitInvestorAccountFormMutation,
} from "../../../generated/urql";
import { InvestorAccountForm } from "../InvestorAccountForm/InvestorAccountForm";

type Props = Readonly<{
  investor: ManageInvestorRouteQuery["investor"];
  executeAccountFormMutation: ReturnType<
    typeof useSubmitInvestorAccountFormMutation
  >[1];
}>;

export const ManageInvestorView: React.FC<Props> = ({
  investor,
  executeAccountFormMutation: executeAccountFormMutation,
}) => {
  return (
    <>
      <InvestorAccountForm
        investor={investor}
        executeAccountFormMutation={executeAccountFormMutation}
      />
    </>
  );
};
