import React from "react";
import { match } from "ts-pattern";
import { Chip } from "../../../core/v2/Chip/Chip";
import { DistributionStatus } from "./data/DistributionStatus";

type Props = {
  status: DistributionStatus;
};

export function generatePaidBadgeText(status: DistributionStatus) {
  return match(status)
    .with("PAID", () => "Paid")
    .with("UNPAID", () => "Not paid")
    .with("PARTIALLY_PAID", () => "Partially paid")
    .exhaustive();
}

export const PaidBadge: React.FC<Props> = ({ status }) => (
  <Chip backgroundColor="accent" textColor="invariantWhite" size="small">
    {generatePaidBadgeText(status)}
  </Chip>
);
