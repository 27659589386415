import React, { useMemo } from "react";
import { SingleDocument } from "../data/SingleDocument";
import {
  DocumentLinkStructure,
  TableColumn,
  tableFallbackValue,
} from "../../../core/v2/Table/Table";
import { useGenerateDocumentsTableLink } from "../../../../hooks/useGenerateDocumensTableLink";
import { formatDateDisplay1 } from "../../../../utils/date";
import { PageTable } from "../../../core/v2/PageTable/PageTable";

type MarketingOverviewRow = {
  id: string;
  title: string;
  createdOn?: Date;
  link?: DocumentLinkStructure;
};

type Props = {
  documents: ReadonlyArray<SingleDocument>;
};

/**
 * List all the generic/platform documents in the Data Room view.
 */
export const HeritageOverviewDocuments: React.FC<Props> = ({ documents }) => {
  const generateLinkKind = useGenerateDocumentsTableLink();
  const columns = useMemo<Array<TableColumn<MarketingOverviewRow>>>(
    () => [
      { headerName: "Title", field: "title", flex: 3, minWidth: 200 },
      {
        headerName: "Created on",
        field: "createdOn",
        minWidth: 150,
        valueFormatter: (value) =>
          value instanceof Date
            ? formatDateDisplay1(value)
            : tableFallbackValue,
      },
    ],
    [],
  );

  const rows = useMemo<Array<MarketingOverviewRow>>(
    () =>
      documents.map((document) => ({
        id: document.id,
        title: document.name,
        createdOn: document.approvedAt
          ? new Date(document.approvedAt)
          : undefined,
        link: generateLinkKind(document),
      })),
    [documents, generateLinkKind],
  );

  return (
    <PageTable
      kind="documents-table"
      title="Heritage Overview"
      columns={columns}
      rows={rows}
      defaultSortingModel={{
        sort: "desc",
        field: "createdOn",
      }}
    />
  );
};
