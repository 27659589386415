import React from "react";
import { useInvestor } from "../../hooks/useInvestor";
import { CashflowSimulator as CashflowSimulatorV2 } from "../../components/cashflowSimulator/v2/CashflowSimulator/CashflowSimulator";

export const CashflowSimulatorRoute: React.FC = () => {
  const investorId = useInvestor()?.id;

  if (investorId === undefined) {
    return null; // TODO: handle error
  }

  return <CashflowSimulatorV2 investorId={investorId} />;
};
