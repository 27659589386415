import { config } from "./config";
import { formatCutoffDate } from "./cutoff";

/**
 * Given an investor ID and a cutoff date, generates a link to download a quarterly report.
 */
export const generateQuarterlyReportLink = (
  investorId: string,
  cutoffDate: Date,
) =>
  `${config.serverBaseUrl}/investor/${investorId}/portfolio-quarter-export/${formatCutoffDate(cutoffDate)}`;
