import MoreVertIcon from "@mui/icons-material/MoreVert";
import React from "react";
import { Menu, MenuItem, Stack, Typography } from "@mui/material";
import {
  Currency,
  isLeafTrail,
  MoneyAddT,
  MoneySubT,
  MoneySumT,
  MoneyT,
  MoneyTimesT,
  NumericT,
  PercentAddT,
  PercentPowT,
  PercentSubT,
  PercentSumT,
  PercentT,
} from "../types";
import { useSnackbar } from "notistack";
import { match } from "ts-pattern";
import FunctionsIcon from "@mui/icons-material/Functions";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { SvgIconProps } from "@mui/material/SvgIcon/SvgIcon";
import { copyToClipboard } from "../../../../utils/clipboard";

type Option = "Amount" | "Formula";
/**
 * an item option is a menu item that allows to perform an action on an audit item
 * if the component is not null, it will be rendered as the icon of the menu item
 * option allows to specify the type of action that will be performed on the audit item
 */
type ItemOption = {
  component: React.ReactNode;
  label: string;
  option: Option;
  currency?: Currency;
};
const iconOptions: Partial<SvgIconProps> = {
  fontSize: "small",
  color: "action",
};
// this default option allows to copy the value of non-money items (percent, numeric, etc.)
const defaultCopyOptions: ItemOption = {
  component: <ContentCopyIcon key={"copyUSD"} {...iconOptions} />,
  label: "Copy value",
  option: "Amount",
};
// these copy options allow to copy the value of money items in different currencies
const copyOptions: ReadonlyArray<ItemOption> = [
  {
    component: <ContentCopyIcon key={"copyUSD"} {...iconOptions} />,
    label: "Copy value $",
    option: "Amount",
    currency: "USD",
  },
  {
    component: <ContentCopyIcon key={"copyEUR"} {...iconOptions} />,
    label: "Copy value €",
    option: "Amount",
    currency: "EUR",
  },
  {
    component: <ContentCopyIcon key={"copyEUR"} {...iconOptions} />,
    label: "Copy value €",
    option: "Amount",
    currency: "GBP",
  },
];
// this option allows to copy the formula (excel compliant format) of an audit item
const formulaOptions: ReadonlyArray<ItemOption> = [
  {
    component: <FunctionsIcon key={"formula"} {...iconOptions} />,
    label: "Copy formula",
    option: "Formula",
  },
];
type UnknownWithTrail = { trail: unknown };
type Props = {
  value: { trail: unknown };
};

const getAmount =
  (currency: Currency | undefined) =>
  (value: unknown): number | undefined =>
    match(value)
      .when(MoneyT.is, ({ amounts }) =>
        currency ? (amounts[currency] ?? 0) : 0,
      )
      .when(PercentT.is, ({ value }) => value / 100)
      .when(NumericT.is, ({ value }) => value)
      .otherwise(() => undefined);

// wrap formula in parentheses if value is composed by multiple items
const wrapFormula = (value: UnknownWithTrail) =>
  match(value.trail)
    .when(
      (v) => PercentSumT.is(v) && v.items.length > 0,
      () => `(${getFormula(value)})`,
    )
    .when(
      (v) => MoneySumT.is(v) && v.items.length > 0,
      () => `(${getFormula(value)})`,
    )
    .otherwise(() => getFormula(value));

const getCurrencies = (value: UnknownWithTrail): ReadonlyArray<Currency> =>
  match(value)
    .when(MoneyT.is, ({ amounts }) => Object.keys(amounts) as Currency[])
    .otherwise(() => []);

const getFormulaLeftRight = (
  left: UnknownWithTrail,
  right: UnknownWithTrail,
  operator: "*" | "+" | "-" | "^",
) => `(${wrapFormula(left)} ${operator} ${wrapFormula(right)})`;

const getFormula = (value: UnknownWithTrail): string => {
  return match(value.trail)
    .when(MoneyT.is, ({ trail }) => getFormula({ trail }))
    .when(PercentSumT.is, ({ items }) =>
      items.length === 0 ? "0" : items.map(getFormula).join(" + "),
    )
    .when(MoneySumT.is, ({ items }) =>
      items.length === 0 ? "0" : items.map(getFormula).join(" + "),
    )
    .when(MoneyTimesT.is, ({ left, right }) =>
      getFormulaLeftRight(left, right, "*"),
    )
    .when(MoneySubT.is, ({ left, right }) =>
      getFormulaLeftRight(left, right, "-"),
    )
    .when(PercentSubT.is, ({ left, right }) =>
      getFormulaLeftRight(left, right, "-"),
    )
    .when(MoneyAddT.is, ({ left, right }) =>
      getFormulaLeftRight(left, right, "+"),
    )
    .when(PercentAddT.is, ({ left, right }) =>
      getFormulaLeftRight(left, right, "+"),
    )
    .when(PercentPowT.is, ({ left, right }) =>
      getFormulaLeftRight(left, right, "^"),
    )
    .otherwise(() => {
      return (
        // TODO support multiple currencies for formula
        getAmount("USD")(value)?.toString().replace(".", ",") ?? "ERR"
      );
    });
};

/**
 * OptionMenu is a menu that allows to perform actions on an audit item
 * it shows a list of options beside the item
 * @param value
 * @constructor
 */
export const OptionMenu: React.FC<Props> = ({ value }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | SVGSVGElement>(null);
  const { enqueueSnackbar } = useSnackbar();
  const handleClick = (event: React.MouseEvent<SVGSVGElement>) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };
  const handleClose = (event: React.MouseEvent) => {
    setAnchorEl(null);
    event.stopPropagation();
  };

  const handleMenuItemClick = async (
    event: React.MouseEvent,
    option: Option,
    currency?: Currency,
  ) => {
    handleClose(event);
    const result: boolean = await match(option)
      .with("Amount", async () => {
        const amount = getAmount(currency)(value)?.toString();
        if (amount === undefined) {
          return false;
        }
        return await copyToClipboard(amount);
      })
      .with("Formula", async () => await copyToClipboard(getFormula(value)))
      .exhaustive();
    if (result) {
      enqueueSnackbar("Copied to clipboard", {
        variant: "info",
      });
    } else {
      enqueueSnackbar("Copy to clipboard failed!", {
        variant: "error",
      });
    }
  };

  const isLeaf = isLeafTrail(value.trail);
  // const copyOptionsCurrency
  const currencies = getCurrencies(value);
  // if the current value has multiple currencies, we can copy the value for each currency
  // if it has no currency we provide a default copy option
  const copies =
    currencies.length > 0
      ? copyOptions.filter(
          (co) => co.currency !== undefined && currencies.includes(co.currency),
        )
      : [defaultCopyOptions];
  const options = [...copies, ...(isLeaf ? [] : formulaOptions)];
  return (
    <>
      <MoreVertIcon sx={{ fontSize: 18 }} onClick={handleClick} />
      <Menu anchorEl={anchorEl} open={anchorEl !== null} onClose={handleClose}>
        {options.map((optionItem) => (
          <MenuItem
            key={optionItem.label as string}
            onClick={(e) =>
              handleMenuItemClick(e, optionItem.option, optionItem.currency)
            }
          >
            <Stack direction={"row"} alignItems={"center"} spacing={0.5}>
              {optionItem.component}
              <Typography>{optionItem.label}</Typography>
            </Stack>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
