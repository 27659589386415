import { atom } from "recoil";

/**
 * Application state that determines whether the user has accepted
 * the disclaimer for the cashflow simulator.
 */
export const cashflowSimulatorDisclaimerAccepted = atom({
  key: "cashflowSimulatorDisclaimerAccepted",
  default: false,
});
