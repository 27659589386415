import { Typography } from "@mui/material";
import React from "react";
import { useHeritageV2Palette } from "../../../../utils/hooks/useHeritageV2Palette";
import { heritageV2Palette } from "../../../../mui/heritageV2Palette";

type Props = {
  children: string;
  color: keyof typeof heritageV2Palette;
};

/**
 * An H3 title with an underline below.
 */
export const UnderlineH3: React.FC<Props> = ({ children, color }) => {
  const palette = useHeritageV2Palette();

  return (
    <Typography
      display="inline-block"
      variant="h3"
      color={palette[color]}
      sx={{
        borderBottom: `3px solid ${palette[color]}`,
      }}
    >
      {children}
    </Typography>
  );
};
