import React from "react";
import { Card, Stack, Typography } from "@mui/material";
import { Button } from "../../../core/v2/Button/Button";
import { registerPasskey } from "../../../../lib/passkey";
import { useNotifications } from "../../../../utils/notifications";
import { useLayoutContext } from "../../../../routes/LayoutRoute/LayoutRoute";
import { PasskeysTable } from "./PasskeysTable";
import { useLayoutRouteQuery } from "../../../../generated/urql";
import PasskeySvg from "@heritageholdings/lib-commons-frontend/assets/svg/passkey/passkey.dynamic.svg?react";
import { DesignTokens } from "@heritageholdings/lib-commons-frontend";
import { browserSupportsWebAuthn } from "@simplewebauthn/browser";
import { NotificationError } from "../../../core/v2/Icon/Icon";
import { Box } from "../../../core/v2/Box/Box";
import { tracker } from "../../../../utils/tracker";

const CreatePasskeyBanner = () => {
  const { successNotification, errorNotification } = useNotifications();
  const [_, refresh] = useLayoutRouteQuery();
  return (
    <Card>
      <Stack
        direction="row"
        alignItems="center"
        p={2}
        gap={2}
        flexWrap={{ sm: "wrap", md: "nowrap" }}
      >
        <Box flexShrink={0} flexGrow={0} flexBasis="auto">
          <PasskeySvg
            fill={DesignTokens.darkColors.neutral700}
            width={DesignTokens.sizes.iconMedium}
            height={DesignTokens.sizes.iconMedium}
          />
        </Box>

        <Box flexShrink={1} flexGrow={1} flexBasis="100%">
          <Typography variant={"h4"}>
            Create a passkey on this device
          </Typography>

          <Typography>
            Speed up your sign-in next time by creating a new passkey on this
            device.
          </Typography>
        </Box>

        <Box flexShrink={0} flexGrow={0} flexBasis="auto">
          <Button
            variant="primary"
            onClick={async () => {
              tracker.trackEvent({
                name: "PasskeyRegistrationStart",
              });
              const res = await registerPasskey()();

              if (res._tag === "Left") {
                if (
                  res.left.kind !== "excludedCredentialExists" &&
                  res.left.kind !== "userCancelled"
                ) {
                  tracker.trackEvent({
                    name: "PasskeyRegistrationFailure",
                    payload: {
                      error: res.left.kind,
                    },
                  });
                  errorNotification("Failed to create passkey");
                }

                return;
              }
              refresh();
              tracker.trackEvent({
                name: "PasskeyRegistrationCompleted",
              });
              successNotification("Passkey created");
            }}
          >
            Create a passkey
          </Button>
        </Box>
      </Stack>
    </Card>
  );
};

const BrowserNotSupportedBanner = () => {
  return (
    <Card>
      <Box flexDirection={"row"} display={"flex"} p={1} alignItems={"center"}>
        <NotificationError variant={"medium"} />

        <Box ml={1}>
          <Typography variant={"h4"}>
            Your browser does not support passkeys
          </Typography>

          <Typography>
            Please switch to a supported browser to generate a new passkey.
          </Typography>
        </Box>
      </Box>
    </Card>
  );
};

/**
 * The Passkey section in the security tab
 * @constructor
 */
const PasskeySection = () => {
  const { authUser } = useLayoutContext();

  return (
    <>
      <Box
        flexDirection={"row"}
        display={"flex"}
        justifyContent={"space-between"}
        flexGrow={1}
      >
        <Typography variant="h1">Passkeys</Typography>
      </Box>

      <Typography>
        Passkeys enable you to securely sign in to your Heritage account using
        your fingerprint, face or screen lock without using email and password.
        Only set up passkeys on devices that you own.
      </Typography>

      <Box>
        {browserSupportsWebAuthn() ? (
          <CreatePasskeyBanner />
        ) : (
          <BrowserNotSupportedBanner />
        )}
      </Box>

      <Box width={"100%"} mt={3}>
        <PasskeysTable authenticators={authUser?.authenticators ?? []} />
      </Box>
    </>
  );
};

/**
 * The security tab in the user account page
 * @constructor
 */
export const SecurityComponent: React.FC = () => {
  return (
    <Stack
      direction="column"
      justifyContent="space-between"
      alignItems="flex-start"
      gap={2}
      display="flex"
    >
      <PasskeySection />
    </Stack>
  );
};
