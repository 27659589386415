import React from "react";
import { Link as MuiLink } from "@mui/material";
import { PropsWithChildren } from "react";
import { useHeritageV2Palette } from "../../../../utils/hooks/useHeritageV2Palette";
import { Link } from "react-router-dom";

type Props = PropsWithChildren<{
  href: string;
}>;

/**
 * A `Table` cell that contains an internal link.
 */
export const CellInternalLink: React.FC<Props> = ({ href, children }) => {
  const palette = useHeritageV2Palette();

  return (
    <MuiLink
      component={Link}
      to={href}
      color={palette.accent}
      underline="none"
      display="flex"
      width="100%"
      height="100%"
      alignItems="center"
    >
      {children}
    </MuiLink>
  );
};
