import { z } from "zod";

/**
 * Runtime type validation for a `Currency` data structure.
 */
const moneyCurrencySchema = z.union([z.literal("EUR"), z.literal("USD")]);

/**
 * Runtime type validation for a `SimpleMoney` data structure.
 */
const simpleMoneySchema = z.object({
  amount: z.number(),
  currency: moneyCurrencySchema,
});

/**
 * The compile-time type for a `SimpleMoney` data structure.
 */
export type SimpleMoney = z.TypeOf<typeof simpleMoneySchema>;

/**
 * The compile-time type for a `MoneyCurrency` data structure.
 */
type MoneyCurrency = z.TypeOf<typeof moneyCurrencySchema>;

/**
 * Parse an `unknown` value into a `SimpleMoney` data structure.
 */
export const parseSimpleMoney = (data: unknown): SimpleMoney | undefined => {
  const parsed = simpleMoneySchema.safeParse(data);

  if (!parsed.success) return undefined;

  return parsed.data;
};

/**
 * Parse an `unknown` value into a `MoneyCurrency` data structure.
 * If the value is not a valid `MoneyCurrency`, the default value
 * of `USD` will be returned.
 */
export const parseMoneyCurrency = (data: unknown): MoneyCurrency => {
  const parsed = moneyCurrencySchema.safeParse(data);

  if (!parsed.success) return "USD";

  return parsed.data;
};
