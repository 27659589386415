import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Money,
  zero,
} from "@heritageholdings/lib-commons-finance/lib/units/money";
import { Drawer, Paper, Stack, Typography } from "@mui/material";
import { CashflowJCurve } from "../../data/CashflowJCurve";
import { CashflowComputedAsset } from "../../data/CashflowComputedAsset";
import {
  CashflowAssetJCurve,
  findLatestJCurveUsableYear,
  generateForecastJCurveForAsset,
  sumCashflowAssetJCurve,
} from "../../data/CashflowAssetJCurve";
import { CashflowAsset, assetToCashflowAsset } from "../../data/CashflowAsset";
import {
  CashflowStats,
  generateJCurveStatsForAsset,
  sumJCurveStatsForAsset,
} from "../../data/CashflowStats";
import { Numeric } from "@heritageholdings/lib-commons-finance/lib/units/numeric";
import { CashflowSimulatorStats } from "./CashflowSimulatorStats";
import { CashflowSimulatorChart } from "../CashflowSimulatorChart/CashflowSimulatorChart";
import { Slider } from "../../../core/v2/Slider/Slider";
import { useHeritageV2Palette } from "../../../../utils/hooks/useHeritageV2Palette";
import { Button } from "../../../core/v2/Button/Button";
import { CashflowSimulatorPortfolio } from "../CashflowSimulatorPortfolio/CashflowSimulatorPortfolio";
import { pipe } from "fp-ts/lib/function";
import * as RA from "fp-ts/ReadonlyArray";
import * as O from "fp-ts/Option";
import * as E from "fp-ts/Either";
import { CashflowSimulatorTable } from "../CashflowSimulatorTable/CashflowSimulatorTable";
import { NoDataPlaceholder } from "../../../core/v2/NoDataPlaceholder/NoDataPlaceholder";
import { IterableElement } from "type-fest";
import { UnderlineH3 } from "../../../core/v2/UnderlineH3/UnderlineH3";
import { Box } from "../../../core/v2/Box/Box";
import { useCashflowSimulatorQueryProxy } from "../../../../hooks/useCashflowSimulatorQueryProxy";
import { CashflowSimulatorDisclaimer } from "./CashflowSimulatorDisclaimer";
import { Warning } from "../../../core/v2/Icon/Icon";
import { LocalDateFromISOString } from "@heritageholdings/lib-commons-finance/lib/time/LocalDateFromISOString";
import { isDefined } from "../../../../lib/utils/types";

/**
 * Aggregate a collection of JCurves into a single JCurve.
 */
export const aggregateJCurves = (
  jcurves: Array<CashflowJCurve>,
): CashflowJCurve => {
  const resultAggregateJCurve: CashflowJCurve = {
    drawdown: {},
    distribution: {},
    cumulative: {},
  };

  for (const { drawdown, distribution, cumulative } of jcurves) {
    for (const [year, drawdownValue] of Object.entries(drawdown)) {
      resultAggregateJCurve.drawdown[year] = drawdownValue?.plus(
        resultAggregateJCurve.drawdown[year] ?? zero,
      );
    }

    for (const [year, distributionValue] of Object.entries(distribution)) {
      resultAggregateJCurve.distribution[year] = distributionValue?.plus(
        resultAggregateJCurve.distribution[year] ?? zero,
      );
    }

    for (const [year, cumulativeValue] of Object.entries(cumulative)) {
      resultAggregateJCurve.cumulative[year] = cumulativeValue?.plus(
        resultAggregateJCurve.cumulative[year] ?? zero,
      );
    }
  }

  return resultAggregateJCurve;
};

/**
 * Aggregate a collection of stats into a single stats.
 */
const aggregateStats = (stats: Array<CashflowStats>): CashflowStats =>
  stats.reduce<CashflowStats>(
    (acc, next) => ({
      committedCapital: acc.committedCapital.plus(next.committedCapital),
      expectedProfit: acc.expectedProfit.plus(next.expectedProfit),
      expectedProfitLastYear: Math.max(
        acc.expectedProfitLastYear,
        next.expectedProfitLastYear,
      ),
      paidIn: acc.paidIn.plus(next.paidIn),
    }),
    {
      committedCapital: zero,
      expectedProfit: zero,
      expectedProfitLastYear: 0,
      paidIn: zero,
    },
  );

type Props = {
  investorId: string;
};

/**
 * The new Cashflow Simulator with simplified calculation on
 * the jCurve.
 */
export const CashflowSimulator: React.FC<Props> = ({ investorId }) => {
  // Here we are using an `undefined` value to indicate that the
  // assets are still being loaded. This is used to avoid displaying
  // the "No data" placeholder while the assets are being loaded.
  const [selectedAssets, setSelectedAssets] = useState<
    Array<CashflowComputedAsset> | undefined
  >(undefined);

  const [showPortfolio, setShowPortfolio] = useState(false);

  const safeSelectedAssets = useMemo(
    () => selectedAssets ?? [],
    [selectedAssets],
  );

  const palette = useHeritageV2Palette();

  const [{ data }] = useCashflowSimulatorQueryProxy(investorId);

  // Integrate more allocation details creating a record
  // that maps each allocation ID to the relative informations
  // retrieve from the portfolio situation.
  const allocationDetailsRecord = useMemo(() => {
    const allocations = data?.investor.portfolioSituation[0]?.byAllocation;

    if (!allocations) return {};

    return allocations.reduce<
      Record<string, IterableElement<typeof allocations>>
    >(
      (acc, next) => ({
        ...acc,
        [next.allocation.id]: next,
      }),
      {},
    );
  }, [data]);

  const [moic, setMoic] = useState(0);

  const lastAggregatedYear = useMemo(() => {
    return safeSelectedAssets.reduce(
      (acc, next) =>
        Math.max(acc, findLatestJCurveUsableYear(next.jcurveYears) ?? 0),
      0,
    );
  }, [safeSelectedAssets]);

  const [forecastedJCurves, aggregatedJCurve, simulatedAssetsSet, stats] =
    useMemo<
      [Array<CashflowAssetJCurve>, CashflowJCurve, Set<string>, CashflowStats]
    >(
      () =>
        computeCashflowSimulatorUtils(
          safeSelectedAssets,
          lastAggregatedYear,
          moic,
        ),
      [safeSelectedAssets, lastAggregatedYear, moic],
    );

  // Pre-populate the `SelectedAssets` state with all the assets
  // in which the investor has an allocation.
  useEffect(() => {
    if (data?.investor) {
      const assets: Array<CashflowComputedAsset> =
        data.investor.enabledAllocations
          .map(({ id, amountUsd, allocationDate, asset }) => {
            const decodedAllocationDate = pipe(
              LocalDateFromISOString.decode(allocationDate),
              E.getOrElseW(() => undefined),
            );
            if (decodedAllocationDate === undefined) {
              return undefined;
            }

            return {
              ...assetToCashflowAsset(asset),
              allocationAmount: new Money({ USD: amountUsd }),
              allocationDate: decodedAllocationDate,
              showInJCurve: true,
              paidIn: new Money({
                USD: allocationDetailsRecord[id]?.paidInUsd ?? 0,
              }),
              kind: "real" as const,
            };
          })
          .filter(isDefined);

      setSelectedAssets(assets);
    }
  }, [data, allocationDetailsRecord]);

  const { totalAssets, activeAssets } = useMemo(
    () =>
      safeSelectedAssets.reduce(
        (acc, next) => ({
          totalAssets: acc.totalAssets + 1,
          activeAssets: acc.activeAssets + (next.showInJCurve ? 1 : 0),
        }),
        {
          totalAssets: 0,
          activeAssets: 0,
        },
      ),
    [safeSelectedAssets],
  );

  const handleMoicSliderChange = useCallback(
    (_: unknown, value: number | Array<number>) => {
      const sanitizedValue = Array.isArray(value) ? value[0] : value;
      setMoic(sanitizedValue);
    },
    [setMoic],
  );

  const moicSliderMarks = useMemo(
    () => [
      { value: -1, label: "Downside" },
      { value: 0, label: "Base" },
      { value: 1, label: "Upside" },
    ],
    [],
  );

  // The list of non-selected assets that can be simulated
  // in the chart. These can be added to the simulation
  // by clicking on the "Add simulated asset" button in the
  // right sidebar.
  const assetsToSimulate = useMemo<Array<CashflowAsset>>(
    () =>
      pipe(
        data?.investor.openAssets ?? [],
        RA.filterMap((asset) =>
          simulatedAssetsSet.has(asset.id)
            ? O.none
            : O.some(assetToCashflowAsset(asset)),
        ),
        RA.toArray,
      ),
    [data, simulatedAssetsSet],
  );

  const showPlaceholder = selectedAssets !== undefined && activeAssets <= 0;

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        flexWrap="wrap"
        gap={2}
      >
        <Box>
          <Box>
            <Typography variant="h1">Cash Flow</Typography>
          </Box>
        </Box>

        <Box flexBasis={{ sm: "100%", md: "auto" }}>
          <Button variant="primary" onClick={() => setShowPortfolio(true)}>
            Edit simulated portfolio
          </Button>

          <Drawer
            anchor="right"
            open={showPortfolio}
            onClose={() => setShowPortfolio(false)}
            sx={{ zIndex: 1300 }}
          >
            <Box width={{ md: 400 }} p={1}>
              <CashflowSimulatorPortfolio
                jcurveAssets={safeSelectedAssets}
                assetsToSimulate={assetsToSimulate}
                onChange={setSelectedAssets}
                onClose={() => setShowPortfolio(false)}
              />
            </Box>
          </Drawer>
        </Box>
      </Stack>

      <Box mt={2} bgcolor={palette.accentBg200} p={1} borderRadius={1}>
        <Stack
          direction="row"
          alignItems="center"
          gap={0}
          flexWrap={{ sm: "wrap", md: "nowrap" }}
        >
          <Box
            flexGrow={0}
            flexShrink={0}
            flexBasis={{ sm: "100%", md: "auto" }}
            color={palette.accent}
            textAlign="center"
          >
            <Warning variant="medium" />
          </Box>

          <Typography variant="body2" maxWidth={1000} color={palette.accent}>
            The figures presented are estimations based on simulated scenarios
            and do not incorporate actual foreign exchange (forex) rates. As a
            result, these numbers may differ from the actual performance metrics
            found in the &apos;Portfolio&apos; section. All values are
            hypothetical and subject to change.
          </Typography>
        </Stack>
      </Box>

      <Box mt={2}>
        <CashflowSimulatorStats
          stats={stats}
          totalAssets={totalAssets}
          activeAssets={activeAssets}
        />
      </Box>

      <Box mt={2}>
        <Paper>
          <Box p={2}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h3">
                Expected Profit and{" "}
                <UnderlineH3 color="statusNegative">J-Curve</UnderlineH3> over
                time
              </Typography>

              <Typography variant="formLabel">
                Everything in <strong>&apos;000 $</strong>
              </Typography>
            </Stack>

            <Box mt={2} maxWidth={350}>
              <Typography variant="h6">Scenario</Typography>

              <Slider
                defaultValue={moic}
                valueLabelDisplay="auto"
                step={0.1}
                min={-1}
                max={1}
                marks={moicSliderMarks}
                onChangeCommitted={handleMoicSliderChange}
              />
            </Box>

            <Box mt={2} position="relative">
              <CashflowSimulatorChart forecastedJCurve={aggregatedJCurve} />

              <Box
                position="absolute"
                top={0}
                left={0}
                width="100%"
                height="100%"
                bgcolor={palette.invariantWhite}
                zIndex={showPlaceholder ? 1 : -1}
                sx={{
                  pointerEvents: showPlaceholder ? "all" : "none",
                  opacity: showPlaceholder ? 1 : 0,
                  transition: "opacity 0.3s ease",
                }}
              >
                <NoDataPlaceholder message="You must select at least one asset" />
              </Box>
            </Box>
          </Box>
        </Paper>
      </Box>

      <Box mt={3}>
        <Typography variant="h2">Details</Typography>

        <Box mt={0}>
          <Typography variant="formLabel">
            Everything in <strong>&apos;000 $</strong>
          </Typography>
        </Box>

        <Box mt={1}>
          <Paper>
            <Box p={2}>
              <CashflowSimulatorTable
                assetsJCurves={forecastedJCurves}
                aggregatedJCurve={aggregatedJCurve}
              />
            </Box>
          </Paper>
        </Box>
      </Box>

      <CashflowSimulatorDisclaimer />
    </>
  );
};

/**
 * Generate a set of utiles used by the cashflow simulator
 * component in order to render its chart and data.
 */
function computeCashflowSimulatorUtils(
  selectedAssets: Array<CashflowComputedAsset>,
  lastAggregatedYear: number,
  moic: number,
): [Array<CashflowAssetJCurve>, CashflowJCurve, Set<string>, CashflowStats] {
  const forecastedJCurvesMap = new Map<string, CashflowAssetJCurve>();
  const simulatedAssetsSet = new Set<string>();
  const statsMap = new Map<string, CashflowStats>();

  for (const asset of selectedAssets) {
    if (!asset.showInJCurve) continue;

    const moicScenario = new Numeric(moic);

    const currentAssetJCurve = forecastedJCurvesMap.get(asset.id);
    const currentAssetStats = statsMap.get(asset.id);

    const assetJCurve = generateForecastJCurveForAsset(
      asset,
      lastAggregatedYear,
      moicScenario,
    );

    const assetStats = generateJCurveStatsForAsset(
      asset,
      lastAggregatedYear,
      moicScenario,
    );

    const computedAssetJCurve = currentAssetJCurve
      ? sumCashflowAssetJCurve(currentAssetJCurve, assetJCurve)
      : assetJCurve;

    const computedAssetStats = currentAssetStats
      ? sumJCurveStatsForAsset(currentAssetStats, assetStats)
      : assetStats;

    forecastedJCurvesMap.set(asset.id, computedAssetJCurve);
    statsMap.set(asset.id, computedAssetStats);

    simulatedAssetsSet.add(asset.id);
  }

  // FIXME: This could be a bottleneck if the number of assets
  // in the simulation is high (it's probably a O(2N) operation)
  // on top of the others.
  const forecastedJCurves = Array.from(forecastedJCurvesMap.values());
  const stats = Array.from(statsMap.values());

  const aggregatedForecastedJCurve = aggregateJCurves(forecastedJCurves);
  const aggregatedStats = aggregateStats(stats);

  return [
    // The JCurve of each asset.
    forecastedJCurves,

    // The JCurve data to be displayed.
    aggregatedForecastedJCurve,

    // A set containing the IDs of the assets already in the
    // simulation. If the investor has an allocation in that asset
    // or if the asset has been added manually to the simulation it
    // will be in this set. This is used in the "Add simulated asset"
    // form to filter out the assets that are already in the simulation.
    simulatedAssetsSet,

    // Provide aggregated stats for the simulation.
    aggregatedStats,
  ];
}
