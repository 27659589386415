import { Numeric } from "@heritageholdings/lib-commons-finance/lib/units/numeric";
import { CashflowComputedAsset } from "./CashflowComputedAsset";
import { CashflowJCurve } from "./CashflowJCurve";
import { Percent } from "@heritageholdings/lib-commons-finance/lib/units/percent";
import {
  Money,
  zero,
} from "@heritageholdings/lib-commons-finance/lib/units/money";
import { CashflowAsset } from "./CashflowAsset";

const numericNegOne = new Numeric(-1);
const numericZero = new Numeric(0);

/**
 * A JCurve data structure specific for a single asset.
 * This is just the normal JCurve data structure with
 * more metadata about the asset.
 */
export type CashflowAssetJCurve = CashflowJCurve & {
  assetId: string;
  assetName: string;
  latestYear?: number;
};

/**
 * Interpolate the MOIC Scenario value given the MOIC Base, MOIC Low and MOIC High.
 */
export function interpolateMoic(
  moicScenario: Numeric,
  moic: { moicLow: Numeric; moicBase: Numeric; moicHigh: Numeric },
): Numeric {
  return moicScenario.lt(numericZero)
    ? // base + value * (base - low)
      moic.moicBase.plus(moicScenario.times(moic.moicBase.minus(moic.moicLow)))
    : // base + value * (high - base)
      moic.moicBase.plus(
        moicScenario.times(moic.moicHigh.minus(moic.moicBase)),
      );
}

/**
 * Given a list `JCurveYear`, this function will return the latest year
 * that identify the asset lifetime. This is required because we are going
 * to have a right padding with a number of zeroes that we don't actually
 * want to show in the chart or on any of the stats.
 */
export function findLatestJCurveUsableYear(
  years: ReadonlyArray<CashflowAsset["jcurveYears"][0]>,
): number | undefined {
  if (years.length === 0) return undefined;

  for (let i = years.length - 1; i >= 0; i--) {
    if (years[i].distributionPercent.toPercent() > 0) return years[i].year;
  }

  return undefined;
}

/**
 * Generate the forecasted drawdown and distribution values for a given asset.
 * The data structure in input should describe the investor's allocation toward
 * an assets, and the underlying asset information with the jcurve years.
 */
export function generateForecastJCurveForAsset(
  jcurveAsset: CashflowComputedAsset,
  latestYear: number,
  moicScenario: Numeric,
): CashflowAssetJCurve {
  const moic = interpolateMoic(moicScenario, jcurveAsset).withLabel(
    `${jcurveAsset.name} MOIC`,
  );

  const allocationAmount = jcurveAsset.allocationAmount.withLabel(
    `${jcurveAsset.name} Allocation Amount`,
  );

  const expectedReturn = allocationAmount
    .times(moic)
    .withLabel(`${jcurveAsset.name} Expected Return`);

  const startDate = jcurveAsset.allocationDate;
  const startDateYear = startDate.year;

  // This is the cumulative value of the asset.
  let cumulatedAcc: Money = zero;

  // Return the latest year of the asset.
  const latestUsableYear = findLatestJCurveUsableYear(jcurveAsset.jcurveYears);

  const resultForecastedJCurve: CashflowAssetJCurve = {
    assetId: jcurveAsset.id,
    assetName: jcurveAsset.name,
    drawdown: {},
    distribution: {},
    cumulative: {},
    latestYear: latestUsableYear,
  };

  // Since the forecasted years could start _before_ the investor's
  // allocation date, we are going to sum all the forecasted percentages
  // before the actual allocation date.
  let firstYearDrawdownAcc = new Percent(0);
  let firstYearDistributionAcc = new Percent(0);

  // We need the JCurve years to be sorted from the oldest
  // to the newest.
  // This is a possible bottleneck of O(n) complexity.
  const sortedJcurveYears = jcurveAsset.jcurveYears.sort(
    (a, b) => a.year - b.year,
  );

  for (const {
    drawdownPercent,
    distributionPercent,
    year,
  } of sortedJcurveYears) {
    // If we have a latest usable year and we are past it, we break the loop.
    if (latestUsableYear !== undefined && year > latestUsableYear) break;

    // If this year is before the investor's allocation date, we
    // accumulate the percentages.
    if (year < startDateYear) {
      // Accumulate the drawdown percentage.
      firstYearDrawdownAcc = firstYearDrawdownAcc.plus(drawdownPercent);

      // Accumulate the distribution percentage.
      firstYearDistributionAcc =
        firstYearDistributionAcc.plus(distributionPercent);

      continue;
    }

    // If this is the first year we sum all the accumulated forecasted percentages.
    const computedDrawdownPercent = (
      year === startDateYear
        ? drawdownPercent.plus(firstYearDrawdownAcc)
        : drawdownPercent
    )
      // Multiply this percentage by -1 to get the drawdown percentage.
      .times(numericNegOne)
      .withLabel(`${jcurveAsset.name} Drawdown Percent`);

    // If this is the first year we sum all the accumulated forecasted percentages.
    const computedDistributionPercent = (
      year === startDateYear
        ? distributionPercent.plus(firstYearDistributionAcc)
        : distributionPercent
    ).withLabel(`${jcurveAsset.name} Distribution Percent`);

    const drawdownValue = allocationAmount
      .timesPercent(computedDrawdownPercent)
      .withLabel(`${jcurveAsset.name} Drawdown Value`);

    const distributionValue = expectedReturn
      .timesPercent(computedDistributionPercent)
      .withLabel(`${jcurveAsset.name} Distribution Value`);

    resultForecastedJCurve.drawdown[year] = drawdownValue;
    resultForecastedJCurve.distribution[year] = distributionValue;

    // Calculate the cumulative value.
    cumulatedAcc = cumulatedAcc.plus(drawdownValue).plus(distributionValue);
    resultForecastedJCurve.cumulative[year] = cumulatedAcc;
  }

  // This will fix the cumulated curve for assets that have a lifetime
  // shorter than the max lifetime of the portfolio.
  // We are going to use the last cumulated value and we are going to
  // pad the curve with that value.
  if (latestUsableYear !== undefined && latestYear > latestUsableYear) {
    const lastCumulativeValue =
      resultForecastedJCurve.cumulative[latestUsableYear];

    for (let i = latestUsableYear + 1; i <= latestYear; i++)
      resultForecastedJCurve.cumulative[i] = lastCumulativeValue;
  }

  return resultForecastedJCurve;
}

/**
 * This function sums two `CashflowAssetJCurve` together.
 * The result is a new `CashflowAssetJCurve` with the drowdown and
 * distribution values summed together (the first year will be the lowest
 * year between the two, and the last year will be the highest year between
 * the two).
 */
export function sumCashflowAssetJCurve(
  a: CashflowAssetJCurve,
  b: CashflowAssetJCurve,
): CashflowAssetJCurve {
  const result: CashflowAssetJCurve = {
    assetId: a.assetId,
    assetName: a.assetName,
    drawdown: {},
    distribution: {},
    cumulative: {},
  };

  const aYears = Object.keys(a.drawdown).map(Number);
  const bYears = Object.keys(b.drawdown).map(Number);

  const minYear = Math.min(...aYears, ...bYears);
  const maxYear = Math.max(...aYears, ...bYears);

  for (let i = minYear; i <= maxYear; i++) {
    result.drawdown[i] = a.drawdown[i]?.plus(b.drawdown[i] ?? zero);
    result.distribution[i] = a.distribution[i]?.plus(b.distribution[i] ?? zero);
    result.cumulative[i] = a.cumulative[i]?.plus(b.cumulative[i] ?? zero);
  }

  return result;
}
