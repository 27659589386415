import React, { useMemo, useState } from "react";
import { Stack, Typography } from "@mui/material";
import { Card } from "../../../core/v2/Card/Card";
import { SingleAsset } from "../data/SingleAsset";
import { getPublicMediaPath } from "../../../../lib/helpers/media";
import { useHeritageV2Palette } from "../../../../utils/hooks/useHeritageV2Palette";
import { OpaqueChip } from "../../../core/v2/Chip/Chip";
import { match } from "ts-pattern";
import { Button } from "../../../core/v2/Button/Button";
import * as U_Show from "@heritageholdings/lib-commons-finance/lib/show";
import {
  readonlyArray as RA,
  option as O,
  function as F,
  number as N,
} from "fp-ts";
import { isDefined } from "../../../../lib/utils/types";
import { RequestAllocationDialog } from "../RequestAllocationDialog/RequestAllocationDialog";
import { PendingAllocation } from "../data/PendingAllocation";
import { Box } from "../../../core/v2/Box/Box";
import { heritageV2Palette } from "../../../../mui/heritageV2Palette";
import { contramap, reverse } from "fp-ts/lib/Ord";
import { DiversificationReportCategory } from "../../../../generated/urql";

const sortByAmount = F.pipe(
  reverse(N.Ord),
  contramap((d: DiversificationReportCategory) => d.amount),
);

type Props = {
  asset: SingleAsset;
  onDetailsClick?: () => void;
  pendingAllocation?: PendingAllocation;
};

/**
 * This component shows a card with the asset information.
 */
export const AssetCard: React.FC<Props> = ({
  asset,
  onDetailsClick,
  pendingAllocation,
}) => {
  const [allocationDialogOpen, setAllocationDialogOpen] = useState(false);
  const palette = useHeritageV2Palette();

  const imageUrl = useMemo(() => {
    const firstMedia = asset.media[0];

    const coverImageUrl =
      firstMedia !== undefined
        ? getPublicMediaPath(firstMedia.publicMediaId, firstMedia.fileName)
        : undefined;

    return coverImageUrl;
  }, [asset]);

  const maybeStrategy = useMemo(
    () =>
      F.pipe(
        asset.diversification.strategies,
        RA.sortBy([sortByAmount]),
        RA.head,
        O.toUndefined,
      ),
    [asset],
  );

  const maybeGeography = useMemo(
    () =>
      F.pipe(
        asset.diversification.geographies,
        RA.sortBy([sortByAmount]),
        RA.head,
        O.toUndefined,
      ),
    [asset],
  );

  const backgroundColor = match<unknown, keyof typeof heritageV2Palette>(
    maybeStrategy?.name,
  )
    .with("Buyout", () => "chart200")
    .with("Growth", () => "chart400")
    .with("Venture Capital", () => "chart600")
    .otherwise(() => "neutral700");

  const mom = useMemo(
    () =>
      isDefined(asset.targetGrossMom)
        ? U_Show.multiple2WithoutSuffix.show(asset.targetGrossMom)
        : undefined,
    [asset],
  );

  const irr = useMemo(
    () =>
      isDefined(asset.targetGrossIrr)
        ? asset.targetGrossIrr.toString()
        : undefined,
    [asset],
  );

  return (
    <>
      <Card backgroundColor={backgroundColor}>
        <Stack
          height="100%"
          direction="column"
          justifyContent="space-between"
          gap={2}
        >
          <Box>
            <Stack direction="row" justifyContent="space-between">
              <Box flex="auto 0 0">
                <Box
                  sx={{
                    borderRadius: "100%",
                    overflow: "hidden",
                    width: 100,
                    height: 100,
                    backgroundImage: `url(${imageUrl})`,
                    backgroundSize: "80% auto",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    backgroundColor: palette.invariantWhite,
                  }}
                />
              </Box>

              <Box
                textAlign="right"
                flex="auto 0 1"
                maxWidth="100%"
                overflow="hidden"
                pl={0}
              >
                <Stack direction="column" gap={0}>
                  {maybeStrategy ? (
                    <Box>
                      <OpaqueChip>{maybeStrategy?.name}</OpaqueChip>
                    </Box>
                  ) : undefined}

                  {maybeGeography ? (
                    <Box>
                      <OpaqueChip>{maybeGeography?.name}</OpaqueChip>
                    </Box>
                  ) : undefined}
                </Stack>
              </Box>
            </Stack>

            <Box mt={1}>
              <Typography
                variant="h3"
                color={palette.invariantWhite}
                className="truncate"
              >
                {asset.name}
              </Typography>
            </Box>

            <Box mt={0}>
              <Typography
                variant="body2"
                color={palette.invariantWhite}
                // This will use CSS to limit the description.
                // It has a decent browser support but we should keep an eye on it.
                sx={{
                  display: "-webkit-box",
                  "-webkit-line-clamp": "4",
                  "-webkit-box-orient": "vertical",
                  overflow: "hidden",
                }}
              >
                {asset.description}
              </Typography>
            </Box>

            <Box mt={1}>
              <Stack direction="row">
                <Box flex="50% 0 0">
                  <Box>
                    <Typography
                      variant="figuresNumber"
                      color={palette.invariantWhite}
                    >
                      {mom}
                    </Typography>

                    <Typography
                      variant="figuresSymbol"
                      color={palette.invariantWhite}
                    >
                      ×
                    </Typography>
                  </Box>

                  <Box>
                    <Typography
                      variant="h6"
                      color={palette.invariantWhite}
                      sx={{ opacity: 0.5 }}
                    >
                      MOM
                    </Typography>
                  </Box>
                </Box>

                <Box flex="50% 0 0">
                  <Box>
                    <Typography
                      variant="figuresNumber"
                      color={palette.invariantWhite}
                    >
                      {irr}
                    </Typography>

                    <Typography
                      variant="figuresSymbol"
                      color={palette.invariantWhite}
                    >
                      %
                    </Typography>
                  </Box>

                  <Box>
                    <Typography
                      variant="h6"
                      color={palette.invariantWhite}
                      sx={{ opacity: 0.5 }}
                    >
                      IRR
                    </Typography>
                  </Box>
                </Box>
              </Stack>
            </Box>
          </Box>

          <Box>
            <Stack direction="row" gap={0} flexWrap="wrap">
              <Box flex="auto 1 1">
                <Button variant="secondary-dark" onClick={onDetailsClick}>
                  Details
                </Button>
              </Box>

              <Box flex="auto 1 1">
                <Button
                  variant="secondary-dark"
                  onClick={() => setAllocationDialogOpen(true)}
                >
                  {pendingAllocation ? "Edit allocation" : "Request allocation"}
                </Button>
              </Box>
            </Stack>
          </Box>
        </Stack>
      </Card>

      <RequestAllocationDialog
        asset={asset}
        open={allocationDialogOpen}
        onClose={() => setAllocationDialogOpen(false)}
        pendingAllocation={pendingAllocation}
      />
    </>
  );
};
