import { Typography } from "@mui/material";
import React, { useMemo } from "react";
import { AssetInfo } from "./AssetInfo";
import { AssetOverview } from "./AssetOverview";
import { AssetTerms } from "./AssetTerms";
import { DetailedAsset } from "../data/DetailedAsset";
import { SingleAssetDocument } from "../data/SignleAssetDocument";
import { SingleAssetContract } from "../data/SingleAssetContract";
import { AssetDocuments } from "./AssetDocuments";
import { Box } from "../../../core/v2/Box/Box";
import { AssetStatus } from "../../../assets/v2/data/AssetStatus";
import { NavigationTabs } from "../../../core/v2/NavigationTabs/NavigationTabs";

type Props = {
  asset: DetailedAsset;
  status: AssetStatus;
  documents: ReadonlyArray<SingleAssetDocument>;
  contracts: ReadonlyArray<SingleAssetContract>;
};

/**
 * Main asset details view.
 */
export const AssetView: React.FC<Props> = ({
  asset,
  status,
  documents,
  contracts,
}) => {
  const tabs = useMemo(
    () => [
      {
        route: "overview" as const,
        displayName: "Overview",
        component: (
          <Box>
            <AssetOverview asset={asset} />
          </Box>
        ),
      },

      {
        route: "terms" as const,
        displayName: "Terms",
        component: (
          <Box>
            <AssetTerms asset={asset} status={status} />
          </Box>
        ),
      },

      {
        route: "documents" as const,
        displayName: "Documents",
        component: (
          <Box>
            <AssetDocuments documents={documents} contracts={contracts} />
          </Box>
        ),
      },
    ],
    [asset, documents, contracts, status],
  );

  return (
    <>
      <Box>
        <Typography variant="h1">{asset.name}</Typography>
      </Box>

      <Box mt={4}>
        <AssetInfo asset={asset} />
      </Box>

      <Box mt={3}>
        <NavigationTabs tabs={tabs} defaultTab="overview" />
      </Box>
    </>
  );
};
