import { Slider as MuiSlider, SliderProps, styled } from "@mui/material";

/**
 * A simple slider selector.
 */
export const Slider = styled(MuiSlider)<SliderProps>(({
  theme: { palette, typography },
  marks,
}) => {
  const firstMarkAttrSelector = `&[data-index="${0}"]`;
  const lastMarkAttrSelector = `&[data-index="${
    Array.isArray(marks) ? marks.length - 1 : 0
  }"]`;

  return {
    color: palette.heritageV2.accent,
    "& .MuiSlider-thumb": {
      "&:hover, &.Mui-focusVisible": {
        boxShadow: "none",
      },
      "&.Mui-active": {
        boxShadow: "none",
      },
    },

    "& .MuiSlider-markLabel": {
      fontSize: typography.formLabel,
      color: palette.heritageV2.neutral700,

      [firstMarkAttrSelector]: {
        transform: "translateX(0%)",
      },

      [lastMarkAttrSelector]: {
        transform: "translateX(-100%)",
      },
    },

    "& .MuiSlider-valueLabel": {
      backgroundColor: palette.heritageV2.neutral700,
    },
  };
});
