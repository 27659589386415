import React, { useCallback, useMemo, useState } from "react";
import { option as O } from "fp-ts";
import { useQuarterSelectManager } from "../QuarterSelect/QuarterSelect";
import {
  ActiveTableColumnFilter,
  TableColumn,
} from "../../../core/v2/Table/Table";
import { useAssetUrl } from "../../../../hooks/useAssetUrl";
import { PortfolioCompaniesFilters } from "./PortfolioCompaniesFilters";
import { SkeletonLoader } from "../../../core/v2/Loader/SkeletonLoader";
import { PageTable } from "../../../core/v2/PageTable/PageTable";
import { portfolioEmptyQuarterText } from "../PortfolioOverview/PortfolioOverview";
import { tracker } from "../../../../utils/tracker";

export type PortfolioCompaniesRow = {
  id: string;
  company: string;
  companyLink?: string;
  industry: string;
  region: string;
  fund: string;
  fundId: string;
  commitment: number;
  paidIn: number;
};

/**
 * Display the `companies` tab of the portfolio.
 */
export const PortfolioCompanies: React.FC = () => {
  const { maybePortfolioSituation, loading } = useQuarterSelectManager();

  const getFundUrl = useAssetUrl({ fromPortfolio: true });

  const [filters, setFilters] = useState<
    Array<ActiveTableColumnFilter<PortfolioCompaniesRow>>
  >([]);

  const columns = useMemo<Array<TableColumn<PortfolioCompaniesRow>>>(
    () => [
      {
        headerName: "Company",
        field: "company",
        flex: 3,
        minWidth: 250,
        customColumnKind: {
          kind: "externalLink",
          generateLink: ({ row }) => (row ? row.companyLink : undefined),
          onLinkClick: ({ row }) =>
            row
              ? () =>
                  tracker.trackEvent({
                    name: "CompanyLinkClick",
                    payload: {
                      assetName: row.fund,
                      url: row.companyLink ?? "",
                    },
                  })
              : undefined,
        },
      },
      {
        headerName: "Industry",
        field: "industry",
      },
      {
        headerName: "Region",
        minWidth: 150,
        field: "region",
      },
      {
        headerName: "Fund",
        field: "fund",
        customColumnKind: {
          kind: "internalLink",
          generateLink: ({ row }) => (row ? getFundUrl(row.fundId) : "#"),
        },
        minWidth: 200,
      },
    ],
    [getFundUrl],
  );

  const rows = useMemo<Array<PortfolioCompaniesRow>>(() => {
    if (O.isNone(maybePortfolioSituation)) return [];

    return maybePortfolioSituation.value.companies.map((company) => ({
      id: company.id,
      company: company.name,
      companyLink: company.website ?? undefined,
      industry: company.sector ?? "-",
      region: company.region ?? "-",
      fund: company.asset.name,
      fundId: company.asset.id,
      commitment: company.amountUsd ?? 0,
      paidIn: company.percentage ?? 0,
    }));
  }, [maybePortfolioSituation]);

  const { industryValues, regionValues, fundValues } = useMemo<{
    industryValues: Set<string>;
    regionValues: Set<string>;
    fundValues: Set<string>;
  }>(() => {
    if (O.isNone(maybePortfolioSituation))
      return {
        industryValues: new Set(),
        regionValues: new Set(),
        fundValues: new Set(),
      };

    const industryValues = new Set<string>();
    const regionValues = new Set<string>();
    const fundValues = new Set<string>();

    maybePortfolioSituation.value.companies.forEach((company) => {
      if (company.sector) {
        industryValues.add(company.sector);
      }
      if (company.region) {
        regionValues.add(company.region);
      }
      fundValues.add(company.asset.name);
    });

    return {
      industryValues,
      regionValues,
      fundValues,
    };
  }, [maybePortfolioSituation]);

  const handleFiltersChange = useCallback(
    (column: keyof PortfolioCompaniesRow, value: string | undefined) => {
      setFilters((filters) => {
        const newFilters = filters.filter((filter) => filter.column !== column);

        if (value) {
          newFilters.push({
            column,
            value,
          });
        }

        return newFilters;
      });
    },
    [setFilters],
  );

  return (
    <>
      <PageTable
        title="Companies"
        kind="table"
        loading={loading}
        rows={rows}
        columns={columns}
        filters={filters}
        loaderComponent={<SkeletonLoader size={{ height: "table" }} />}
        filtersComponent={
          <PortfolioCompaniesFilters
            currentFilters={filters}
            industryValues={industryValues}
            regionValues={regionValues}
            fundValues={fundValues}
            onChange={handleFiltersChange}
          />
        }
        customEmptyText={portfolioEmptyQuarterText}
        quarterSelect
        downloadCsv
      />
    </>
  );
};
