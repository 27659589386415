import { atom } from "recoil";

/**
 * Application state that determines which quarter is selected
 * in the portfolio page.
 */
export const portfolioSelectedQuarter = atom<string | undefined>({
  key: "portfolioSelectedQuarter",
  default: undefined,
});
