import { startOfTomorrow, format, isSameDay } from "date-fns";
import { formatISODate } from "../lib/utils/date";

/**
 * Get the cutoff date for the "Today" section.
 */
export function getTodayCutoff() {
  return startOfTomorrow();
}

/**
 * Format the cutoff date in order to be actually
 * used inside the application.
 */
export function formatCutoffDate(date: Date) {
  return formatISODate(date);
}

/**
 * Render a date into a quarter string
 * in the format of "Q2 23".
 */
export function formatCutoffDateQuarter(date: Date) {
  return format(date, "QQQ yy");
}

/**
 * Check if two dates, even in different standards, are the
 * same exact day.
 */
export function isSameCutoffDate(strDateA: string, strDateB: string) {
  const dateA = new Date(strDateA);
  const dateB = new Date(strDateB);

  return isSameDay(dateA, dateB);
}
