import React, { useEffect } from "react";
import { Card } from "../../../core/v2/Card/Card";
import { Box, Typography } from "@mui/material";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm, useWatch } from "react-hook-form";
import { Input } from "../../../core/v2/Input/Input";
import { Button } from "../../../core/v2/Button/Button";
import { match, P } from "ts-pattern";
import { usePasswordChangeMutation } from "../../../../utils/hooks/usePasswordChangeMutation";
import { useNotifications } from "../../../../utils/notifications";
import { userPasswordSchema } from "@heritageholdings/lib-commons-validator/lib/shared/userPassword";
import { PasswordConstraints } from "../../../commons/PasswordConstraints";

const formatErrorMessage = (
  message: string | undefined,
): string | undefined => {
  return match(message)
    .with("TOO_SHORT", () => "The password is too short")
    .with("INVALID", () => "The password must respects the constraints")
    .with("INCORRECT", () => "Wrong password")
    .with(P.string, (str) => str.charAt(0).toUpperCase() + str.slice(1))
    .otherwise(() => undefined);
};

const changePasswordSchema = z
  .object({
    currentPassword: z.string().min(1, "TOO_SHORT"),
    newPassword: userPasswordSchema,
    confirmPassword: z.string(),
  })
  .superRefine(({ currentPassword, confirmPassword, newPassword }, ctx) => {
    if (currentPassword === newPassword) {
      ctx.addIssue({
        path: ["newPassword"],
        code: "custom",
        message: "New password should be different from the current password",
      });
    }

    if (confirmPassword !== newPassword) {
      ctx.addIssue({
        path: ["confirmPassword"],
        code: "custom",
        message: "The new password and confirmation do not match",
      });
    }
  });

type ChangePasswordSchema = z.infer<typeof changePasswordSchema>;

export const ChangePasswordComponent: React.FC = () => {
  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isValid, isDirty },
    reset,
    watch,
    trigger,
  } = useForm<ChangePasswordSchema>({
    mode: "all",
    resolver: zodResolver(changePasswordSchema),
    defaultValues: {
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
  });

  const watchedPassword = useWatch({
    control,
    name: "newPassword",
    defaultValue: "",
  });

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      // If the user changes the currentPassword and already have inserted a newPassword
      // we should trigger the validation of the newPassword again to check if it's different
      if (name === "currentPassword" && value.newPassword !== "") {
        void trigger(["newPassword"]);
      }
      // If the user changes the newPassword and already have inserted a confirmPassword
      // we should trigger the validation of the confirmPassword again to check if it's different
      else if (name === "newPassword" && value.confirmPassword !== "") {
        void trigger(["confirmPassword"]);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, trigger]);

  const { mutate, isSuccess, data, isLoading } = usePasswordChangeMutation();

  const { successNotification, errorNotification } = useNotifications();

  const submitButtonEnabled = isDirty && isValid && !isSubmitting && !isLoading;
  const textInputEnabled = !isLoading && !isSubmitting;

  useEffect(() => {
    if (isSuccess && data.status === 200) {
      reset({
        confirmPassword: "",
        currentPassword: "",
        newPassword: "",
      });
      successNotification("Password changed successfully");
    } else if (isSuccess && data.status !== 200) {
      errorNotification("Something went wrong");
    }
  }, [isSuccess, data, successNotification, errorNotification, reset]);

  return (
    <Card>
      <form
        onSubmit={handleSubmit((formValues) => {
          mutate(formValues);
        })}
      >
        <Box
          display={"flex"}
          justifyContent={"flex-start"}
          flexDirection={"column"}
        >
          <Typography variant={"h3"}>Change password</Typography>

          <Box mt={1}>
            <Controller
              name="currentPassword"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <Input
                  error={error !== undefined}
                  type="password"
                  label={"Current password"}
                  helperText={formatErrorMessage(error?.message)}
                  disabled={!textInputEnabled}
                  {...field}
                />
              )}
            />
          </Box>

          <Box mt={1}>
            <Controller
              name="newPassword"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <Input
                  error={error !== undefined}
                  type="password"
                  label={"New password"}
                  helperText={formatErrorMessage(error?.message)}
                  disabled={!textInputEnabled}
                  {...field}
                />
              )}
            />
          </Box>

          <Box mt={0}>
            <PasswordConstraints password={watchedPassword} />
          </Box>

          <Box mt={1}>
            <Controller
              name="confirmPassword"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <Input
                  error={error !== undefined}
                  type="password"
                  label={"Repeat new password"}
                  helperText={formatErrorMessage(error?.message)}
                  disabled={!textInputEnabled}
                  {...field}
                />
              )}
            />
          </Box>

          <Box mt={2} flex={"0 1 auto"} alignSelf={"flex-end"}>
            <Button
              variant={"primary"}
              type="submit"
              disabled={!submitButtonEnabled}
            >
              Save changes
            </Button>
          </Box>
        </Box>
      </form>
    </Card>
  );
};
