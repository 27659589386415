import React from "react";

import IconButton from "@mui/material/IconButton";
import InfoRounded from "@mui/icons-material/InfoRounded";

import { AuditTrailDialog } from "../AuditTrailDialog/AuditTrailDialog";

type Props = {
  value: string;
};

export const AuditTrailCell: React.FC<Props> = (props) => {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <>
      <IconButton onClick={() => setIsOpen(true)} size="small" color="primary">
        <InfoRounded />
      </IconButton>

      {isOpen && (
        <AuditTrailDialog
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          trailJson_UNSTABLE={props.value}
        />
      )}
    </>
  );
};
