import React from "react";
import { AssetsRouteComponent } from "../../generated/urql";
import { AssetsView as AssetsViewV2 } from "../../components/assets/v2/AssetsView/AssetsView";
import { handleQueryData } from "../../utils/queries";
import { useInvestor } from "../../hooks/useInvestor";

export const AssetsRoute = () => {
  const investor = useInvestor();

  if (investor === undefined) {
    return null; // TODO: handle error
  }

  return (
    <AssetsRouteComponent
      variables={{ investorId: investor.id }}
      children={handleQueryData((data) => (
        <AssetsViewV2
          openAssets={data.investor.openAssets}
          pendingAllocations={data.investor.pendingAllocations}
        />
      ))}
    />
  );
};
