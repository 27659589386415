import React from "react";
import { Dialog } from "../../../core/v2/Dialog/Dialog";
import { Typography, useTheme } from "@mui/material";
import { Box } from "../../../core/v2/Box/Box";
import { useRecoilState } from "recoil";
import { cashflowSimulatorDisclaimerAccepted } from "../../../../state/cashflowSimulator";

export const CashflowSimulatorDisclaimer = () => {
  const [disclaimerAccepted, setDisclaimerAccepted] = useRecoilState(
    cashflowSimulatorDisclaimerAccepted,
  );

  const theme = useTheme();

  const acceptDisclaimer = () => {
    setDisclaimerAccepted(true);
  };

  return (
    <Dialog
      title="Disclaimer"
      open={!disclaimerAccepted}
      onClose={acceptDisclaimer}
      submitButtonText="Agree"
      onSubmit={acceptDisclaimer}
      maxWidth={700}
      noContentPadding
    >
      <Box maxHeight={500} overflow="auto" px={3}>
        <Typography>
          The following disclaimer provides detailed information about the
          nature of the numbers presented in the Cash Flow Simulator (J-Curve)
          on the Heritage web app. It is designed to inform investors and should
          be read carefully before proceeding:
          <ol
            style={{
              display: "flex",
              flexDirection: "column",
              gap: theme.spacing(2),
            }}
          >
            <li>
              <strong>Illustrative Nature</strong>: The J-Curve illustration
              displayed on the Heritage web app is for illustrative purposes
              only. It serves as a visual representation to help investors
              understand potential investment cash flows over time. However, it
              is important to note that the numbers presented are not reflective
              of actual or guaranteed cash flow and investment performance. They
              are designed to provide a conceptual idea rather than precise
              predictions.
            </li>

            <li>
              <strong>Collected and Elaborated Data</strong>: The numbers
              depicted in the J-Curve are derived from the underlying investment
              assets and data collected from various sources. These figures have
              undergone calculation and elaboration processes to create the
              visual representation displayed on the Heritage web app. However,
              the calculations and methods used may involve certain assumptions,
              estimates, or approximations.
            </li>

            <li>
              <strong>Subject to Change</strong>: Investors should be aware that
              the J-Curve illustration and the associated numbers are subject to
              change. The actual cash flows and investment performance may
              differ significantly from the visual representation due to various
              factors. Market conditions, economic fluctuations, regulatory
              changes, and other unforeseen circumstances can impact investment
              outcomes. Therefore, it is important to recognize that the J-Curve
              illustration is not a static prediction but rather a dynamic
              representation that can evolve over time.
            </li>

            <li>
              <strong>No Investment Advice</strong>: The J-Curve illustration
              does not constitute investment advice, financial recommendations,
              or any form of professional guidance. It is provided solely for
              informational purposes. The purpose of the J-Curve illustration is
              to offer investors a general understanding of potential investment
              cash flows and their timeframes. However, it should not be the
              sole basis for making investment decisions. Investors should seek
              personalized advice from qualified professionals who can consider
              their individual circumstances and objectives.
            </li>

            <li>
              <strong>No Guarantee of Accuracy</strong>: While Heritage strives
              to ensure the accuracy of the J-Curve illustration, it cannot
              guarantee its precision or completeness. The numbers presented may
              contain errors, omissions, or inaccuracies. They are subject to
              potential limitations in the data collection process, assumptions
              made, and the calculation and elaboration methods used. Investors
              should exercise caution and conduct their own due diligence before
              relying solely on the information provided in the J-Curve
              illustration.
            </li>

            <li>
              <strong>
                Past Performance is not Indicative of Future Results
              </strong>
              : The J-Curve illustration may include historical data or
              references to past investment performance. However, it is
              essential to understand that past performance is not indicative of
              future results. The investment landscape is dynamic and subject to
              various market conditions that can impact investment outcomes.
              Factors such as economic trends, market volatility, and
              geopolitical events can significantly affect investment
              performance in the future.
            </li>

            <li>
              <strong>No Liability</strong>: Heritage disclaims any liability
              for any loss, damage, or expense incurred directly or indirectly
              as a result of reliance on the J-Curve illustration or any
              associated information provided. Investors assume all risks
              associated with their investment decisions. Heritage cannot be
              held responsible for any financial losses or negative consequences
              that may arise from decisions made based on the J-Curve
              illustration. Investors are encouraged to take responsibility for
              their investment choices and to carefully evaluate their risk
              tolerance and financial objectives.
            </li>
          </ol>
          By continuing to view the J-Curve illustration on the Heritage web
          app, users acknowledge and agree to the terms and conditions outlined
          in this disclaimer. This means that users understand and accept the
          limitations, risks, and informational nature of the J-Curve
          illustration. If users do not agree with these terms and conditions,
          Heritage kindly requests that they refrain from using the J-Curve
          illustration feature on the web app. By choosing not to proceed, users
          demonstrate their acknowledgment that they do not wish to rely on the
          J-Curve illustration or its associated information for investment
          decisions. However, it is important to note that this disclaimer does
          not cover all aspects or potential risks associated with investing.
          Investing involves a wide range of considerations, risks, and factors
          that may not be explicitly addressed in this disclaimer. Investors
          should take responsibility for their investment decisions and educate
          themselves on the various aspects of investing. Seeking professional
          advice from qualified financial advisors or other experts is highly
          recommended to ensure a comprehensive understanding of the investment
          process. This disclaimer serves as a starting point for investors to
          be aware of the limitations and general nature of the J-Curve
          illustration, but it is not a substitute for personalized advice or
          thorough research. It is crucial for users to carefully review and
          consider the implications of this disclaimer, as well as any
          additional terms and conditions provided by Heritage or applicable
          regulatory bodies, before using the J-Curve illustration feature on
          the web app.
        </Typography>
      </Box>
    </Dialog>
  );
};
