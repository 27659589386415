import React from "react";
import { IterableElement } from "type-fest";
import { NavigationTabs as NewNavigationTabs } from "../v2/NavigationTabs/NavigationTabs";

type SingleTab<R extends string> = {
  route: R;
  displayName: string;
  component?: React.ReactNode;
};

type Props<R extends string, T extends Array<SingleTab<R>>> = {
  tabs: T;
  defaultTab: IterableElement<T>["route"];
};

/**
 * Temporary NavigationTabs component that will proxy
 * the render to the correct component depending on the theme used.
 */
export const NavigationTabs = <R extends string, T extends Array<SingleTab<R>>>(
  props: Props<R, T>,
) => {
  return <NewNavigationTabs {...props} />;
};
