import React from "react";

import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";

import { AuditTrailFormula } from "../AuditTrailFormula/AuditTrailFormula";
import { MoneyT } from "../AuditTrailFormula/types";
import { either as E } from "fp-ts";
import { Alert } from "@mui/material";

type Props = Readonly<{
  isOpen: boolean;
  trailJson_UNSTABLE?: string;
  onClose: () => void;
}>;

export const AuditTrailDialog: React.FC<Props> = ({
  onClose,
  trailJson_UNSTABLE,
  isOpen,
}) => {
  const handleClose = () => onClose();

  const trail = JSON.parse(trailJson_UNSTABLE ?? "{}");

  const navTrailJson = MoneyT.decode("nav" in trail ? trail.nav : {});

  return (
    <Dialog fullScreen onClose={handleClose} open={isOpen}>
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Financial Stack Audit Trail
          </Typography>
        </Toolbar>
      </AppBar>
      {E.isRight(navTrailJson) ? (
        <AuditTrailFormula value={navTrailJson.right} />
      ) : (
        <Alert severity="error">Invalid JSON - cannot decode data</Alert>
      )}
    </Dialog>
  );
};
