import { PageTable } from "../../../core/v2/PageTable/PageTable";
import React, { useEffect, useMemo } from "react";
import { TableColumn } from "../../../core/v2/Table/Table";
import { Typography } from "@mui/material";
import {
  AuthenticatorCreatedFromClient,
  AuthenticatorCreatedPlatform,
  LayoutRouteQuery,
  useDeleteAuthenticatorMutation,
  useLayoutRouteQuery,
} from "../../../../generated/urql";
import { match } from "ts-pattern";
import { DesignTokens } from "@heritageholdings/lib-commons-frontend";
import { DeleteIcon } from "../../../core/v2/Icon/Icon";
import AppleSvg from "@heritageholdings/lib-commons-frontend/assets/svg/passkey/apple.dynamic.svg?react";
import MacOsSvg from "@heritageholdings/lib-commons-frontend/assets/svg/passkey/macos.dynamic.svg?react";
import AndroidSvg from "@heritageholdings/lib-commons-frontend/assets/svg/passkey/android.dynamic.svg?react";
import LinuxSvg from "@heritageholdings/lib-commons-frontend/assets/svg/passkey/linux.dynamic.svg?react";
import OthersSvg from "@heritageholdings/lib-commons-frontend/assets/svg/passkey/others.dynamic.svg?react";
import WindowsSvg from "@heritageholdings/lib-commons-frontend/assets/svg/passkey/windows.dynamic.svg?react";
import { IconButton } from "../../../core/v2/IconButton/IconButton";
import { SkeletonLoader } from "../../../core/v2/Loader/SkeletonLoader";
import { Dialog } from "../../../core/v2/Dialog/Dialog";
import { useNotifications } from "../../../../utils/notifications";
import { tracker } from "../../../../utils/tracker";

type NameRow = { name: string; createdPlatform: AuthenticatorCreatedPlatform };

type PasskeyRow = {
  id: string;
  name: NameRow;
  createdWith: string;
  createdAt?: Date;
  lastUsedAt?: Date;
  delete: string;
};

const formatCreatedFromClient = (
  createdFromClient: AuthenticatorCreatedFromClient | undefined,
) =>
  match(createdFromClient)
    .with(AuthenticatorCreatedFromClient.Web, () => "Heritage Web app")
    .with(AuthenticatorCreatedFromClient.Mobile, () => "Heritage Mobile app")
    .with(AuthenticatorCreatedFromClient.Others, () => "Heritage platform")
    .otherwise(() => "Heritage platform");

const fromPlatformToLogo = (
  platform: AuthenticatorCreatedPlatform | undefined,
) =>
  match(platform)
    .with(AuthenticatorCreatedPlatform.Ios, () => (
      <AppleSvg
        height={DesignTokens.sizes.iconMedium}
        width={DesignTokens.sizes.iconMedium}
        fill={DesignTokens.darkColors.neutral500}
      />
    ))
    .with(AuthenticatorCreatedPlatform.Android, () => (
      <AndroidSvg
        height={DesignTokens.sizes.iconMedium}
        width={DesignTokens.sizes.iconMedium}
        fill={DesignTokens.darkColors.neutral500}
      />
    ))
    .with(AuthenticatorCreatedPlatform.Windows, () => (
      <WindowsSvg
        height={DesignTokens.sizes.iconMedium}
        width={DesignTokens.sizes.iconMedium}
        fill={DesignTokens.darkColors.neutral500}
      />
    ))
    .with(AuthenticatorCreatedPlatform.Linux, () => (
      <LinuxSvg
        height={DesignTokens.sizes.iconMedium}
        width={DesignTokens.sizes.iconMedium}
        fill={DesignTokens.darkColors.neutral500}
      />
    ))
    .with(AuthenticatorCreatedPlatform.Mac, () => (
      <MacOsSvg
        height={DesignTokens.sizes.iconMedium}
        width={DesignTokens.sizes.iconMedium}
        fill={DesignTokens.darkColors.neutral500}
      />
    ))
    .with(AuthenticatorCreatedPlatform.Others, () => (
      <OthersSvg
        height={DesignTokens.sizes.iconMedium}
        width={DesignTokens.sizes.iconMedium}
        fill={DesignTokens.darkColors.neutral500}
      />
    ))
    .otherwise(() => (
      <OthersSvg
        height={DesignTokens.sizes.iconMedium}
        width={DesignTokens.sizes.iconMedium}
        fill={DesignTokens.darkColors.neutral500}
      />
    ));

const DeletePasskeyButton: React.FC<{
  id: string;
  onClick: (id: string) => void;
}> = ({ id, onClick }) => {
  return (
    <IconButton
      Icon={DeleteIcon}
      danger={true}
      onClick={() => {
        onClick(id);
      }}
    />
  );
};

const columns = (
  onClick: (id: string) => void,
): Array<TableColumn<PasskeyRow>> => [
  {
    headerName: "Name",
    field: "name",
    flex: 3,
    customColumnKind: {
      kind: "withInlineComponent",
      generateCell: ({ value }) => {
        const typedName = value as NameRow;

        return {
          value: typedName.name,
          prefixComponent: fromPlatformToLogo(typedName.createdPlatform),
        };
      },
    },
  },
  {
    headerName: "Created with",
    field: "createdWith",
    flex: 2,
  },
  {
    headerName: "Created",
    field: "createdAt",
    type: "dateTime",
    flex: 2,
  },
  {
    headerName: "Last used",
    field: "lastUsedAt",
    type: "dateTime",
    flex: 2,
  },
  {
    headerName: "Delete",
    field: "delete",
    customColumnKind: {
      kind: "withInlineComponent",
      generateCell: ({ value }) =>
        typeof value === "string"
          ? {
              prefixComponent: (
                <DeletePasskeyButton id={value} onClick={onClick} />
              ),
            }
          : {},
    },
    flex: 1,
  },
];

/**
 * Formats the authenticator data into a row that can be used by the table.
 * @param auth
 */
const formatAuthenticatorRows = (
  auth: LayoutRouteQuery["authUser"]["authenticators"][0],
) => ({
  id: auth.id,
  name: {
    name: auth.createdDevice ?? "Unknown",
    createdPlatform: auth.createdPlatform,
  },
  createdWith: formatCreatedFromClient(auth.createdFromClient),
  createdAt: auth.createdAt ? new Date(auth.createdAt) : undefined,
  lastUsedAt: auth.lastUsed ? new Date(auth.lastUsed) : undefined,
  delete: auth.id,
});

const tableRowHeight = 52;

/**
 * Asks the user to confirm that they want to revoke a passkey.
 * @param authenticator
 * @param onClose
 * @param onSubmit
 * @constructor
 */
const DeletePasskeyDialog: React.FC<{
  authenticator: LayoutRouteQuery["authUser"]["authenticators"][0] | null;
  onClose: () => void;
  onSubmit: () => void;
}> = ({ authenticator, onClose, onSubmit }) => {
  return (
    <Dialog
      title={`Revoke ${authenticator?.createdDevice} passkey`}
      open={authenticator !== null}
      onClose={onClose}
      onCancel={onClose}
      onSubmit={onSubmit}
      cancelButtonText="Cancel"
      submitButtonText={"Revoke"}
      submitButtonNegative={true}
      minWidth={400}
      maxWidth={500}
    >
      <Typography>
        The Passkey you are about to revoke will no longer be valid for
        authentication but will remain on your device. You will need to set up a
        new Passkey for future use.
      </Typography>
    </Dialog>
  );
};

/**
 * Displays a table with all the passkeys that the user has created.
 * The user can delete a passkey from this table.
 * @param authenticators
 * @constructor
 */
export const PasskeysTable: React.FC<{
  authenticators: LayoutRouteQuery["authUser"]["authenticators"];
}> = ({ authenticators }) => {
  const { successNotification, errorNotification } = useNotifications();
  const [{ fetching, error, data }, submit] = useDeleteAuthenticatorMutation();
  const [_, refresh] = useLayoutRouteQuery();
  const [passkeyToDelete, setPasskeyToDelete] = React.useState<
    LayoutRouteQuery["authUser"]["authenticators"][0] | null
  >(null);

  // handle mutation results
  useEffect(() => {
    if (!data) return;
    if (
      data.deleteAuthenticator.__typename === "DeleteAuthenticatorError" ||
      error !== undefined
    ) {
      tracker.trackEvent({
        name: "PasskeyRevokeFailure",
        payload: { error: error?.message },
      });
      errorNotification("Failed to revoke passkey");
    } else if (data.deleteAuthenticator.success) {
      tracker.trackEvent({ name: "PasskeyRevokeCompleted" });
      successNotification("Passkey revoked");
    }
  }, [data, error, errorNotification, successNotification]);

  const cols = useMemo(
    () =>
      columns(async (id) => {
        setPasskeyToDelete(authenticators.find((a) => a.id === id) ?? null);
      }),
    [authenticators],
  );

  return (
    <>
      <PageTable
        title="Passkeys that you created"
        kind="table"
        loading={fetching}
        columns={cols}
        loaderComponent={
          <SkeletonLoader
            size={{
              height: tableRowHeight * (authenticators.length + 1),
            }}
          />
        }
        rows={authenticators.map(formatAuthenticatorRows)}
      />
      <DeletePasskeyDialog
        authenticator={passkeyToDelete}
        onClose={() => setPasskeyToDelete(null)}
        onSubmit={async () => {
          tracker.trackEvent({ name: "PasskeyRevokeStart" });
          setPasskeyToDelete(null);
          await submit({ input: passkeyToDelete?.id ?? "" });
          refresh();
        }}
      />
    </>
  );
};
