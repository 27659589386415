import { Money } from "@heritageholdings/lib-commons-finance/lib/units/money";
import { CashflowComputedAsset } from "./CashflowComputedAsset";
import { Numeric } from "@heritageholdings/lib-commons-finance/lib/units/numeric";
import { interpolateMoic } from "./CashflowAssetJCurve";

/**
 * The general stats for a single asset.
 */
export type CashflowStats = {
  committedCapital: Money;
  expectedProfit: Money;
  expectedProfitLastYear: number;
  paidIn: Money;
};

/**
 * Generate the stats for a single asset.
 */
export function generateJCurveStatsForAsset(
  jcurveAsset: CashflowComputedAsset,
  latestYear: number,
  moicScenario: Numeric,
): CashflowStats {
  const moic = interpolateMoic(moicScenario, jcurveAsset).withLabel(
    `${jcurveAsset.name} MOIC`,
  );

  const committedCapital = jcurveAsset.allocationAmount.withLabel(
    `${jcurveAsset.name} Committed capital`,
  );

  const expectedReturn = committedCapital
    .times(moic)
    .withLabel(`${jcurveAsset.name} Expected Return`);

  const expectedProfit = expectedReturn
    .minus(committedCapital)
    .withLabel(`${jcurveAsset.name} Expected Profit`);

  const expectedProfitLastYear = latestYear;

  const paidIn = jcurveAsset.paidIn.withLabel(`${jcurveAsset.name} Paid In`);

  return {
    committedCapital,
    expectedProfit,
    expectedProfitLastYear,
    paidIn,
  };
}

/**
 * Sum the stats for two assets.
 */
export function sumJCurveStatsForAsset(
  a: CashflowStats,
  b: CashflowStats,
): CashflowStats {
  return {
    committedCapital: a.committedCapital.plus(b.committedCapital),
    expectedProfit: a.expectedProfit.plus(b.expectedProfit),
    expectedProfitLastYear: Math.max(
      a.expectedProfitLastYear,
      b.expectedProfitLastYear,
    ),
    paidIn: a.paidIn.plus(b.paidIn),
  };
}
