import { Typography } from "@mui/material";
import React from "react";

/**
 * it shows a message indicating the system cannot be used since they are
 * under maintenance
 */
export const MaintenanceBanner = () => (
  <Typography variant={"h5"} pt={6} pb={6} textAlign={"center"}>
    The Heritage site is under extraordinary maintenance, please come back later
  </Typography>
);
