import { Stack, Typography } from "@mui/material";
import React, { useMemo } from "react";
import { useHeritageV2Palette } from "../../../../utils/hooks/useHeritageV2Palette";
import { readonlyArray as RA, function as F, number as N } from "fp-ts";
import { contramap, reverse } from "fp-ts/lib/Ord";
import * as U_Show from "@heritageholdings/lib-commons-finance/lib/show";
import { Box } from "../../../core/v2/Box/Box";

/**
 * The actual bar height in the chart.
 */
const barHeight = 30;

/**
 * The threashold for the text to be displayed in the bar.
 * If the bar is smaller than this value, the text will be hidden.
 */
const barTextThreashold = "40px";

/**
 * The CSS rule to hide the text in the bar.
 */
const hideTextMaxWidthRule = `max(0px, calc((100% - ${barTextThreashold}) * 999))`;

type DiversificationPoint = {
  name: string;
  amount: number;
};

/**
 * Sorter used to sort the diversification points by amount
 * in descending order.
 */
const byAmountSorter = F.pipe(
  N.Ord,
  contramap((p: DiversificationPoint) => p.amount),
  reverse,
);

type Props = {
  title: string;
  data: ReadonlyArray<DiversificationPoint>;
};

/**
 * The diversification horizontal bar chart.
 */
export const PortfolioDiversificationChart: React.FC<Props> = ({
  title,
  data,
}) => {
  const palette = useHeritageV2Palette();

  const shades = useMemo(
    () => [
      palette.chart500,
      palette.chart400,
      palette.chart300,
      palette.chart200,
      palette.chart100,
    ],
    [palette],
  );

  const total = useMemo(
    () => data.reduce((acc, next) => acc + next.amount, 0),
    [data],
  );

  const sorteredData = useMemo(
    () => F.pipe(data, RA.sortBy([byAmountSorter])),
    [data],
  );

  const otherDataValue = useMemo(
    () =>
      sorteredData
        .slice(shades.length)
        .reduce((acc, next) => acc + next.amount, 0),
    [sorteredData, shades.length],
  );

  const shadesWithOther = useMemo(
    () => [...shades, palette.neutral500],
    [shades, palette],
  );

  const dataWithOther = useMemo<Array<DiversificationPoint>>(
    () => [
      ...sorteredData.slice(0, shades.length),
      ...(otherDataValue > 0
        ? [{ name: "Other", amount: otherDataValue }]
        : []),
    ],
    [sorteredData, otherDataValue, shades.length],
  );

  return (
    <Box>
      <Typography>{title}</Typography>

      <Stack direction="row" mt={0}>
        {dataWithOther.map(({ name, amount }, index) => {
          if (shadesWithOther.length <= index) return null;

          const percentage = amount / total;
          const formattedPercentage = U_Show.percentNoDecimal.show(percentage);

          return (
            <Box
              key={name}
              flex={`1 1 ${formattedPercentage}`}
              overflow="hidden"
              sx={{
                borderRight: `1px solid ${palette.invariantWhite}`,
              }}
            >
              <Box
                height={barHeight}
                bgcolor={shadesWithOther[index]}
                display="flex"
                justifyContent="flex-end"
                alignItems="center"
              >
                <Typography
                  mr={0}
                  variant="h6"
                  color={palette.invariantWhite}
                  overflow="hidden"
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                  sx={{
                    maxWidth: hideTextMaxWidthRule,
                  }}
                >
                  {formattedPercentage}
                </Typography>
              </Box>

              <Typography
                mt={0}
                variant="h6"
                overflow="hidden"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
                color={shadesWithOther[index]}
                sx={{
                  maxWidth: hideTextMaxWidthRule,
                }}
              >
                {name}
              </Typography>
            </Box>
          );
        })}
      </Stack>
    </Box>
  );
};
