import React, { useMemo } from "react";
import { VictoryTooltipProps } from "victory";
import { Typography } from "@mui/material";
import { useHeritageV2Palette } from "../../../../utils/hooks/useHeritageV2Palette";
import { Box } from "../Box/Box";

/**
 * A basic point interface used on the Victory Chart.
 */
export type TooltipBasePoint = { x: string | number | Date; y: number };

type RenderedDatumValue = {
  value: string | undefined;
  color?: string;
};

type RenderedDatum = { title: RenderedDatumValue & { suffix?: string } } & {
  [key: string]: RenderedDatumValue;
};

/**
 * A render function used to transform a point on the chart
 * into the label showed in the tooltip.
 */
export type TooltipRenderDataFn<T extends TooltipBasePoint> = (
  datum: T,
) => RenderedDatum;

type Props<T extends TooltipBasePoint> = VictoryTooltipProps & {
  baseFontSize: number;
  tooltipWidth: number;
  tooltipHeight: number;
  renderData: TooltipRenderDataFn<T>;
  chartRatio?: number;
};

/**
 * The generic tooltip "label" component.
 * This will be showed _inside_ the tooltip container.
 */
export const GenericTooltip = <T extends TooltipBasePoint>(props: Props<T>) => {
  const {
    datum,
    x: px,
    y: py,
    baseFontSize,
    tooltipWidth,
    tooltipHeight,
    renderData,
    chartRatio,
  } = props;
  const sanitizedDatum = useMemo(() => datum as T | undefined, [datum]);
  const palette = useHeritageV2Palette();
  const computedChartRatio = chartRatio ?? 1;

  const toolTipContent = useMemo(
    () => (sanitizedDatum ? renderData(sanitizedDatum) : undefined),
    [renderData, sanitizedDatum],
  );

  if (!toolTipContent || !sanitizedDatum) return null;

  const padding = 3 * computedChartRatio;
  const computedX = (px ?? 0) - tooltipWidth / 2 + padding;
  const computedY = (py ?? 0) - tooltipHeight / 2 + padding - 1;

  const { title, ...contents } = toolTipContent;

  return (
    <g style={{ pointerEvents: "none" }}>
      <foreignObject
        x={computedX}
        y={computedY}
        width={tooltipWidth}
        height={tooltipHeight}
        style={{
          display: "flex",
        }}
      >
        <Box>
          <Typography
            variant="h6"
            fontSize={baseFontSize + 0.5}
            color={title.color ?? palette.neutral500}
          >
            {title.value}

            {title.suffix ? (
              <Typography
                display="inline-block"
                variant="h6"
                fontSize={baseFontSize - 0.2}
                color={title.color ?? palette.neutral500}
                ml="1px"
              >
                {title.suffix}
              </Typography>
            ) : undefined}
          </Typography>
        </Box>

        {Object.entries(contents).map(([title, { value, color }]) => (
          <Box
            display="flex"
            key={title + value}
            sx={{
              marginTop: "1px",
            }}
          >
            <Typography variant="h6" fontSize={baseFontSize} color={color}>
              {title + (value ? ": " : "")}
            </Typography>

            <Typography
              variant="h6"
              fontSize={baseFontSize}
              color={palette.invariantWhite}
              sx={{ marginLeft: "2px" }}
            >
              {value ?? "-"}
            </Typography>
          </Box>
        ))}
      </foreignObject>
    </g>
  );
};
