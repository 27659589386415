import * as t from "io-ts";

export const LabeledTrailT = t.type({
  label: t.string,
});

//
// Units
//

const AmountsT = t.partial({
  USD: t.number,
  EUR: t.number,
  GBP: t.number,
});
export type Currency = keyof AmountsT;
export type AmountsT = t.TypeOf<typeof AmountsT>;

export const MoneyT = t.type({
  kind: t.literal("Money"),
  amounts: AmountsT,
  trail: t.unknown,
});
export type MoneyT = t.TypeOf<typeof MoneyT>;

export const NumericT = t.type({
  kind: t.literal("Numeric"),
  value: t.number,
  trail: t.unknown,
});

export const PercentT = t.type({
  kind: t.literal("Percent"),
  value: t.number,
  trail: t.unknown,
});

//
// Operations
//

const MoneyCreateT = t.type({
  kind: t.literal("MoneyCreate"),
});
type MoneyCreateT = t.TypeOf<typeof MoneyCreateT>;

export const MoneyAddT = t.type({
  kind: t.literal("MoneyAdd"),
  left: MoneyT,
  right: MoneyT,
});

export const MoneySumT = t.type({
  kind: t.literal("MoneySum"),
  items: t.readonlyArray(MoneyT),
});

export const MoneyGenericOperationT = t.type({
  kind: t.literal("MoneyGenericOperation"),
  args: t.readonlyArray(MoneyT),
});

export const MoneySubT = t.type({
  kind: t.literal("MoneySub"),
  left: MoneyT,
  right: MoneyT,
});

export const MoneyTimesT = t.type({
  kind: t.literal("MoneyTimes"),
  left: MoneyT,
  right: t.union([NumericT, PercentT]),
});

export const MoneyOverT = t.type({
  kind: t.literal("MoneyOver"),
  left: MoneyT,
  right: MoneyT,
});

export const MoneyMinT = t.type({
  kind: t.literal("MoneyMin"),
  left: MoneyT,
  right: MoneyT,
});

export const MoneyMaxT = t.type({
  kind: t.literal("MoneyMax"),
  left: MoneyT,
  right: MoneyT,
});

export const MoneyToSingleCurrencyT = t.type({
  kind: t.literal("MoneyWithSingleCurrency"),
  currency: t.string,
  value: MoneyT,
});

export const MoneyWithSingleCurrencyT = t.type({
  kind: t.literal("MoneyWithSingleCurrency"),
  currency: t.string,
  value: MoneyT,
});

const PercentCreateT = t.type({
  kind: t.literal("PercentCreate"),
});
type PercentCreateT = t.TypeOf<typeof PercentCreateT>;

export const PercentAddT = t.type({
  kind: t.literal("PercentAdd"),
  left: PercentT,
  right: PercentT,
});

export const PercentSumT = t.type({
  kind: t.literal("PercentSum"),
  items: t.readonlyArray(PercentT),
});

export const PercentSubT = t.type({
  kind: t.literal("PercentSub"),
  left: PercentT,
  right: PercentT,
});
export type PercentSubT = t.TypeOf<typeof PercentSubT>;

export const PercentPowT = t.type({
  kind: t.literal("PercentPow"),
  left: PercentT,
  right: NumericT,
});

const NumericCreateT = t.type({
  kind: t.literal("NumericCreate"),
});

export const NumericAddT = t.type({
  kind: t.literal("NumericAdd"),
  left: NumericT,
  right: NumericT,
});

export const NumericSubT = t.type({
  kind: t.literal("NumericSub"),
  left: NumericT,
  right: NumericT,
});

export const NumericTimesT = t.type({
  kind: t.literal("NumericTimes"),
  left: NumericT,
  right: NumericT,
});

export const NumericOverT = t.type({
  kind: t.literal("NumericOver"),
  left: NumericT,
  right: NumericT,
});

export const isLeafTrail = (
  trail: unknown,
): trail is MoneyCreateT | PercentCreateT =>
  MoneyCreateT.is(trail) ||
  NumericCreateT.is(trail) ||
  PercentCreateT.is(trail) ||
  (PercentSumT.is(trail) && trail.items.length === 0);
