import { Box, useTheme } from "@mui/material";
import React, { PropsWithChildren } from "react";
import { ContainerPaper } from "../../core/ContainerPaper/ContainerPaper";
import { ContentWrapper } from "../ContentWrapper/ContentWrapper";

type Props = PropsWithChildren<{
  wrapContentInContainer?: boolean;
}>;

/**
 * Pure functional component that wraps a page in the
 * next gen application. This component adds the bottom
 * disclaimer to the page.
 */
export const BasePageContainer: React.FC<Props> = ({
  wrapContentInContainer,
  children,
}) => {
  const theme = useTheme();

  return (
    <ContentWrapper>
      {!wrapContentInContainer ? (
        <Box>{children}</Box>
      ) : (
        <ContainerPaper>{children}</ContainerPaper>
      )}

      <Box
        sx={{
          marginTop: "40px",
          fontSize: "0.7em",
          color: theme.palette.lowEmphasis.main,
        }}
      >
        Heritage Holdings S.à r.l. (92, rue de Bonnevoie, L-1260 Luxembourg) is
        acting as general partner of the fund Heritage AIF SCSp. IQ EQ Fund
        Management (Luxembourg) S.A. (412F, route d’Esch, L-2086 Luxembourg) is
        acting as full-scope AIFM, IQ EQ Depositary Services (Luxembourg) S.A.
        (412F, route d’Esch, L-2086 Luxembourg) is acting as depositary and IQ
        EQ (Luxembourg) S.A. (412F, route d’Esch, L-2086 Luxembourg) is acting
        as domiciliary, registrar and transfer agent services to Heritage AIF
        SCSp. All investors and marketing materials present on the platform are
        reviewed and approved by IQ EQ Fund Management (Luxembourg) S.A.. The
        platform is provided by HTECH S.r.l. (Corso di Porta Nuova, 34, Milano,
        20124, Italy) and it is managed and controlled by the AIFM as assisted
        by HTECH S.r.l.. Heritage does not make investment recommendations and
        no communication, through this website or in any other medium should be
        construed as a recommendation for any security offered on or off this
        investment platform. Alternative investments in private placements, and
        private equity investments via funds in particular, are speculative and
        involve a high degree of risk and those investors who cannot afford to
        lose their entire investment should not invest. Prospective investors
        should carefully consider the risk warnings and disclosures for the
        respective fund or investment vehicle set out therein. The value of an
        investment may go down as well as up and investors may not get back
        their money originally invested. Past performance is not an indicator of
        future performance. An investment in a fund or investment vehicle is not
        the same as a deposit with a banking institution. Investors will
        typically receive illiquid and/or restricted membership interests that
        may be subject to holding period requirements and/or liquidity concerns.
        In the most sensible investment strategy for private equity investing,
        private equity should only be part of your overall investment portfolio.
        Further, the private equity portion of your portfolio may include a
        balanced portfolio of different private equity funds. Investments in
        private equity are highly illiquid and those investors who cannot hold
        an investment for the long term (at least 10 years) should not invest.
      </Box>
    </ContentWrapper>
  );
};
