import React from "react";
import { Link, Stack } from "@mui/material";
import { PropsWithChildren } from "react";
import { External } from "../Icon/Icon";
import { useHeritageV2Palette } from "../../../../utils/hooks/useHeritageV2Palette";
import { Box } from "../Box/Box";

type Props = PropsWithChildren<{
  href?: string;
  onClick?: () => void;
}>;

/**
 * A `Table` cell that contains an external link.
 */
export const CellExternalLink: React.FC<Props> = ({
  href,
  children,
  onClick,
}) => {
  const palette = useHeritageV2Palette();

  return (
    <Link
      href={href}
      color={palette.text900}
      underline="none"
      onClick={onClick}
      target="_blank"
      referrerPolicy="no-referrer"
      display="flex"
      width="100%"
      height="100%"
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        gap={0}
      >
        <Box>
          <External variant="small" stroke={palette.accent} />
        </Box>
        <Box>
          <span className="truncate">{children}</span>
        </Box>
      </Stack>
    </Link>
  );
};
