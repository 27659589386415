import { differenceInQuarters } from "date-fns";
import eachQuarterOfInterval from "date-fns/eachQuarterOfInterval";
import formatISO from "date-fns/formatISO";
import lastDayOfQuarter from "date-fns/lastDayOfQuarter";
import { getTodayCutoff } from "../../utils/cutoff";

/**
 * Returns the quarters within the given interval of dates.
 *
 * Each quarter is represented by its last day.
 */
const getQuartersForInterval = (start: Date, end: Date): Date[] =>
  eachQuarterOfInterval({ start: start, end: end < start ? start : end }).map(
    (_) => lastDayOfQuarter(_),
  );

export const formatISODate = (date: Date) =>
  formatISO(date, {
    representation: "date",
  });

/**
 * Given the default quarter date and an optional oldest allocation date,
 * returns the confirmed quarters.
 */
export const generateConfirmedQuarters = (
  defaultQuarterDate: Date,
  oldestAllocationDate?: Date,
): Array<Date> =>
  // The confirmed cutoffs exists only when we have a first allocation date
  // and it's older than our default quarter.
  oldestAllocationDate &&
  differenceInQuarters(defaultQuarterDate, oldestAllocationDate) >= 0
    ? getQuartersForInterval(oldestAllocationDate, defaultQuarterDate)
    : [];

/**
 * Given the default quarter date and an optional oldest allocation date,
 * returns the draft quarters.
 */
export const generateDraftQuarters = (
  defaultQuarterDate: Date,
  oldestAllocationDate?: Date,
  todayCutoff = getTodayCutoff(),
): Array<Date> => {
  // If we don't have a first allocation date OR the first allocation date is
  // older than our default quarter, de draft quarters will be all the quarters
  // from the default quarter to today.
  //
  // N.B. We always want that the latest quarter is the "Today" date.
  return !oldestAllocationDate ||
    differenceInQuarters(defaultQuarterDate, oldestAllocationDate) >= 0
    ? // We should avoid the first quarter since it's the `defaultQuarter`
      // and a quarter that has been approved. We are going to replace the latest
      // quarter with the "Today" date.
      getQuartersForInterval(defaultQuarterDate, todayCutoff)
        .slice(1, -1)
        .concat([todayCutoff])
    : // If we have a first allocation date and it's newer than our default quarter,
      // we are going to show in the select just one quarter and it will be `Today`.
      getQuartersForInterval(oldestAllocationDate, todayCutoff)
        .slice(0, -1)
        .concat([todayCutoff]);
};
