import { NavigationTabs } from "../../core/NavigationTabs/NavigationTabs";
import React from "react";
import { SecurityComponent } from "./SecurityComponent/SecurityComponent";
import { AccountComponent } from "./AccountComponent/AccountComponent";

/**
 * The user account page
 * @constructor
 */
export const UserProfileView: React.FC = () => {
  return (
    <NavigationTabs
      defaultTab="account"
      tabs={[
        {
          route: "account",
          displayName: "Account",
          component: <AccountComponent />,
        },

        {
          route: "security",
          displayName: "Security",
          component: <SecurityComponent />,
        },
      ]}
    />
  );
};
