import { Link, Typography } from "@mui/material";
import React from "react";
import { z } from "zod";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { config } from "../../../../utils/config";
import { Checkbox } from "../../../core/v2/Checkbox/Checkbox";
import { Input } from "../../../core/v2/Input/Input";
import { Box } from "../../../core/v2/Box/Box";
import { Dialog } from "../../../core/v2/Dialog/Dialog";

const vdrLoginCompactSchema = z.object({
  email: z.string().email("Enter a valid email address"),
});

const vdrLoginCompleteSchema = vdrLoginCompactSchema.extend({
  investorName: z.string().min(1, "Enter your name"),
  nda: z.boolean().refine((v) => v, { message: "You must accept the NDA" }),
  ceid: z.boolean().refine((v) => v, {
    message: "You must accept the Certificate of Eligible Investor Declaration",
  }),
});

type VdrLoginCompact = z.infer<typeof vdrLoginCompactSchema>;

type VdrLoginComplete = z.infer<typeof vdrLoginCompleteSchema>;

export type VdrLogin = VdrLoginCompact | VdrLoginComplete;

type Props = {
  variant: "compact" | "complete";
  open: boolean;
  onSubmit: (data: VdrLogin) => void;
};

/**
 * Login form used in the Guest VDR.
 */
export const VdrLoginForm: React.FC<Props> = ({ variant, open, onSubmit }) => {
  const {
    control,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm<VdrLogin>({
    mode: "all",
    resolver: zodResolver(
      variant === "compact" ? vdrLoginCompactSchema : vdrLoginCompleteSchema,
    ),
    defaultValues:
      variant === "compact"
        ? { email: "" }
        : { email: "", investorName: "", nda: false, ceid: false },
  });

  return (
    <Dialog
      title="Insert your information below"
      onClose={() => null}
      open={open}
      minWidth={500}
      submitButtonText="Submit"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Box>
        <Box>
          <Controller
            name="email"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <Input
                {...field}
                error={error !== undefined}
                label="E-Mail"
                type="email"
                helperText={error?.message}
                disabled={isSubmitting}
              />
            )}
          />
        </Box>

        {variant === "complete" ? (
          <Box mt={2}>
            <Controller
              name="investorName"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <Input
                  {...field}
                  error={error !== undefined}
                  label="Name"
                  helperText={
                    error?.message ||
                    "Please provide the name of the professional investor that you represent (individual or business entity)"
                  }
                  disabled={isSubmitting}
                />
              )}
            />
          </Box>
        ) : undefined}

        {variant === "complete" ? (
          <Box mt={2}>
            <Box>
              <Controller
                name="nda"
                control={control}
                render={({ field }) => (
                  <Checkbox
                    checked={field.value}
                    onChange={() => field.onChange(!field.value)}
                    label={
                      <Typography>
                        I hereby acknowledge that I have read and fully
                        understood the{" "}
                        <Link
                          href={`${config.legacyAppUrl}/disclaimers/nda-disclaimer.pdf`}
                          target="_blank"
                          rel="noopener noreferrer"
                          sx={{
                            color: "inherit",
                            textDecorationColor: "inherit",
                          }}
                        >
                          NDA Policy
                        </Link>
                      </Typography>
                    }
                  />
                )}
              />
            </Box>

            <Box mt={1}>
              <Controller
                name="ceid"
                control={control}
                render={({ field }) => (
                  <Checkbox
                    checked={field.value}
                    onChange={() => field.onChange(!field.value)}
                    label={
                      <Typography>
                        I hereby acknowledge that I have read and fully
                        understood the{" "}
                        <Link
                          href={`${config.legacyAppUrl}/disclaimers/professional-investor-declaration-disclaimer.pdf`}
                          target="_blank"
                          rel="noopener noreferrer"
                          sx={{
                            color: "inherit",
                            textDecorationColor: "inherit",
                          }}
                        >
                          Certificate of Eligible Investor Declaration
                        </Link>
                      </Typography>
                    }
                  />
                )}
              />
            </Box>
          </Box>
        ) : undefined}

        {"nda" in errors || "ceid" in errors ? (
          <Box mt={1}>
            <Typography
              variant="body2"
              sx={(theme) => ({
                color: theme.palette.heritageV2.statusNegative,
              })}
            >
              All the checkbox are required.
            </Typography>
          </Box>
        ) : undefined}
      </Box>
    </Dialog>
  );
};
