import { useRoutesResolver } from "../lib/hooks/useRoutesResolver";
import { RouteClasses } from "../routes/RootRoute/RootRoute";
import { useInvestor } from "./useInvestor";
import { function as F, option as O } from "fp-ts";
/**
 * return a function that takes the asset id and returns the url to the asset detail page
 */
export const useAssetUrl = (
  { fromPortfolio } = {
    fromPortfolio: false,
  },
): ((assetId: string) => string) => {
  const { generatePathForRoute } = useRoutesResolver<RouteClasses>();
  const investor = useInvestor();
  return F.pipe(
    investor,
    O.fromNullable,
    O.match(
      () => () => "#",
      ({ id: investorId }) =>
        (assetId: string) =>
          generatePathForRoute(
            fromPortfolio ? "InvestmentsAssetRoute" : "AssetRoute",
            {
              investorId,
              assetId,
            },
          ),
    ),
  );
};
