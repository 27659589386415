import React from "react";
import Grid, { Grid2Props } from "@mui/material/Unstable_Grid2";
import { SkeletonLoader } from "../../../core/v2/Loader/SkeletonLoader";

const statsBoxHeight = 135;

const commonStatContainerProps: Grid2Props = {
  xs: 12,
  sm: 12,
  md: 12,
  lg: 12,
};

/**
 * The loader "skeleton" section for the portfolio top stats.
 */
export const PortfolioOverviewStatsLoader: React.FC = () => {
  return (
    <Grid container spacing={2}>
      <Grid {...commonStatContainerProps}>
        <SkeletonLoader size={{ height: statsBoxHeight }} />
      </Grid>

      <Grid {...commonStatContainerProps}>
        <SkeletonLoader size={{ height: statsBoxHeight }} />
      </Grid>
    </Grid>
  );
};
