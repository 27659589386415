import React, { useMemo } from "react";
import { PortfolioOverview } from "../PortfolioOverview/PortfolioOverview";
import { useRetrieveCutoffs } from "../../../../hooks/useRetrieveCutoffs";
import { NoDataPlaceholder } from "../../../core/v2/NoDataPlaceholder/NoDataPlaceholder";
import { useNavigate } from "react-router-dom";
import { useRoutesResolver } from "../../../../lib/hooks/useRoutesResolver";
import { RouteClasses } from "../../../../routes/RootRoute/RootRoute";
import { useInvestor } from "../../../../hooks/useInvestor";
import { Box } from "../../../core/v2/Box/Box";

/**
 * The main portfolio view component.
 */
export const PortfolioView: React.FC = () => {
  const { generatePathForRoute } = useRoutesResolver<RouteClasses>();
  const investorId = useInvestor()?.id;
  const { confirmedCutoffs, draftCutoffs, fetching } = useRetrieveCutoffs();
  const navigate = useNavigate();

  const cutoffs = useMemo(
    () => [...confirmedCutoffs, ...draftCutoffs],
    [confirmedCutoffs, draftCutoffs],
  );

  // FIXME: This loader should handled in a more global way
  // than just render the inner components.
  if (fetching) return;

  if (cutoffs.length === 0) {
    return (
      <Box height="100%">
        <NoDataPlaceholder
          message="No investments in this portfolio"
          ctaText="Browse new opportunities"
          onCtaClick={() =>
            navigate(generatePathForRoute("AssetsRoute", { investorId }))
          }
        />
      </Box>
    );
  }

  return <PortfolioOverview />;
};
