import React from "react";
import { FeatureFlag, TransactionsRouteComponent } from "../../generated/urql";
import { TransactionsView as TransactionsViewV2 } from "../../components/transactions/v2/TransactionsView/TransactionsView";
import { useInvestor } from "../../hooks/useInvestor";
import { handleQueryData } from "../../utils/queries";
import { useLayoutContext } from "../LayoutRoute/LayoutRoute";

export const TransactionsRoute: React.FC = () => {
  const {
    authUser: { featureFlags },
  } = useLayoutContext();
  const investor = useInvestor();
  const investorId = investor?.id;
  const showBankAccounts = !!featureFlags.includes(
    FeatureFlag.EnableBankAccountsSection,
  );

  if (investorId === undefined) {
    return null; // TODO: handle error
  }

  return (
    <TransactionsRouteComponent
      variables={{
        investorId,
      }}
      children={handleQueryData((data) => (
        <TransactionsViewV2
          capitalCalls={data.investor.capitalCalls}
          distributions={data.investor.distributions}
          bankAccounts={
            showBankAccounts
              ? (data.investor.bankAccounts ?? undefined)
              : undefined
          }
        />
      ))}
    />
  );
};
