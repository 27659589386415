import React from "react";
import { IconButton as BaseIconButton } from "@mui/material";
import { BaseIconPublicProps } from "../Icon/Icon";

type Props = {
  Icon: React.FC<BaseIconPublicProps>;
  disabled?: boolean;
  onClick?: () => void;
  danger?: boolean;
  noPadding?: boolean;
  href?: string;
};

/**
 * A button component with just an icon inside.
 */
export const IconButton: React.FC<Props> = ({
  Icon,
  disabled,
  onClick,
  danger,
  noPadding,
  href,
}) => {
  return (
    <BaseIconButton
      disabled={disabled}
      onClick={onClick}
      href={href ?? ""}
      className={danger ? "danger" : undefined}
      sx={{
        padding: noPadding ? "0px" : undefined,
      }}
    >
      <Icon variant="medium" />
    </BaseIconButton>
  );
};
