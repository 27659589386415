import React from "react";
import { RootRoute } from "./routes/RootRoute/RootRoute";
import { bootstrap } from "./utils/bootstrap";
import "@fontsource/inter/300.css";
import "@fontsource/inter/400.css";
import "@fontsource/inter/500.css";
import "@fontsource/inter/600.css";
import "@fontsource/inter/700.css";
import "@fontsource/playfair-display/500.css";

bootstrap("app", <RootRoute />);
